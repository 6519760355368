import React from 'react';
import "../../assests/css/Footer.css";
import Images from "../../Images/Images"
import { Link } from 'react-router-dom';


const LandFooter = () => {
    return (
        <footer className="footer-land ">
            <div className='land-footer-head'>
                <div className='footer-left'>
                    <div className="footer-logo">
                        <Link to="" className="logoImageiconfooter">
                            <img src={Images.footerLand} alt='logo' />
                        </Link>
                    </div>
                    <div className="footer-copy">
                        © 2024 Kartoffel Technologies. All rights reserved.
                    </div>
                </div>
                <div className="footer-social-icons">
                    <a href="https://www.facebook.com/kartoffeltech" target="_blank" rel="noopener noreferrer">
                        <img src={Images.fb} alt='fb logo'></img>
                    </a>
                    <a href="https://www.instagram.com/kartoffeltechnologies/" target="_blank" rel="noopener noreferrer">
                        <img src={Images.insta} alt='insta logo'></img>
                    </a>
                    {/* <a href="">
                                    <img src={Images.x} alt='x logo'></img>
                                </a> */}
                    <a href="https://www.linkedin.com/company/kartoffeltech/mycompany/" target="_blank" rel="noopener noreferrer">
                        <img src={Images.linked} alt='linkedin logo'></img>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default LandFooter;
