import React from 'react';
import '../../assests/css/WebOurProcess.css';

const OurProcess = () => {
    return (
        <div className="text-center my-5 our-process-head">
            <p className='our-process-tittle'>Our Process</p>
            <div className="d-flex gap-4 justify-content-center mt-4">
                <div className=" process-step">
                    <div className="our-process-head">
                        <p className="process-circle">1</p>
                        <p className="process-label-one">Gathering &
                            <br/> Analysis</p>
                    </div>
                </div>

                <div className=" process-step">
                    <div className="our-process-head">
                        <p className="process-circle">2</p>
                        <p className="process-label-two">UI/UX Design</p>
                    </div>
                </div>

                <div className=" process-step">
                    <div className="our-process-head">
                        <p className="process-circle">3</p>
                        <p className="process-label-three">Front-end <br/>Development</p>
                    </div>
                </div>

                <div className=" process-step">
                    <div className="our-process-head">
                        <p className="process-circle">4</p>
                        <p className="process-label-four">Back-end <br/>Development</p>
                    </div>
                </div>

                <div className=" process-step">
                    <div className="our-process-head">
                        <p className="process-circle">5</p>
                        <p className="process-label-five">Maintenance &<br/> Support</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurProcess;
