import React from 'react';
import '../../assests/css/website.css';
import Images from "../../Images/Images";

const WebsiteInfo = () => {
    return (
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12 web-partone">
                    <div className='web-laptop'>
                    <img src={Images.weblap} alt="Dynamic and Static Websites" className="img-fluid info-image" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="info-text">
                        <div className='web-line'>
                            <p className="info-title">Dynamic Websites</p>
                            <p className='web-lap-para'>Manage your content effortlessly with our CMS solutions.</p></div>
                        <div className='web-line'><p className="info-title">Static Websites</p>
                            <p className='web-lap-para'>Get a maintenance-free static online presence.</p></div>
                        <div className='web-line'> <p className="tools-title">Tools</p>
                            <div className="tools-icons">
                                <img src={Images.webtechimg} alt="Kotlin" className="tool-icon" />
                            </div></div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default WebsiteInfo;
