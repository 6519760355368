import React, { useRef, useState, useEffect } from 'react';
import img from '../../Images/Images';
import '../../assests/css/ProductAnimations.css';
import CeoCard from './CeoCard';
import MobileAnimations from './MobileAnimations';

const ProductAnimations = () => {
  const [animData, setAnimData] = useState({
    drop1: [
      {
        title: "The Happiness Project  ",
        describe: "THAP is an app for consultation with a wellness coach via teleconsultation. It has inbuilt assessment tests, personalized plans with exercises and diet recommendations.  ",
        ceo: {
          title: "Technologies:",

          image: img.thaptech
        },
        firstpj: {
          src: img.thapp,
          alt: 'Image 1',
          className: 'thap',
          id: 'animenarto1',
        },
      },
      {
        title: "THEA",
        describe: "THEA offers features like recording pumping sessions and collected milk, option to handle data online and offline for up to 36 hours  ",
        ceo: {
          title: "Technologies:",

          image: img.theatech
        },
        secndpj: {
          src: img.theav,
          alt: 'Image 2',
          className: 'thaap',
          id: 'animenarto2',
          hidden: true,
        },
      },
      {
        title: "PeriodPal by LAIQA ",
        describe: "PeriodPal provides women an easy period tracking mobile platform. They can also track moods, sleeping habits, and water intake. The app also provides teleconsultations and chat support on demand. It also has an in-app shop for menstrual wellness products.",
        ceo: {
          title: "Technologies:",

          image: img.periodtech
        },
        thirdpj: {
          src: img.period,
          alt: 'Image 3',
          className: 'thaaap',
          id: 'animenarto3',
          hidden: true,
        },
      },
      {
        title: "Neuberg Diagnostics ",
        describe: "Neuberg Diagnostics is a leading global diagnostic service provider with locations in India, UAE, South Africa, and the USA, offering over 5000 types of pathological tests. Through their app, patients can request lab tests and schedule at-home sample collections by qualified phlebotomists. Test results are made available on the app, where patients can also track the phlebotomist's location in real-time..",
        ceo: {
          title: "Technologies:",

          image: img.neubergtech
        },
        fourthpj: {
          src: img.neubgbk,
          alt: 'Image 3',
          className: 'thaaap',
          id: 'animenarto4',
          hidden: true,
        },
      }
    ],
    drop2: [
      {
        title: "TANUVAS  ",
        describe: "Tamil Nadu Veterinary and Animal Science University (TANUVAS) is the first veterinary and animal sciences university in Asia, established by the Government of Tamil Nadu and approved by ICAR. The university offers a range of undergraduate, postgraduate, and Ph.D. courses across various disciplines. ",
        ceo: {
          title: "Technologies:",

          image: img.tanuvastech
        },
        firstpj: {
          src: img.tanusbk,
          alt: 'Image 1',
          className: 'lapone',
          id: 'animenarto01',
        },
      },
      {
        title: "LAIQA",
        describe: "LAIQA is committed to improving menstrual hygiene worldwide by offering high-quality, cotton-based sanitary pads in India. Designed to be biodegradable, leakage-free, and rash-free, their pads aim to make menstruation a more comfortable experience for women. As India's first chlorine-free pad, LAIQA also offers protection against infections.  ",
        ceo: {
          title: "Technologies:",

          image: img.laiqatech
        },
        secndpj: {
          src: img.laiqabk,
          alt: 'Image 2',
          className: 'lapone',
          id: 'animenarto02',
          hidden: true,
        },
      },
      {
        title: "ProConnect ",
        describe: "ProConnect, a division of the USD 6.5 billion Redington Group, specializes in end-to-end supply chain solutions for over 160 leading brands. With 172 warehouses covering 6 million square feet, delivery capabilities extend to 36,000 locations across India. The company offers a cohesive, tailored package to meet diverse supply chain requirements. ",
        ceo: {
          title: "Technologies:",

          image: img.protech
        },
        thirdpj: {
          src: img.DashboardGlobl,
          alt: 'Image 3',
          className: 'lapone',
          id: 'animenarto03',
          hidden: true,
        },
        
      },
      {
        title: "MyLabConnect ",
        describe: "MyLabConnect is a multi-platform tool that revolutionises the dental industry by connecting dental offices, labs, and DSOs through real-time business workflows, offering a pay-per-use model. It is a preferred platform across the dental network and can be used as a common front-end interface platform with labs and Intra Oral scanner and 3D printers .",
        ceo: {
          title: "Technologies:",

          image: img.labtech
        },
        fourthpj: {
          src: img.mylabcnbk,
          alt: 'Image 4',
          className: 'lapone',
          id: 'animenarto04',
          hidden: true,
        },
        
      },
      {
        title: "Etisalat ",
        describe: "Etisalat goes beyond traditional telecom services to offer digital experiences tailored to modern lifestyles, including areas like gaming, health, and insurance. The company focuses on enabling connectivity and accelerating the digital world. Etisalat is also at the forefront of emerging technologies, including private networks, autonomous vehicles, and AI. ",
        ceo: {
          title: "Technologies:",

          image: img.waastech
        },
        fifthpj: {
          src: img.etsilbk,
          alt: 'Image 5',
          className: 'lapone',
          id: 'animenarto05',
          hidden: true,
        }, 
      }  
    ],
    drop3: [
      {
        title: " BGR Tech ",
        describe: "BGR Tech specializes in clean energy generation, including hydrogen production, as well as the processing and decarbonization of oil and gas, all aimed at mitigating climate change. The company focuses on reducing carbon emissions to create a sustainable energy landscape. Committed to environmental responsibility, BGR Tech contributes to meeting global clean energy demands.   ",
        ceo: {
          title: "Technologies:",

          image: img.thechn
        },
        firstpj: {
          src: img.bgrbk,
          alt: 'Image 1',
          className: 'lapone',
          id: 'animenarto001',
        },
      },
      {
        title: "Mobility MEA",
        describe: "Based in Dubai, UAE, Mobility MEA serves as a trusted partner in digital transformation. The company specializes in Digital Workspace solutions, designed to help businesses navigate the complexities of the modern digital landscape. Through its expertise, Mobility MEA aims to facilitate enterprise growth and adaptability in a rapidly changing digital environment.   ",
        ceo: {
          title: "Technologies:",

          image: img.jsre
        },
        secndpj: {
          src: img.mobilitymeabk,
          alt: 'Image 2',
          className: 'lapone',
          id: 'animenarto002',
          hidden: true,
        },
      },
      {
        title: "Volvo",
        describe: "Volvo Group is committed to sustainable transport and infrastructure, influencing the future of eco-friendly solutions. Their products serve critical roles in daily life, including public transit, food delivery, and construction. With offerings like trucks, buses, and construction equipment, the company boosts customer productivity through specialized financing and services.",
        ceo: {
          title: "Technologies:",

          image: img.perio
        },
        thirdpj: {
          src: img.volvbk,
          alt: 'Image 3',
          className: 'lapone',
          id: 'animenarto003',
          hidden: true,
        },
        
      },
      {
        title: "Spartek ",
        describe: "Spartek stands as the market leader and the largest ceramic tile manufacturer in India, utilizing cutting-edge technology from the USA and European collaborators. The company pioneered the concept of ceramic flooring in India, producing it domestically for the first time. Renowned for innovation, Spartek continues to set industry standards in quality and design.",
        ceo: {
          title: "Technologies:",

          image: img.perio
        },
        fourthpj: {
          src: img.sparktkbk,
          alt: 'Image 3',
          className: 'lapone',
          id: 'animenarto004',
          hidden: true,
        },     
      },
      {
        title: "MyLabConnect ",
        describe: "MyLabConnect is a multi-platform tool that revolutionises the dental industry by connecting dental offices, labs, and DSOs through real-time business workflows, offering a pay-per-use model. It is a preferred platform across the dental network and can be used as a common front-end interface platform with labs and Intra Oral scanner and 3D printers .",
        ceo: {
          title: "Technologies:",
          image: img.perio
        },
        fifthpj: {
          src: img.mylabcnbk,
          alt: 'Image 3',
          className: 'lapone',
          id: 'animenarto005',
          hidden: true,
        },   
      },
    ],
    drop4: [
      {
        title: "Mix Mash",
        describe: "Mixmash is a tile-matching puzzle game where players rearrange tiles to match a given sequence. The game features increasingly challenging levels, variable grid sizes, and multiple themes to choose from, requiring strategic thinking and quick reflexes to succeed.",
        ceo: {
          title: "Technologies:",
          image: img.unrealtech
        },
        firstpj: {
          src: img.mixmash,
          alt: 'Image 1',
          className: 'lapone',
          id: 'animenarto001',
        },
      },
      {
        title: "Alien Rescue Saga",
        describe: "Alien Rescue Saga is a unique 2D puzzle game that seamlessly blends the popular 2048 mechanics with classic 'match-three' gameplay.The game is enhanced by a variety of power-ups and optional minigames, offering a dynamic and engaging experience.",
        ceo: {
          title: "Technologies:",
          image: img.unitytech
        },
        secndpj: {
          src: img.alien,
          alt: 'Image 2',
          className: 'lapone',
          id: 'animenarto002',
          hidden: true,
        },
      },
      {
        title: "Jarugandi",
        describe: "Jarugandi is a heart-pumping 3D thriller game developed to promote the movie of the same name. Players are thrust into a high-stakes world of timed races and pulse-pounding minigames, including bomb diffusion and lock picking challenges. With its fast-paced action and suspenseful gameplay",
        ceo: {
          title: "Technologies:",
          image: img.unitytech
        },
        thirdpj: {
          src: img.jarungandi,
          alt: 'Image 2',
          className: 'lapone',
          id: 'animenarto003',
          hidden: true,
        },
      },
      {
        title: "Word clan",
        describe: `Word Clan" is a fast-paced 2D multiplayer word game where players engage in a battle of linguistic prowess to attack each other's castles. By rapidly forming anagrams from a shared letter pool, players unleash devastating attacks, including explosive barrels, tornadoes, and fireballs, to breach their opponents' defenses.`,
        ceo: {
          title: "Technologies:",
          image: img.unitytech
        },
        fourthpj: {
          src: img.worldclan,
          alt: 'Image 2',
          className: 'lapone',
          id: 'animenarto004',
          hidden: true,
        },
      },
      {
        title: "Arjuna's Arc",
        describe: `"Arjuna's Arc" is a 2D platformer game inspired by the legendary archer Arjuna from the ancient Indian epic, Mahabharatha. Players assume the role of Arjuna, navigating through challenging levels set in a mystical world filled with demonic creatures. Armed with a bow and arrow, players must skillfully shoot down waves of demons and bats.`,
        ceo: {
          title: "Technologies:",
          image: img.unitytech
        },
        fifthpj: {
          src: img.arjuna,
          alt: 'Image 2',
          className: 'lapone',
          id: 'animenarto005',
          hidden: true,
        },
      },
      
    ]
  })
  const [selectedData, setSelectedData] = useState(animData?.drop1); // Default value
  const [activeButton, setActiveButton] = useState('drop1');
  const handleButtonClick = (key) => {
    setSelectedData(animData[key]); // Update the selected data key based on the clicked button
    setActiveButton(key);
  };
  const [scrollPosition, setScrollPosition] = useState(0);
  const [image, setImg] = useState('1');
  const divRef = useRef(null);
  const [count1, setCount1] = useState(0)
  const [count2, setCount2] = useState(0)
  const [topDis, setTopDis] = useState(100);
  var lastScrollTop = 0
  const [direction, setDirection] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const position = divRef.current.scrollTop;
      setScrollPosition(position);
      if (position > lastScrollTop) {
        console.log('down')
        setDirection('down')
        // downscroll code
      } else if (position < lastScrollTop) {
        console.log('up')
        setDirection('up')
      } // else was horizontal scroll
      lastScrollTop = position;
    };
    divRef.current.addEventListener('scroll', handleScroll);
    return () => {
      if (divRef.current) {
        divRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    let timeoutId;
    if (scrollPosition < 450) {
      setImg('1');
    } else if (scrollPosition > 450 && scrollPosition < 1050) {
      setImg('2');
    } else if (scrollPosition > 1050) {
      setImg('3');
    }
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      divRef.current.scrollTop = scrollPosition;
    }, "0.3s 1");
    return () => clearTimeout(timeoutId);
  }, [scrollPosition]);

let imageToShow = null;
let parentPhoneId = '';
let contentId = '';
console.log('========', direction);
if (scrollPosition < 450) {
  imageToShow = (
    <>
      <img className={(direction == 'down') ? `${selectedData[0]?.firstpj?.className} down_direction` : `${selectedData[0]?.firstpj?.className} up_direction`} id={selectedData[0]?.firstpj?.id} src={selectedData[0]?.firstpj?.src} alt='Image 1' />
    </>
  );
  parentPhoneId = 'parent-phone1';
  contentId = 'content1';
} else if (scrollPosition > 450 && scrollPosition < 1050) {
  imageToShow = (
    <img className={(direction == 'down') ? `${selectedData[1]?.secndpj?.className} down_direction` : `${selectedData[1]?.secndpj?.className} up_direction`} id={selectedData[1]?.secndpj?.id} src={selectedData[1]?.secndpj?.src} alt='Image 2' style={{ display: 'none' }} />
  );
  parentPhoneId = 'parent-phone2';
  contentId = 'content2';
  imageLoad(selectedData[1]?.secndpj?.id)
} 
else if (scrollPosition > 1050 && scrollPosition < 1650) {
  imageToShow = (
    <img
      className={(direction === 'down') ? `${selectedData[2]?.thirdpj?.className} down_direction` : `${selectedData[2]?.thirdpj?.className} up_direction`}
      id={selectedData[2]?.thirdpj?.id}
      src={selectedData[2]?.thirdpj?.src}
      alt='Image 3'
      style={{ display: 'none' }}
    />
  );
  parentPhoneId = 'parent-phone3';
  contentId = 'content3';
  
  imageLoad(selectedData[2]?.thirdpj?.id);
} else if (scrollPosition > 1650 && scrollPosition < 2250) {
  imageToShow = (
    <img
      className={(direction === 'down') ? `${selectedData[3]?.fourthpj?.className} down_direction` : `${selectedData[3]?.fourthpj?.className} up_direction`}
      id={selectedData[3]?.fourthpj?.id}
      src={selectedData[3]?.fourthpj?.src}
      alt='Image 4'
      style={{ display: 'none' }}
    />
  );
  parentPhoneId = 'parent-phone4';
  contentId = 'content4';
  imageLoad(selectedData[3]?.fourthpj?.id);
} else if (scrollPosition > 2250 && scrollPosition < 2850) {
  imageToShow = (
    <img
      className={(direction === 'down') ? `${selectedData[4]?.fifthpj?.className} down_direction` : `${selectedData[4]?.fifthpj?.className} up_direction`}
      id={selectedData[4]?.fifthpj?.id}
      src={selectedData[4]?.fifthpj?.src}
      alt='Image 5'
      style={{ display: 'none' }}
    />
  );
  parentPhoneId = 'parent-phone5';
  contentId = 'content5';
  imageLoad(selectedData[4]?.fifthpj?.id);
} else {
  imageToShow = (
    <img className={selectedData[5]?.sixthpj?.className} id={selectedData[5]?.sixthpj?.id} src={selectedData[5]?.sixthpj?.src} alt='Image 6' style={{ display: '' }} />
  );
  parentPhoneId = 'parent-phone6';
  contentId = 'content6';
  imageLoad(selectedData[5]?.sixthpj?.id);
}
  function imageLoad(imgId) {
    setTimeout(() => {
      if (document.getElementById(imgId)) {
        document.getElementById(imgId).style.display = "block"
        document.getElementById(imgId).style.animation = imgId + " 1.3s 1";
      }
    }, 60)
  }
  function calFun() {
    console.log('===', topDis);
  }
  return (
    <div className='anmolpk'>
      <div className='container'>
        <div className="row emer">
          <div className="col-lg-6 col-md-6 col-sm-12 butTbFour">
            <div className="bumTn">
              <button
                className={`mobTb btn btn-primary ${activeButton === 'drop1' ? 'active-button' : ''}`}
                type="button"
                onClick={() => handleButtonClick('drop1')}
              >
                Mobile
              </button>
              <button
                className={`webTb btn btn-primary ${activeButton === 'drop2' ? 'active-button' : ''}`}
                onClick={() => handleButtonClick('drop2')}
              >
                Web
              </button>
              <button
                className={`digitalTb btn btn-primary ${activeButton === 'drop3' ? 'active-button' : ''}`}
                onClick={() => handleButtonClick('drop3')}
              >
                Digital
              </button>
              <button
                className={`gameTb btn btn-primary ${activeButton === 'drop4' ? 'active-button' : ''}`}
                onClick={() => handleButtonClick('drop4')}
              >
                Game
              </button>
            </div>
          </div>
        </div>
        <div className='product_ani scrollable ' ref={divRef}>
          <div className='row'>
            <div className={`col-lg-6 col-12 ${contentId} order-2 order-lg-1`}>
              <div className='content-wrapper'>
                <MobileAnimations data={selectedData} /> {/* Pass selected data */}
              </div>
            </div>
            <div className={`col-lg-6 col-12 ${parentPhoneId} order-1 order-lg-2`}>
              {image === '1' ? (
                <img className='ellipse' src={img.eclipegg} alt='' />
              ) : image === '2' ? (
                <img className='ellipsev' src={img.eclipevn} alt='' />
              ) : (
                <img className='ellipseb' src={img.eclipegg} alt='' />
              )}

              <div className={`phvvoneCase ${activeButton === 'drop1' ? 'class1' : activeButton === 'drop2' ? 'class2' : activeButton==='drop3'? 'class3': activeButton === 'drop4' ? 'class4' :'class5'}`}>
                <div className={`phoneOutercase ${activeButton === 'drop1' ? 'class1' : activeButton === 'drop2' ? 'class2' : activeButton==='drop3' ? 'class3' : activeButton === 'drop4' ? 'class4' :'class5'}`}>
                  {/* Rest of your content */}
                  {imageToShow}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAnimations;
