import React from 'react'
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient'
import Images from "../../Images/Images"
// import Seo from "../../commonComponents/MetaTag/MetaTag";
import LandingBan from '../../component/Service/ServiceBanneer';
import NavbarLanding from '../../commonComponents/header/NavbarLanding';
import WebsiteInfo from '../../component/WebLanding/Website';
import Features from '../../component/WebLanding/WebService';
import WebDomain from '../../component/WebLanding/WebDomain';
import OurProcess from '../../component/WebLanding/WebOurProcess';
import Achievements from '../../component/WebLanding/WebAchievment';
import LandGetInTouch from '../../commonComponents/GetInTouch/LandGetInTouch';
import LandFooter from '../../commonComponents/footer/LandFooter';
import WhatWeOffer from '../../component/MobileAppLanding/WhatWeDoOffer';
import ElevateOnlinePresenceForm from '../../component/MobileAppLanding/MobileLandForm';

const MobileLandPage = () => {
    const bann = [
        {
            img: Images.mobilelandban,
            head: "TYPES OF APPS WE DEVELOP",
        }
    ];
    const client= {
      classname: "clients"
    };

  return (
    <div className='Mobilelanding'>
       <NavbarLanding/>
        <LandingBan  bann={bann}/>
        <OurTrustedPartners client={client}/>
        <WhatWeOffer/>
        <WebDomain/>
        <div className=''>
        <Achievements/>
        </div>
        <ElevateOnlinePresenceForm/>
        <LandGetInTouch/>
        <LandFooter/>
    </div>
  )
}

export default MobileLandPage