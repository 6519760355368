import KartoffelLogo from "../assests/image/png/kartoffellogo.png"
import dropdown from "../assests/image/svg/Vector.svg"
import fb from "../assests/image/svg/fb.svg"
import insta from "../assests/image/svg/insta.svg"
import x from "../assests/image/svg/x.svg"
import linked from "../assests/image/svg/linked.svg"
import consulting from "../assests/image/png/consulting.png"
import digitalproduct from "../assests/image/png/digitalproduct.png"
import viable from "../assests/image/png/viableproduct.png"
import service from "../assests/image/png/service.png"
import learnmore from "../assests/image/svg/learnmore.svg"
import idea from "../assests/image/png/idea.png"
import uparrow from "../assests/image/svg/uparrow.svg"
import downarrow from "../assests/image/svg/downarrow.svg"
import second from "../assests/image/png/02.png"
import colordown from "../assests/image/svg/colordownarrow.svg"
import colorup from "../assests/image/svg/coloruparrow.svg"
import cplus from "../assests/image/png/c++.png"
import cc from "../assests/image/png/cc.png"
import js from "../assests/image/png/js.png"
import docker from "../assests/image/png/docker.png"
import angular from "../assests/image/png/angular.png"
import mango from "../assests/image/png/mangodb.png"
import sql from "../assests/image/png/sql.png"
import node from "../assests/image/png/node.png"
import css from "../assests/image/png/css.png"
import development from "../assests/image/png/Development Tools.png"
import quotes from "../assests/image/png/quotes.png"
import list from "../assests/image/svg/list.svg"
import serviceban from "../assests/image/png/service-ban.png"
import certified from "../assests/image/png/certified.png"
import serBan from "../assests/image/png/service-ban.png"
import lenarrow from "../assests/image/svg/lenarrow.svg"
import computer from "../assests/image/svg/computer.svg"
import digital from "../assests/image/svg/digital.svg"
import rocket from "../assests/image/svg/rocket.svg"
import technology from "../assests/image/svg/technology.svg"
import chess from "../assests/image/svg/chess.svg"
import global from "../assests/image/png/global.png"
import nextmove from "../assests/image/png/nextmove.png"
import deltas from "../assests/image/png/Delta.png"
import spartek from "../assests/image/png/Spartek.png"
import aspiration from "../assests/image/png/Aspiration.png"
import alike from "../assests/image/png/alike.png"
import kiddle from "../assests/image/png/Kiddle.png"
import mobility from "../assests/image/png/Mobility.png"
import bank from "../assests/image/png/Bank.png"
import phone from "../assests/image/png/phone.png"
import technologies from "../assests/image/png/technologies.png"
import graph from "../assests/image/png/graph.png"
import thechn from "../assests/image/svg/thn.svg"
import thapp from "../assests/image/svg/thap.svg"
import jsre from "../assests/image/png/jsre.png"
import theav from "../assests/image/svg/tthe.svg"
import perio from "../assests/image/svg/pperiodp.svg"
import period from "../assests/image/png/periodpal.png"
import neubgbk from "../assests/image/png/neubgbk.png"
import tanusbk from "../assests/image/png/tanusbk.png"
import laiqabk from "../assests/image/png/laiqabk.png"
import DashboardGlobl from "../assests/image/png/DashboardGlobl.png"
import mylabcnbk from "../assests/image/png/mylabcnbk.png"
import etsilbk from "../assests/image/png/etsilbk.png"
import bgrbk from "../assests/image/png/bgrbk.png"
import mobilitymeabk from "../assests/image/png/mobilitymeabk.png"
import volvbk from "../assests/image/png/volvbk.png"
import sparktkbk from "../assests/image/png/sparktkbk.png"
import Wordclanh from "../assests/image/png/Wordclanh.png"
import eclipegg from "../assests/image/svg/eclipsde.svg"
import eclipevn from "../assests/image/svg/eclipeven.svg"
import abtBan from "../assests/image/png/abt-ban.png"
import memone from "../assests/image/png/member1.png"
import memtwo from "../assests/image/png/member2.png"
import memthree from "../assests/image/png/member3.png"
import memfour from "../assests/image/png/member4.png"
import memfive from "../assests/image/png/member5.png"
import memsix from "../assests/image/png/member6.png"
import memseven from "../assests/image/png/member7.png"
import memeight from "../assests/image/png/member8.png"
import memnine from "../assests/image/png/member9.png"
import memten from "../assests/image/png/member10.png"
import memtenone from "../assests/image/png/member11.png"
import memtentwo from "../assests/image/png/member12.png"
import memtenthree from "../assests/image/png/member13.png"
import memtenfour from "../assests/image/png/member14.png"
import memtenfive from "../assests/image/png/member15.png"
import memtensix from "../assests/image/png/member16.png"
import memtenseven from "../assests/image/png/member17.png"
import memteneight from "../assests/image/png/member18.png"
import memtennine from "../assests/image/png/member19.png"
import memtenten from "../assests/image/png/member20.png"
import memtwoone from "../assests/image/png/member21.png"
import memtwotwo from "../assests/image/png/member22.png"
import memtwothree from "../assests/image/png/member23.png"
import memtwofour from "../assests/image/png/member24.png"
import memtwofive from "../assests/image/png/member25.png"
import memtwosix from "../assests/image/png/member26.png"
import memtwoseven from "../assests/image/png/member27.png"
import memtwoeight from "../assests/image/png/member28.png"
import memtwonine from "../assests/image/png/member29.png"
import memtwoten from "../assests/image/png/member30.png"
import memthreeone from "../assests/image/png/member31.png"
// import memthreetwo from "../assests/image/png/member32.png"
import memthreethree from "../assests/image/png/member33.png"
import memthreefour from "../assests/image/png/member34.png"
import memthreefive from "../assests/image/png/member35.png"

import arrowhome from "../assests/image/svg/arrowhome.svg"
import angdev from "../assests/image/png/angdev.png"
import nodedev from "../assests/image/png/nodedev.png"
import wordpress from "../assests/image/png/wordpress.png"
import apply from "../assests/image/png/apply.png"
import applysel from "../assests/image/png/applyselect.png"
import careerban from "../assests/image/png/careerban.png"
import jobone from "../assests/image/png/jobone.png"
import jobsec from "../assests/image/png/jobthree.png"
import jobthree from "../assests/image/png/jobthree.png"
import jobfour from "../assests/image/png/jobfour.png"
import search from "../assests/image/png/search.png"
import remote from "../assests/image/svg/remote.svg"
import level from "../assests/image/svg/level.svg"
import rupees from "../assests/image/svg/rupees.svg"
import time from "../assests/image/svg/time.svg"
import press from "../assests/image/png/press.png"
import angulardev from "../assests/image/png/angulardev.png"
import mean from "../assests/image/png/mean.png"
import gamedev from "../assests/image/png/gamedev.png"
import nodejs from "../assests/image/png/nodejsdev.png"
import upload from "../assests/image/png/upload.png"
import darkclock from "../assests/image/svg/clock.svg"
import darkremote from "../assests/image/svg/darkremote.svg"
import darkrupees from "../assests/image/svg/darkrupees.svg"
import darkmid from "../assests/image/svg/darkmid.svg"
import designban from "../assests/image/png/designban.png"
import productban from "../assests/image/png/prouctban.png"
import modernban from "../assests/image/png/modernban.png"
import gameban from "../assests/image/png/gameban.png"
import UseCentricDesign from "../assests/image/png/UseCentricDesign.png"
import UXDevelopmentanModernization from "../assests/image/png/UXDevelopmentanModernization.png"
import UXlModernization from "../assests/image/png/UXlModernization.png"
import NewpProductIdeation from "../assests/image/png/NewpProductIdeation.png"
import SolutiDesign from "../assests/image/png/SolutiDesign.png"
import andIdeation from "../assests/image/png/andIdeation.png"
import ConceptCreation from "../assests/image/png/ConceptCreation.png"
import VisualanInteractioDesign from "../assests/image/png/VisualanInteractioDesign.png"
import DevelopmenanImplementation from "../assests/image/png/DevelopmenanImplementation.png"
import innphicconcept from "../assests/image/png/innphicconcept.png"
import productcardone from "../assests/image/png/ideation.png"
import productcardthree from "../assests/image/png/Optimization.png"
import productcardfour from "../assests/image/png/MkaintenanceIteration.png"
import productcardfive from "../assests/image/png/Managedervices.png"
import newproductban from "../assests/image/png/newproductban.png"
import IdeatiaSolutioDesign from "../assests/image/png/IdeatiaSolutioDesign.png"
import MVDesigavelopment from "../assests/image/png/MVDesigavelopment.png"
import IdetMarket from "../assests/image/png/IdetMarket.png"
import Consuland from "../assests/image/png/Consuland.png"
import NeTecMigration from "../assests/image/png/NeTecMigration.png"
import MigratIntegration from "../assests/image/png/MigratIntegration.png"
import UXmok from "../assests/image/png/UXmok.png"
import consulting_auditing from "../assests/image/png/consulting_auditing.png"
import ScopeAnalysianIdeation from "../assests/image/png/ScopeAnalysianIdeation.png"
import StrategDevelopment from "../assests/image/png/StrategDevelopment.png"
import ArchitecturDesign from "../assests/image/png/ArchitecturDesign.png"
import MigratioadImplementation from "../assests/image/png/MigratioadImplementation.png"
import OptimizaionSupport from "../assests/image/png/OptimizaionSupport.png"
import contactban from "../assests/image/png/contactban.png"
import loc from "../assests/image/svg/loc.svg"
import mail from "../assests/image/svg/mail.svg"
import phn from "../assests/image/svg/phn.svg"
import games from "../assests/image/png/games.png"
import arvr from "../assests/image/png/arvr.png"
import interactive from "../assests/image/png/interactive.png"
import LauncOptimization from "../assests/image/png/LauncOptimization.png"
import TklkestingIteration from "../assests/image/png/TklkestingIteration.png"
import plPrototyping from "../assests/image/png/plPrototyping.png"
import DdesigDevelopment from "../assests/image/png/DdesigDevelopment.png"
import Ilmdeation from "../assests/image/png/Ilmdeation.png"
import serviceproductone from "../assests/image/png/serviceprodocutone.png"
import expert from "../assests/image/png/expert.png"
import optimise from "../assests/image/png/optimise.png"
import actionable from "../assests/image/png/actionable.png"
import tailored from "../assests/image/png/tailored.png"
import boost from "../assests/image/png/boost.png"
import fix from "../assests/image/png/fix.png"
import fiture from "../assests/image/png/fiture.png"
import maximize from "../assests/image/png/maximize.png"
import mobileban from "../assests/image/png/mobileban.png"
import webban from "../assests/image/png/webban.png"
import moderizationban from "../assests/image/png/modizationban.png"
import mainban from "../assests/image/png/mainban.png"
import guide from "../assests/image/png/guidecloud.png" 
import blogban from "../assests/image/png/blogban.png"
import modernone from "../assests/image/png/modern-one.png"
import bloginnerban from "../assests/image/png/bloginnerban.png"
import moderntwo from "../assests/image/png/modern-two.png"
import modernthree from "../assests/image/png/modern-three.png"
import modernfour from "../assests/image/png/modern-four.png"
import modernfive from "../assests/image/png/modern-five.png"
import modernsix from "../assests/image/png/modern-six.png"
import modernseven from "../assests/image/png/modern-seven.png"
import webone from "../assests/image/png/mobileone.png"
import webtwo from "../assests/image/png/mobiletwo.png"
import webthree from "../assests/image/png/mobilethree.png"
import webfour from "../assests/image/png/mobilefour.png"
import webfive from "../assests/image/png/moilefive.png"
import websix from "../assests/image/png/mobilesix.png"
import mobileone from "../assests/image/png/webone.png"
import mobiletwo from "../assests/image/png/webtwo.png"
import mobilethree from "../assests/image/png/webthree.png"
import mobilefour from "../assests/image/png/webfour.png"
import mobilefive from "../assests/image/png/webfive.png"
import mobilesix from "../assests/image/png/websix.png"
import maintanceone from "../assests/image/png/maintanceone.png"
import maintancetwo from "../assests/image/png/maintancetwo.png"
import maintancethree from "../assests/image/png/maintancethree.png"
import maintancefour from "../assests/image/png/maintancefour.png"
import maintancefive from "../assests/image/png/maintancefive.png"
import designone from "../assests/image/png/designone.png"
import designtwo from "../assests/image/png/designtwo.png"
import designthree from "../assests/image/png/designthree.png"
import designfour from "../assests/image/png/designfour.png"
import designfive from "../assests/image/png/designfive.png"
import designsix from "../assests/image/png/designsix.png"
import designseven from "../assests/image/png/designseven.png"
import developmentone from "../assests/image/png/developmentone.png"
import developmenttwo from "../assests/image/png/developmenttwo.png"
import developmentthree from "../assests/image/png/developmentthree.png"
import developmentfour from "../assests/image/png/developmentfour.png"
import developmentfive from "../assests/image/png/developmentfive.png"
import developmentsix from "../assests/image/png/developmentsix.png"
import developmentseven from "../assests/image/png/developmentseven.png"
import productone from "../assests/image/png/productone.png"
import producttwo from "../assests/image/png/producttwo.png"
import productthree from "../assests/image/png/productthree.png"
import productfour from "../assests/image/png/productfour.png"
import mvpone from "../assests/image/png/mvpone.png"
import mvptwo from "../assests/image/png/mvptwo.png"
import mvpthree from "../assests/image/png/mvpthree.png"
import mvpfour from "../assests/image/png/mvpfour.png"
import roadmapone from "../assests/image/png/roadmapone.png"
import roadmaptwo from "../assests/image/png/roadmaptwo.png"
import roadmapthree from "../assests/image/png/roadmapthree.png"
import roadmapfour from "../assests/image/png/roadmapfour.png"
import coone from "../assests/image/png/coone.png"
import cotwo from "../assests/image/png/cotwo.png"
import cothree from "../assests/image/png/cothree.png"
import cofour from "../assests/image/png/cofour.png"
import cofive from "../assests/image/png/cofive.png"
import cosix from "../assests/image/png/cosix.png"
import portone from "../assests/image/png/portone.png"
import porttwo from "../assests/image/png/porttwo.png"
import portthree from "../assests/image/png/portthree.png"
import portfour from "../assests/image/png/portfour.png"
import gameone from "../assests/image/png/gameone.png"
import gametwo from "../assests/image/png/gametwo.png"
import gamethree from "../assests/image/png/gamethree.png"
import gamefour from "../assests/image/png/gamefour.png"
import gamefive from "../assests/image/png/gamefive.png"
import gamesix from "../assests/image/png/gamesix.png"
import unrealone from "../assests/image/png/unrealone.png"
import unrealtwo from "../assests/image/png/unrealtwo.png"
import unrealthree from "../assests/image/png/unrealthree.png"
import unrealfour from "../assests/image/png/unrealfour.png"
import testingone from "../assests/image/png/testingone.png"
import testingtwo from "../assests/image/png/testingtwo.png"
import testingthree from "../assests/image/png/testingthree.png"
import testingfour from "../assests/image/png/testingfour.png"
import testingfive from "../assests/image/png/testingfive.png"
import testingsix from "../assests/image/png/testingsix.png"
import arone from "../assests/image/png/arone.png"
import artwo from "../assests/image/png/artwo.png"
import arthree from "../assests/image/png/arthree.png"
import interactiveone from "../assests/image/png/interactiveone.png"
import interactivetwo from "../assests/image/png/interactivetwo.png"
import interactivethree from "../assests/image/png/interactivethree.png"
import interactivefour from "../assests/image/png/interactivefour.png"
import uiuxban from "../assests/image/png/UIUXban.png"
import uiuxdesignban from "../assests/image/png/uiuxdesignban.png"
import proban from "../assests/image/png/productban.png"
import roadmapban from "../assests/image/png/roadmapban.png"
import mvpban from "../assests/image/png/mvpban.png"
import coban from "../assests/image/png/coban.png"
import interactiveban from "../assests/image/png/interactiveban.png"
import testingban from "../assests/image/png/tesingban.png"
import portingban from "../assests/image/png/portingban.png"
import unityban from "../assests/image/png/unityban.png"
import unrealban from "../assests/image/png/unrealban.png"
import arvrban from "../assests/image/png/arvrban.png"
import softwaretwo from "../assests/image/png/softwaretwo.png"
import softwarethree from "../assests/image/png/softwarethree.png"
import softwarefour from "../assests/image/png/softwarefour.png"
import softwarefive from "../assests/image/png/softwarefive.png"
import softwaresix from "../assests/image/png/softwaresix.png"
import softwareseven from "../assests/image/png/softwareseven.png"
import softwareeight from "../assests/image/png/softwareeight.png"
import softwarenine from "../assests/image/png/softwarenine.png"
import volvo from "../assests/image/png/volvo.png"
import airbus from "../assests/image/png/Airbus.png"
import axcore from "../assests/image/png/Axcore.png"
import nizwa from "../assests/image/png/Nizwa.png"
import bgr from "../assests/image/png/BGR.png"
import cerdo from "../assests/image/png/Credo.png"
import hawafa from "../assests/image/png/Hafawa.png"
import kim from "../assests/image/png/kim.png"
import laiqa from "../assests/image/png/Laiqa.png"
import leixier from "../assests/image/png/leixir.png"
import lighthouse from "../assests/image/png/lighthouse.png"
import neuberg from "../assests/image/png/neuberg.png"
import homevideo from "../assests/video/Karto Logo D2.mp4"
import sbigone from "../assests/image/png/sbigone.png"
import sbigtwo from "../assests/image/png/sbigtwo.png"
import sbigthree from "../assests/image/png/sbigthree.png"
import sbigfour from "../assests/image/png/sbigfour.png"
import sbigfive from "../assests/image/png/sbigfive.png"
import sbigsix from "../assests/image/png/sbigsix.png"
import sbigseven from "../assests/image/png/sbigseven.png"
import sbigeight from "../assests/image/png/sbigeight.png"
import sbignine from "../assests/image/png/sbignine.png"
import thaptech from "../assests/image/png/thaptech.png"
import waastech from "../assests/image/png/waastech.png"
import theatech from "../assests/image/png/Theatech.png"
import periodtech from "../assests/image/png/Periodtech.png"
import neubergtech from "../assests/image/png/Neubergtech.png"
import tanuvastech from "../assests/image/png/tanuvas.png"
import laiqatech from "../assests/image/png/laiqatech.png"
import protech from "../assests/image/png/protech.png"
import labtech from "../assests/image/png/labtech.png"
import kartbank from "../assests/image/svg/kartbank.svg"
import kartcertificate from "../assests/image/svg/kartcertificate.svg"
import kartrate from "../assests/image/svg/kartrate.svg"
import unitytech from "../assests/image/png/unitytech.png"
import unrealtech from "../assests/image/png/unrealtech.png"
import file from "../assests/image/svg/addcasepdf.svg"
import button from "../assests/image/png/button.png"
import buttonclick from "../assests/image/png/buttonclick (1).png"
import rightarrow from "../assests/image/png/arrow-right.png"
import weblanban from "../assests/image/png/weblandingban.png"
import logotwo from "../assests/image/png/logotwo.png"
import weblap from "../assests/image/png/weblap.png"
import webtechimg from "../assests/image/png/webtechimg.png"
import webfoodtech from "../assests/image/png/webfoodtech.png"
import webfintech from "../assests/image/png/webfintech.png"
import webhealthtech from "../assests/image/png/webhealthtech.png"
import webedutech from "../assests/image/png/webedutech.png"
import webenter from "../assests/image/png/webenter.png"
import webecom from "../assests/image/png/webecom.png"
// import testimony from "../assests/image/png/testimony.png"
import arrowright from "../assests/image/png/arrowright.png"
import arrowleft from "../assests/image/png/arrowleft.png"
import arrowrightfill from "../assests/image/png/arrowrightfill.png"
import arrowleftfill from "../assests/image/png/arrowleftfill.png"
import footerLand from "../assests/image/png/footer-land.png"
import mobilelandban from "../assests/image/png/mobilelandan.png"
import getrocket from "../assests/image/png/rocket.png"
import getback from "../assests/image/png/getback.png"
import getright from "../assests/image/png/getright.png"
import gifone from "../assests/image/png/gifone.gif"
import gifeight from "../assests/image/png/gifeight.gif"
import gifseven from "../assests/image/png/gifseven.gif"
import gifnine from "../assests/image/png/gifnine.gif"
import gifsix from "../assests/image/png/gifsix.gif"
import gifive from "../assests/image/png/giffive.gif"
import gifour from "../assests/image/png/giffour.gif"
import gifthree from "../assests/image/png/gifthree.gif"
import giftwo from "../assests/image/png/giftwo.gif"
import mobilelandone from "../assests/image/png/mobilelandone.png"
import mobilelandtwo from "../assests/image/png/mobilelandtwo.png"
import mobilelandthree from "../assests/image/png/mobilelandthree.png"
import mobilelandfour from "../assests/image/png/mobilelandfour.png"
import mobilelandfive from "../assests/image/png/mobilelandfive.png"
import mobilelandsix from "../assests/image/png/mobilelandsix.png"
import mobilelandseven from "../assests/image/png/mobilelandseven.png"
import mobilelandeight from "../assests/image/png/mobilelandeight.png"
import jarungandi from "../assests/image/png/jarugandi.png"
import alien from "../assests/image/png/alien.png"
import mixmash from "../assests/image/png/mixmash.png"
import arjuna from "../assests/image/png/arjuna.png"
import worldclan from "../assests/image/png/worldclan.png"
import drarul from "../assests/image/png/drarul.png"
import jaya from "../assests/image/png/jaya.png"
import karthi from "../assests/image/png/karthi.png"
import caseban from "../assests/image/png/caseban.png"
import casephone from "../assests/image/png/casestudyphone.png"
import caselap from "../assests/image/png/casestudylap.png"
import casemobiles from "../assests/image/png/casestudymobiles.png"
import caseprocessone from "../assests/image/svg/caseprocessone.svg"
import caseprocesstwo from "../assests/image/svg/caseprocesstwo.svg"
import caseprocessthree from "../assests/image/svg/caseprocessthree.svg"
import caseprocessfour from "../assests/image/svg/caseprocessfour.svg"
import Consumerization from "../assests/image/svg/Consumerization.svg"
import Productization from "../assests/image/svg/Productization.svg"
import Servitization from "../assests/image/svg/Servitization.svg"
import Uberization from "../assests/image/svg/Uberization.svg"
import hwotu from "../assests/image/svg/hwotu.svg"
import wols from "../assests/image/svg/wols.svg"
import pati from "../assests/image/svg/pati.svg"
import cuspban from "../assests/image/svg/cuspban.svg"
import knowban from "../assests/image/png/knowban.png"
import weblog from "../assests/image/png/weblog.png"
import webenergy from "../assests/image/png/webenergy.png"
import webinternet from "../assests/image/png/webinternet.png"
import webtele from "../assests/image/png/webtele.png"
import iphthap from "../assests/image/svg/iphthap.svg"
import tolsshk from "../assests/image/svg/tolsshk.svg"
import gameengtwo from "../assests/image/png/Advanced Graphics and Animation.png"
import gameengone from "../assests/image/png/immersive_story_telling.png"
import gameengthree from "../assests/image/png/Robust Engineering for Seamless Gameplay.png"
import gameengfour from "../assests/image/png/board.png"
import gameengfive from "../assests/image/png/CommunityCentricApproach.png"


const Images = {
    arone,artwo,arthree,interactiveone,interactivetwo,interactivethree,interactivefour,
    Consumerization,Productization,Servitization,Uberization,hwotu,pati,wols,cuspban,iphthap,tolsshk,
    testingone,testingtwo,testingthree,testingfour,testingfive,testingsix,knowban,
    unrealone,unrealtwo,unrealthree,unrealfour,uiuxban,roadmapban,coban,unityban,
    gameone,gametwo,gamethree,gamefour,gamefive,gamesix,mvpban,arvrban,unrealban,
    portone,porttwo,portthree,portfour,proban,uiuxdesignban,interactiveban,file,
    coone,cotwo,cothree,cofour,cofive,cosix,testingban,softwarefour,softwarefive,
    roadmapone,roadmaptwo,roadmapthree,roadmapfour,softwarethree,softwaresix,
    jarungandi,alien,arjuna,mixmash,worldclan,drarul,jaya,karthi,caseban,
    weblog,webenergy,webtele,webinternet,gameengone,gameengtwo,gameengthree,
    gameengfour,gameengfive,
    mvpone,mvptwo,mvpthree,mvpfour,portingban,softwaretwo,volvo,nizwa,cerdo,kim,
    productone,producttwo,productthree,productfour,airbus,axcore,bgr,hawafa,
    webecom,footerLand,softwareseven,softwareeight,developmentsix,developmentseven,
    webfoodtech,webfintech,webhealthtech,webedutech,webenter,arrowleft,
    developmentone,developmenttwo,developmentthree,developmentfour,developmentfive,
    designone,designtwo,designthree,designfour,designfive,designsix,designseven,
    maintanceone,maintancetwo,maintancethree,maintancefour,maintancefive,homevideo,
    mobileone,mobiletwo,mobilethree,mobilefour,mobilefive,mobilesix,arrowright,
    mobilelandone,mobilelandtwo,mobilelandthree,mobilelandfour,mobilelandfive,
    mobilelandsix,mobilelandseven,mobilelandeight,casephone,caselap,casemobiles,
    memtenone,memtentwo,memtenthree,memtenfour,memtenfive,memtensix,memtenseven,memteneight,
    memtennine,memtenten,memtwoone,memtwotwo,memtwothree,memtwofour,memtwofive,memtwosix,memtwoseven,
    memtwoeight,memtwonine,memtwoten,memthreeone,memthreethree,memthreefour,memthreefive,
    moderntwo,modernthree,modernfour,modernfive,modernsix,modernseven,arrowleftfill,
     webone, webtwo, webthree, webfour, webfive, websix,laiqa,arrowrightfill,
    thechn, mylabcnbk, etsilbk, bgrbk,interactive,leixier,rightarrow,getright,
    caseprocessone,caseprocesstwo,caseprocessthree,caseprocessfour,
    jsre, theav, neubgbk, tanusbk, laiqabk,games,arvr,labtech,mobilelandban,
    period, DashboardGlobl, mobilitymeabk,loc,neuberg,tanuvastech,getrocket,
    sbigone,sbigtwo,sbigthree,sbigfour,sbigfive,sbigsix,sbigseven,sbigeight,sbignine,
    perio, volvbk, sparktkbk, Wordclanh, memone,periodtech,getback,gifone,
    thapp, eclipegg, eclipevn, memthree,phn,lighthouse,kartrate,gifseven,
    development: development, memfour, memtwo,mail,kartbank,gifeight,
    ConceptCreation:ConceptCreation,optimise,thaptech,kartcertificate,
    productcardthree:productcardthree,expert,waastech,memfive,
    productcardfour:productcardfour,Ilmdeation,laiqatech,protech,
    newproductban:newproductban,plPrototyping,unitytech,gifnine,
    IdetMarket:IdetMarket,TklkestingIteration,neubergtech,gifsix,
    contactban:contactban,DdesigDevelopment,theatech,unrealtech,
    graph: graph,LauncOptimization,tailored,softwarenine,
    OptimizaionSupport:OptimizaionSupport,actionable,buttonclick,
    MigratioadImplementation:MigratioadImplementation,logotwo,
    ArchitecturDesign:ArchitecturDesign,bloginnerban,memnine,memten,
    StrategDevelopment:StrategDevelopment,memsix,memseven,memeight,
    ScopeAnalysianIdeation:ScopeAnalysianIdeation,gifthree,giftwo,
    Consuland:Consuland,serviceproductone,button,gifour,
    MVDesigavelopment:MVDesigavelopment,weblanban,
    IdeatiaSolutioDesign:IdeatiaSolutioDesign,gifive,
    productcardfive:productcardfive,modernone,
    productcardone:productcardone,webban,webtechimg,
    darkmid:darkmid,maximize,fiture,boost,fix,
    UXDevelopmentanModernization:UXDevelopmentanModernization,
    innphicconcept:innphicconcept,moderizationban,
    andIdeation:andIdeation,mobileban,mainban,
    NewpProductIdeation:NewpProductIdeation,blogban,
    DevelopmenanImplementation:DevelopmenanImplementation,
    VisualanInteractioDesign:VisualanInteractioDesign,
    SolutiDesign:SolutiDesign,guide,
    UXlModernization:UXlModernization,
    darkrupees:darkrupees,weblap,
    NeTecMigration:NeTecMigration,
    darkremote:darkremote,
    consulting_auditing:consulting_auditing,
    angulardev:angulardev,
    UseCentricDesign:UseCentricDesign,
    mean:mean,
    UXmok:UXmok,
    MigratIntegration:MigratIntegration,
    gamedev:gamedev,
    designban:designban,
    modernban:modernban,
    darkclock:darkclock,
    gameban:gameban,
    press:press,
    nodejs:nodejs,
    upload:upload,
    productban:productban,
    level:level,
    time:time,
    rupees:rupees,
    remote:remote,
    search:search,
    deltas: deltas,
    careerban:careerban,
    technologies: technologies,
    phone: phone,
    angdev:angdev,
    nodedev:nodedev,
    applysel:applysel,
    jobthree:jobthree,
    jobfour:jobfour,
    jobsec:jobsec,
    apply:apply,
    jobone:jobone,
    wordpress:wordpress,
    spartek: spartek,
    bank: bank,
    mobility: mobility,
    kiddle: kiddle,
    alike: alike,
    abtBan: abtBan,
    aspiration: aspiration,
    nextmove: nextmove,
    global: global,
    arrowhome:arrowhome,
    digital: digital,
    technology: technology,
    chess: chess,
    rocket: rocket,
    serBan: serBan,
    computer: computer,
    lenarrow: lenarrow,
    cplus: cplus,
    certified: certified,
    quotes: quotes,
    list: list,
    cc: cc,
    js: js,
    serviceban: serviceban,
    angular: angular,
    mango: mango,
    sql: sql,
    css: css,
    node: node,
    docker: docker,
    KartoffelLogo: KartoffelLogo,
    dropdown: dropdown,
    fb: fb,
    x: x,
    linked: linked,
    insta: insta,
    consulting: consulting,
    digitalproduct: digitalproduct,
    viable: viable,
    service: service,
    learnmore: learnmore,
    idea: idea,
    uparrow: uparrow,
    downarrow: downarrow,
    second: second,
    colorup: colorup,
    colordown: colordown

}
export default Images