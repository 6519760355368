// src/components/Service/ServiceBan.js
import React, { useState } from 'react';
import "../../assests/css/serBan.css";
import LandContactModal from "../../commonComponents/ServiceInnerBan/LandContactModal"
import Button from 'react-bootstrap/Button';



const ServiceBan = ( {bann , onKnowMoreClick }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className='aboutBannersection land-bannersection'>
            {bann.map((item, index) => (
                <div key={index} className='aboutBanMain'>
                    <img className='aboutImgBan' src={item.img} alt="banner-img" />
                    <div className='bannerContent land-ban-web'>
                        {/* <button className="bannerButton" onClick={() => setShowModal(true)}>Let's Talk</button> */}
                        <button className="bannerButton" onClick={onKnowMoreClick}>Let's Talk</button>
                        {/* <Button variant="outline-lights bn-coomon"  onClick={() => setShowModal(true)} className="get-in-touch-btn">Let's Talk</Button> */}
                    </div>
                </div>
            ))}
             <LandContactModal show={showModal} setShow={setShowModal} />
        </div>
    );
}

export default ServiceBan;
