import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import '../../assests/css/ServiceSlider.css';
import Images from "../../Images/Images";

const ImageSlider = ({ sliderData }) => { // Accept sliderData as a prop
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <div className='service-slide'>
            <Container>
                <Row className="slide-gap">
                    <Col xs={12} md={12} lg={6}>
                        <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false}>
                            {sliderData.map((slide, idx) => (
                                <Carousel.Item key={idx}>
                                    <div className="text-section">
                                        <h1 className='service-no'>{slide.number}</h1>
                                        <h2 className=''>{slide.title}</h2>
                                        <p className='slider-para'>{slide.description}</p>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                    <Col xs={12} md={12} lg={4} className='p-0 image-part'>
                        <Carousel activeIndex={index} onSelect={handleSelect} controls={false} indicators={false}>
                            {sliderData.map((slide, idx) => (
                                <Carousel.Item key={idx}>
                                    <div className="image-section">
                                        <img
                                            className="d-block"
                                            src={slide.image}
                                            alt={slide.title}
                                        />
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                    <Col xs={12} md={12} lg={2} className='p-0 bn-slide'>
                        <div className="carousel-controls">
                            <button  onClick={() => setIndex(index + 1 < sliderData.length ? index + 1 : index)}>
                                <img className='ser-up' src={Images.uparrow} alt="Up Arrow" />
                            </button>
                            <button   onClick={() => setIndex(index - 1 >= 0 ? index - 1 : 0)}>
                                <img className='ser-down' src={Images.downarrow} alt="Down Arrow" />
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ImageSlider;
