import React, { useState } from 'react';
import "../../assests/css/ProcessSteps.css";
import Images from "../../Images/Images";

const ProcessSteps = () => {
  const [hoveredStep, setHoveredStep] = useState(null); // State to track which step is hovered

  const handleMouseEnter = (step) => {
    setHoveredStep(step); // Set the hovered step
  };

  const handleMouseLeave = () => {
    setHoveredStep(null); // Reset when the mouse leaves
  };

  return (
    <div className="container my-5 processsteps">
      <div className="row process-row">
        {/* Left side heading */}
        <div className="col-lg-4 col-md-4 col-sm-12">
          <h2 className="process-title">How do we work to help you</h2>
        </div>
        {/* Right side steps */}
        <div className="col-lg-7 col-md-7 col-sm-12">
          <div className="row">
            {/* Step 1 */}
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <div
                className="process-step d-flex"
                onMouseEnter={() => handleMouseEnter(1)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="step-number">1</div>
                {/* Conditionally render content based on hover state */}
                {hoveredStep === 1 ? (
                  <div className="process-step-color">
                    <div className="step-content-color">
                      <h5 className="step-title-color">Design</h5>
                      <p className="step-description">
                        Creating user-focused designs that meet your
                        <br /> business objectives.
                      </p>
                    </div>
                    <div>
                      <img src={Images.caseprocessone} alt="Design" />
                    </div>
                  </div>
                ) : (
                  <div className="step-content">
                    <h5 className="step-title">Design</h5>
                    <p className="step-description">
                      Creating user-focused designs that meet your
                      <br /> business objectives.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* Step 2 */}
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <div
                className="process-step d-flex"
                onMouseEnter={() => handleMouseEnter(2)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="step-number">2</div>
                {hoveredStep === 2 ? (
                  <div className="process-step-color d-flex align-center">
                    <div className="step-content-color">
                      <h5 className="step-title-color">Development</h5>
                      <p className="step-description">
                        Developing tailored solutions that are both
                        <br /> robust and scalable.
                      </p>
                    </div>
                    <div>
                      <img src={Images.caseprocesstwo} alt="Design" />
                    </div>
                  </div>
                ) : (
                  <div className="step-content">
                    <h5 className="step-title">Development</h5>
                    <p className="step-description">
                      Developing tailored solutions that are both
                      <br /> robust and scalable.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* Step 3 */}
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <div
                className="process-step d-flex"
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="step-number">3</div>
                {hoveredStep === 3 ? (
                  <div className="process-step-color d-flex align-center">
                    <div className="step-content-color">
                      <h5 className="step-title-color">Testing</h5>
                      <p className="step-description">
                        Conducting thorough testing to ensure top-
                        <br /> quality performance.
                      </p>
                    </div>
                    <div>
                      <img src={Images.caseprocessthree} alt="Design" />
                    </div>
                  </div>
                ) : (
                  <div className="step-content">
                    <h5 className="step-title">Testing</h5>
                    <p className="step-description">
                      Conducting thorough testing to ensure top-
                      <br /> quality performance.
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* Step 4 */}
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <div
                className="process-step d-flex"
                onMouseEnter={() => handleMouseEnter(4)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="step-number">4</div>
                {hoveredStep === 4 ? (
                  <div className="process-step-color ">
                    <div className="step-content-color">
                      <h5 className="step-title-color">Live</h5>
                      <p className="step-description">
                        Conducting thorough testing to ensure top-
                        <br /> quality performance.
                      </p>
                    </div>
                    <div>
                      <img src={Images.caseprocessfour} alt="Design" />
                    </div>
                  </div>
                ) : (
                  <div className="step-content">
                    <h5 className="step-title">Live</h5>
                    <p className="step-description">
                      Conducting thorough testing to ensure top-
                      <br /> quality performance.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessSteps;
