import React, { useState, useEffect, useRef } from 'react';
import '../../assests/css/ProjectCount.css';
import Images from "../../Images/Images";

const Statistics = () => {
    const [productCount, setProductCount] = useState(0);
    const [expertCount, setExpertCount] = useState(0);
    const [enterpriseCount, setEnterpriseCount] = useState(0);
    const [hasStarted, setHasStarted] = useState(false);
    const statisticsRef = useRef(null);

    useEffect(() => {
        const currentElement = statisticsRef.current;

        const updateCount = (setter, endValue) => {
            let count = 0;
            setter(count);
            const intervalId = setInterval(() => {
                if (count >= endValue) {
                    clearInterval(intervalId);
                } else {
                    count += 1;
                    setter(count);
                }
            }, 20);
            return intervalId;
        };

        const handleIntersect = (entries) => {
            if (entries[0].isIntersecting && !hasStarted) {
                setHasStarted(true);
                updateCount(setProductCount, 100);
                updateCount(setExpertCount, 50);
                updateCount(setEnterpriseCount, 19);
            }
        };

        const observer = new IntersectionObserver(handleIntersect, {
            threshold: 0.1,
        });

        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [hasStarted]);

    return (
        <div className='project-count container' ref={statisticsRef}>
            <div className="statistics-section">
                <div className="statistic-item">
                    <div className='img-count'>
                        <img src={Images.kartrate} alt='rate' />
                    </div>
                    <div>
                        <div className="statistic-value pro-count">{productCount}+</div>
                        <div className="statistic-label">Products Released</div>
                    </div>
                </div>
                <div className="statistic-item">
                    <div className='img-count'>
                        <img src={Images.kartcertificate} alt='certificate' />
                    </div>
                    <div>
                        <div className="statistic-value certificate-count">{expertCount}+</div>
                        <div className="statistic-label">Certified Experts</div>
                    </div>
                </div>
                <div className="statistic-item">
                    <div className='img-count'>
                        <img src={Images.kartbank} alt='bank' />
                    </div>
                    <div>
                        <div className="statistic-value enter-count">{enterpriseCount}+</div>
                        <div className="statistic-label">Enterprises Modernized</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Statistics;
