import React, { useState } from 'react';
import "../../assests/css/ContactModal.css";
import img from '../../Images/Images';

const LandContactModal = ({ show, setShow }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    countryCode: '+91',
    wehelp: '', // The help textarea
  });
  const [formError, setFormError] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNumberChange = (e) => {
    // Allow only numeric characters and limit length to 10 digits
    const value = e.target.value.replace(/\D/g, '').slice(0, 10);
    setFormData({ ...formData, number: value });
  };

  const handleCountryCodeChange = (e) => {
    // Allow numbers, +, and -; limit length to 5
    const value = e.target.value;
    const formattedValue = value.replace(/[^0-9+-]/g, '').slice(0, 5);
    setFormData({ ...formData, countryCode: formattedValue });
  };

  const validateForm = () => {
    if (!formData.name) return 'Name is required.';
    if (!formData.email) return 'Email is required.';
    if (!formData.number) return 'Phone number is required.';

    const regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (!regexEmail.test(formData.email)) return 'Invalid email format.';

    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorMsg = validateForm();
    if (errorMsg) {
      setFormError(errorMsg);
      return;
    }

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          to: "info@kartoffel.in",
          subject: `New Contact from ${formData.name}`,
          body: ` <p><strong>Name:</strong> ${formData.name}</p>
                    <p><strong>Email:</strong>${formData.email}</p>
                    <p><strong>Phone:</strong> ${formData.countryCode} ${formData.number}</p>
                    <p><strong>Loking For:</strong>${formData.wehelp}</p>`,
        }),
      };

      const response = await fetch("https://kartoffel.in/api/send-mail", requestOptions);
      const result = await response.json();
      console.log(result);

      setSubmissionSuccess(true);
      setTimeout(() => {
        setSubmissionSuccess(false);
      }, 5000);

      setFormData({
        name: '',
        email: '',
        number: '',
        countryCode: '+91',
        wehelp: '',
      });
      setFormError('');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const details = [
    { image: img.list, text: 'Swift response from us.' },
    { image: img.list, text: 'Detailed one-on-one discovery & consultation.' },
    { image: img.list, text: 'Customized solution proposal will be shared.' },
    { image: img.list, text: 'Strict adherence to timeline and milestones.' },
    { image: img.list, text: 'Seamless collaboration and progress.' },
  ];

  return (
    <div>
      <div className={`modal fade ${show ? 'show d-block' : 'd-none'}`} tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header hed">
              <button type="button" className="btn-close" onClick={() => setShow(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body popUp">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 contactSinputS">
                  <h3 className="contactSlide">CONTACT US</h3>
                  <form className='formIn' onSubmit={handleSubmit}>
                    <div className={`form-group name ${formData.name ? 'filled' : ''}`}>
                      <input
                        type="text"
                        placeholder='Name *'
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={`form-group email ${formData.email ? 'filled' : ''}`}>
                      <input
                        type="text"
                        placeholder='Email *'
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={`form-group num ${formData.number ? 'filled' : ''}`}>
                      <div className="country-code">
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          id="countryCode"
                          name="countryCode"
                          value={formData.countryCode}
                          onChange={handleCountryCodeChange}
                        />
                      </div>
                      <div className="phone-number">
                        <input
                          type="tel"
                          placeholder="Number *"
                          className="form-control"
                          id="number"
                          name="number"
                          value={formData.number}
                          onChange={handleNumberChange}
                        />
                      </div>
                    </div>
                    <div className={`form-group help ${formData.wehelp ? 'filled' : ''}`}>
                      <textarea
                        placeholder="How can we help?"
                        className="form-control"
                        name="wehelp"
                        value={formData.wehelp}
                        onChange={handleInputChange}
                      />
                    </div>
                    <br />
                    <button type="submit" className="btn btn-primary sub">
                      Submit
                    </button>
                    {formError && (
                      <p className="text-danger mt-2">
                        {formError}
                      </p>
                    )}
                    {submissionSuccess && (
                      <p className="text-success mt-2">
                        Form submitted successfully!
                      </p>
                    )}
                  </form>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 whatHpnsSlide">
                  <h3 className='hpnsnxtbnm'>WHAT HAPPENS NEXT?</h3>
                  <div className="happnsNextDetails">
                    {details.map((detail, index) => (
                      <div key={index} className="starTextt">
                        <span><img src={detail.image} alt="" /></span>
                        <p>{detail.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandContactModal;
