import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import necessary hooks from react-router-dom
import Images from '../../../Images/Images';
import '../../../assests/css/OurBlog.css';

const KnowledgeBlogsCenter = () => {
    const blogData = [
        { 
          date: 'May 22, 2024', 
          content: 'How to Choose the Right Software Development Methodology for Your Project', 
          para: "Discuss various methodologies like Agile, Scrum, Waterfall, and DevOps, and provide guidance on selecting the best one for different project types.", 
          link: '', 
          image: Images.hwotu // Add image path for each blog
        },
        { 
          date: 'May 8, 2024', 
          content: 'The chaos of tackling an undocumented code.', 
          para: 'Undocumented code can be a nightmare for developers. Whether you are inheriting a legacy code', 
          link: '', 
          image: Images.guide 
        },
        { 
            date: 'April 18, 2024', 
            content: `Going Native v/s Going Hybrid.`, 
            para: `Crafting a mobile app requires a crucial decision: hybrid or native development?`, 
            link: '', 
            image: Images.softwaretwo 
          },
          { 
            date: 'April 10, 2024', 
            content: `Administrators' Multifaceted Roles`, 
            para: 'Building awesome products can seem glamorous.', 
            link: '', 
            image: Images.softwarethree 
          },
       
      ];

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleApplyClick = (link) => {
    navigate(link);
  };

  return (
    <div className='our-blogs'>
    <div className='container'>
      <p className='text-center head' style={{fontSize:"40px"}}>Blogs</p>
      <div className='row blog-rows'>
        {blogData.map((blog, index) => (
          <div
            key={index}
            className='col-lg-6 col-md-6 col-sm-12 mb-4'
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className="blog-card d-flex flex-column"
              style={{
                background: hoveredIndex === index
                ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.83), rgba(255, 255, 255, 0.83)), linear-gradient(95.61deg, #FF9233 -1.73%, #00AEEF 100%)'
                : 'transparent',
              }}
            >
              <div>
              <div className='blo-img'>

                              <img src={blog.image} className="card-img-top" alt={blog.title} />
                          </div>
                <p
                  className='content'
                  style={{
                    color: hoveredIndex === index ? 'rgba(37, 41, 53, 1)' : 'rgba(255, 255, 255, 1)',
                  }}
                >
                  {blog.content}
                </p>
                <p
                  className='content-para'
                  style={{
                      color: hoveredIndex === index ? 'rgba(37, 41, 53, 1)' : 'rgba(255, 255, 255, 0.6)',
                  }}
                >
                  {blog.para}
                </p>
              </div>
              <div className='explore-link-whole'>
                <Link to={blog.link} className='explore-link'>Read More</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='hover-image-container'>
        <img className='hover-image blo-unique' src={Images.button} alt="Know More" />
        <img className='button-image blo-unique' src={Images.buttonclick} alt="Know More Hover" />
        <div className='hover-text' onClick={() => handleApplyClick("")}>
          <p className='home-know'>View Blogs Pages</p>
          <span className='arrow-icon'>
            <img src={Images.rightarrow} alt="Right Arrow" />
          </span>
        </div>
      </div>
    </div>
  </div>
  )
}

export default KnowledgeBlogsCenter