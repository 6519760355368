import React, { useRef, useEffect } from 'react';
import NavbarHeader from '../../commonComponents/header/NavbarHeader';
import Footer from '../../commonComponents/footer/footer';
import OurService from '../../component/Home/OurService';
import HomeBanner from '../../component/Home/HomeBanner';
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient';
import TheHappinessProject from '../../component/Home/TheHappinessProject';
import OurBlogs from '../../component/Home/OurBlog';
import CareerSection from '../../component/Home/Careers';
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch';
import ProductAnimations from "../../component/ProductAnimations/ProductAnimations";
import Seo from "../../commonComponents/MetaTag/MetaTag";
import throttle from 'lodash/throttle';
import Statistics from '../../commonComponents/ProjectCount/ProjectCount';
import Testimony from '../../component/Home/Tesimony';
import TestimonialCarousel from '../../component/Home/Tesimony';

const HomePage = () => {
  const containerRef = useRef(null);
  const ourServiceRef = useRef(null);
  const ourBlogRef = useRef(null)

  const handleKnowMoreClick = () => {
    if (ourServiceRef.current) {
      ourServiceRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleOurBlog = () => {
    if (ourBlogRef.current) {
      ourBlogRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const divSections = Array.from(containerRef.current.children);
      divSections.forEach((section, index) => {
        const height = section.clientHeight;
        console.log(`Section ${index + 1} height:`, height);
      });
    }

    const handleScroll = throttle(() => {
      if (window.innerWidth > 768) {
        let lastScrollTop = 0;
        const scrollPosition = window.scrollY;
        const someDiv = document.getElementById('aaaaaaaaaaaaaaaaaa');
        if (someDiv) {
          const distanceToTop = someDiv.getBoundingClientRect().top;
          if ((distanceToTop > 550 && distanceToTop < 580) && scrollPosition > lastScrollTop) {
            window.scrollTo({ top: 1500, behavior: 'smooth' });
          }

          if (distanceToTop > -560 && distanceToTop < -550 && scrollPosition < lastScrollTop) {
            window.scrollTo({ top: 1300, behavior: 'smooth' });
          }
        }
        lastScrollTop = scrollPosition;
      }
    }, 100);

    if (window.innerWidth > 768) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const meta_description = "Kartoffel Technologies is an IT Consulting firm that helps businesses thrive in the digital age. We deliver change through AI, data, and product engineering services.";
  const meta_title = "Kartoffel Technologies | IT Consulting Company";
  const meta_keyword = "Technology consulting, IT outsourcing, technology services, it service provider, technology solutions, software consulting, software development outsourcing, software development services, offshore software development, IoT, cybersecurity, digital transformation, automation, business analytics, application development, IT Services, Business Consulting, IT outsourcing, digital solutions"

  const ProductService = [
    {
      description: "Kartoffel team provided us with a clear plan as to how the design should be, how the process should be , all the way up to launch of the website and the app and how it must be from an end user perspective. It was clean and simple."
    },
    {
      description: "Kartoffel team provided us with a clear plan as to how the design should be, how the process should be , all the way up to launch of the website and the app and how it must be from an end user perspective. It was clean and simple."
    }
  ]

  const client = {
    classname: "client"
  };

  return (
    <div className='homepage' ref={containerRef}>
      <Seo meta_description={meta_description} meta_title={meta_title} meta_keyword={meta_keyword} />
      <NavbarHeader onKnowMoreClick={handleKnowMoreClick} onOurBlog={handleOurBlog} />
      <HomeBanner onKnowMoreClick={handleKnowMoreClick} />
      <div ref={ourServiceRef} style={{ marginTop: "-8px" }}>
        <OurService />
      </div>
      <Statistics />
      <OurTrustedPartners client={client}/>
      {/* <div className='aaaaaaaaaaaaaaaaaa' id='aaaaaaaaaaaaaaaaaa'> */}
      <ProductAnimations />
      {/* </div> */}
      <TestimonialCarousel/>
      <div ref={ourBlogRef}>
        <OurBlogs />
      </div>
      <CareerSection />
      <GetInTouch />
      <Footer />
    </div>
  );
};

export default HomePage;
