import Images from "../../Images/Images.js"

const categories = ["Agile", "Software", "Trend", "Tech", "AWS", "Cloud", "UX"];

const blogPosts = [
    {
        category: "Software",
        title: "The chaos of tackling an undocumented code.",
        description: "Undocumented code can be a nightmare for developers. Whether you are inheriting a legacy code",
        image: Images.guide,
         link: '/tackling'
    },
    {
        category: "Software",
        title: "Going Native v/s Going Hybrid.",
        description: "Crafting a mobile app requires a crucial decision: hybrid or native development?",
        image: Images.softwaretwo,
        link:'/native ' 
    },
    {
        category: "Software",
        title: "Administrators' Multifaceted Roles:",
        description: "Building awesome products can seem glamorous.",
        image: Images.softwarethree,
        link:'/administrators ' 
    },
    {
        category: "Software",
        title: "virtual reality and the metaverse ",
        description: "I cannot say this enough but virtual reality and the metaverse will always need a skilled tester",
        image: Images.softwarefour,
        link:'/testing ' 
    },
    {
        category: "Software",
        title: "Tailoring Software Architecture To Your Start-Up's Needs.",
        description: "In software development, choosing the right architecture is crucial for the success of your project.",
        image: Images.softwarefive,
        link:'/architecture ' 
    },
    {
        category: "Software",
        title: "Corporate Recruitment and Attracting Top Talent.",
        description: "As an HR Executive deeply involved in recruitment and sourcing",
        image: Images.softwaresix,
        link:'/recruitment ' 
    },
    {
        category: "Software",
        title: "SNAP IT, STITCH IT, SCULPT IT.",
        description: "Photogrammetry is a technique with which we can generate a proprietary 3D file format called FilmBox (FBX) with textures using 2D images.",
        image: Images.softwareseven,
        link:'/Photogrammetry ' 
    },
    {
        category: "Software",
        title: "Employee Off boarding",
        description: "Every company meticulously crafts an onboarding process, warmly welcoming each new team member who walks through their doors",
        image: Images.softwareeight,
        link:'/offboarding ' 
    },
    {
        category: "Software",
        title: "The Core Identity Of Your Game.",
        description: "the game industry has been around long enough to encapsulate the best bits from all of them.",
        image: Images.softwarenine,
        link:'/game ' 
    },
    // {
    //     category: "Software",
    //     title: "Case Study: How We Helped [Client Name] Achieve Digital Transformation",
    //     description: "A detailed case study on how we guided a client through a successful digital transformation.",
    //     image: Images.softwaretwo,
    //     link:'/native ' 
    // },
    // {
    //     category: "Software",
    //     title: "The Future of Mobile App Development: What to Expect",
    //     description: "Analyzing upcoming trends and innovations in mobile app development.",
    //     image: Images.softwareseven,
    //     link:'/native ' 
    // },
    // {
    //     category: "Agile",
    //     title: "The Future of Mobile App Development: What to Expect",
    //     description: "Analyzing upcoming trends and innovations in mobile app development.",
    //     image: Images.softwareseven,
    // },
    // {
    //     category: "Trend",
    //     title: "Case Study: How We Helped [Client Name] Achieve Digital Transformation",
    //     description: "A detailed case study on how we guided a client through a successful digital transformation.",
    //     image: Images.softwaretwo,
    // },
    // {
    //     category: "AWS",
    //     title: "The Role of Artificial Intelligence in Modern Software Solutions",
    //     description: "Exploring how AI is revolutionizing software development and enhancing user experiences.",
    //     image: Images.softwarethree,
    // },
    // {
    //     category: "Cloud",
    //     title: "The Future of Mobile App Development: What to Expect",
    //     description: "Analyzing upcoming trends and innovations in mobile app development.",
    //     image: Images.softwarefive,
    // },
    // {
    //     category: "UX",
    //     title: "The Role of Artificial Intelligence in Modern Software Solutions",
    //     description: "Exploring how AI is revolutionizing software development and enhancing user experiences.",
    //     image: Images.softwaresix,
    // },
    // {
    //     category: "Tech",
    //     title: "The Future of Mobile App Development: What to Expect",
    //     description: "Analyzing upcoming trends and innovations in mobile app development.",
    //     image: Images.softwarefive,
    // },
];

export { categories, blogPosts };
