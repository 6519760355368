import React, { useRef } from 'react';
import Images from '../../Images/Images';
import "../../assests/css/HomeBanner.css";

const HomeBanner = ({ onKnowMoreClick }) => {
    const bann = [
        {
            // img: Images.global,
            video: Images.homevideo
            // text: ""
        }
    ];
    return (
        <div className='homeBannersection'>
            {bann.map((item, index) => (
                <div key={index} className='HomeBanMain'>
                    {/* <div className='global-img'>
                        <img className='homeImgBan' src={item.img} alt="banner-img" />
                    </div> */}
                    <div className='global-video'>
                        <video className='homeVideoBan' src={item.video} autoPlay loop muted playsInline />
                    </div>
                    <div className='banner'>
                        {/* <img className="make" src={Images.nextmove} alt="Heading" /> */}
                        {/* <p className='container tech-cont'>We see technology as this infinite energy source that can power even<br/> the most intricate ideas that cross our minds.
                            If it can be thought of, it <br/> can be created and brought to life.</p> */}
                        {/* <div className='bannerContent'> */}
                        <h1 className='home-font'>Transform Your Business with CUSP.</h1>
                        <p className='homie-para'>Making Technological Advancements through Consumerization, Uberization, Servitization and Productization.  </p>
                        {/* <button className="bannerButton">Know More</button> */}
                        {/* </div> */}
                        {/* <button className="bannerButton" onClick={onKnowMoreClick}>Know More</button> */}
                        <div className='hover-image-container'>
                            <img className='hover-image  img-unique' src={Images.button} alt="Know More" />
                            <img className='button-image img-unique' src={Images.buttonclick} alt="Know More Hover" />
                            <div className='hover-text'  onClick={onKnowMoreClick}>
                                <p className='home-know'>Know More</p>
                                <span className='arrow-icon'>
                                    <img src={Images.rightarrow} alt="Right Arrow" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default HomeBanner;
