import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img from '../../Images/Images';
import '../../assests/css/JobSlider.css';

const JobSlider = () => {
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 767);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const detailsArray = [
        {
            title: 'Wordpress\nDeveloper',
            jobsAvailable: 'Jobs Available',
            image: img.jobone,
            number: 1,
        },
        {
            title: 'Angular\nDeveloper',
            jobsAvailable: 'Jobs Available',
            image: img.jobsec,
            number: 2,
        },
        {
            title: 'Node\nDeveloper',
            jobsAvailable: 'Jobs Available',
            image: img.jobthree,
            number: 3,
        },
        {
            title: 'MEAN Stack\nDeveloper',
            jobsAvailable: 'Jobs Available',
            image: img.jobfour,
            number: 4,
        },
        {
            title: 'Game\nDeveloper',
            jobsAvailable: 'Jobs Available',
            image: img.jobthree,
        },
        // Add more details as needed
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Display one slide at a time
        slidesToScroll: 1, // Scroll one slide at a time
        autoplay: true,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 1025, // Medium devices
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // Small devices
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480, // Extra small devices
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='updateOpenings'>
            <div className="container jobs">
                <div className='detailsUpcomingHire'>
                    <p className='newOpeningHire'>Let's Build Together.</p>
                    <p className='newOpengJobssun'>
                        {isWideScreen
                            ? 'Our software engineering process involves close\ncollaboration, precise planning, a commitment\n and to delivering solutions that are both\nagile and dependable.'
                            : 'Our software engineering process involves close collaboration, precise planning, and a commitment to delivering solutions that are both agile and dependable.'}
                    </p>
                    Our software engineering process involves close collaboration, precise planning, a commitment and to delivering solutions that are both agile and dependable.
                </div>
                <Slider {...settings}>
                    {detailsArray.map((detail, index) => (
                        <div key={index}>
                            <div className='contentDetailsUpdtJob'>
                                <div className=''>
                                    <img className='updatedImgj' src={detail.image} alt="" />
                                </div>
                                <div className="updateContentopen">
                                    <p className='contentWrt'>{detail.title}</p>
                                    {/* <p className='jobsAvailb'>{detail.jobsAvailable}</p> */}
                                </div>
                                {/* <div>
                                    <div className="numbContbv">{detail.number}</div>
                                </div> */}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default JobSlider;
