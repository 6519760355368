import React, { useState, useEffect, useRef } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import "../../assests/css/Navbar.css";
import Images from "../../Images/Images";
import { Link, useNavigate } from 'react-router-dom';

const NavbarHeader = ({ onKnowMoreClick }) => {
    const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
    const [knowledgeDropdownOpen, setKnowledgeDropdownOpen] = useState(false);
    const servicesDropdownRef = useRef(null);
    const knowledgeDropdownRef = useRef(null);

    const handleServicesDropdownToggle = (e) => {
        e.stopPropagation();
        setServicesDropdownOpen(!servicesDropdownOpen);
        setKnowledgeDropdownOpen(false);
    };

    const handleKnowledgeDropdownToggle = (e) => {
        e.stopPropagation();
        setKnowledgeDropdownOpen(!knowledgeDropdownOpen);
        setServicesDropdownOpen(false);
    };

    const handleMouseEnterServices = () => {
        setServicesDropdownOpen(true);
        setKnowledgeDropdownOpen(false);
    };

    const handleMouseLeaveServices = () => {
        setServicesDropdownOpen(false);
    };

    const handleMouseEnterKnowledge = () => {
        setKnowledgeDropdownOpen(true);
        setServicesDropdownOpen(false);
    };

    const handleMouseLeaveKnowledge = () => {
        setKnowledgeDropdownOpen(false);
    };

    const handleClickOutside = (event) => {
        if (servicesDropdownRef.current && !servicesDropdownRef.current.contains(event.target)) {
            setServicesDropdownOpen(false);
        }
        if (knowledgeDropdownRef.current && !knowledgeDropdownRef.current.contains(event.target)) {
            setKnowledgeDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    
    const navigate = useNavigate();
    const handleApplyClick = (link) => {
        navigate(link);
    };

    return (
        <Navbar expand="lg" className="nav-head">
            <Container fluid className='nav-kart'>
                <Navbar.Brand as={Link} to="/">
                    <img src={Images.KartoffelLogo} alt="Kartoffel logo" className="nav-logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto navbar-nav">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                        <div 
                            ref={servicesDropdownRef} 
                            className='nav-item dropdown' 
                            onMouseEnter={handleMouseEnterServices}
                            onMouseLeave={handleMouseLeaveServices}
                        >
                            <button className="nav-link dropdown-toggle" onClick={onKnowMoreClick}>
                                Services
                                <img src={Images.dropdown} alt="Dropdown icon" className="dropdown-icon" />
                            </button>
                            {servicesDropdownOpen && (
                                <div className="dropdown-menu show">
                                    <Link className="dropdown-item" to="/product-engineering">Product Engineering</Link>
                                    <Link className="dropdown-item" to="/digital-transformation">Digital Transformation</Link>
                                    <Link className="dropdown-item" to="/product-development">Product Development</Link>
                                    <Link className="dropdown-item" to="/game-engineering">Game Engineering</Link>
                                    <Link className="dropdown-item" to="/interactive-engineering">Interactive Engineering </Link>
                                </div>
                            )}
                        </div>
                        <Nav.Link as={Link} to="/about">About</Nav.Link>
                        <div 
                            ref={knowledgeDropdownRef} 
                            className='nav-item dropdown' 
                            onMouseEnter={handleMouseEnterKnowledge}
                            onMouseLeave={handleMouseLeaveKnowledge}
                        >
                            <button className="nav-link dropdown-toggle" onClick={() => handleApplyClick("/knowledge-center")}>
                                Knowledge Center
                                <img src={Images.dropdown} alt="Dropdown icon" className="dropdown-icon" />
                            </button>
                            {knowledgeDropdownOpen && (
                                <div className="dropdown-menu show">
                                    <Link className="dropdown-item" to="/cusp-page">Cusp</Link>
                                    <Link className="dropdown-item" to="/blogs">Blogs</Link>
                                    <Link className="dropdown-item" to="/case-study">Case Study</Link>
                                    {/* <Link className="dropdown-item" to="/contact">Project Inquiry</Link> */}
                                </div>
                            )}
                        </div>
                        <Nav.Link as={Link} to="/careers">Careers</Nav.Link>
                    </Nav>
                    <Button variant="outline-light bn-coomon" onClick={() => handleApplyClick("/contact-us")} className="get-in-touch-btn">Get in Touch</Button>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarHeader;
