import Images from "../../Images/Images";

const ProductService = [
    {
        number: "01",
        title: "Tech Integration Across Functions",
        description: `Digital transformation integrates new technologies into all business functions, streamlining operations and enhancing productivity. This holistic approach ensures that technology supports and enhances every aspect of the business.`,
        image: Images.andIdeation
    },
    {
        number: "02",
        title: "Data-Driven Decisions",
        description: `By leveraging data analytics, businesses can make smarter, real-time decisions. This leads to more informed strategies and improved outcomes based on actionable insights.`,
        image: Images.ConceptCreation
    },
    {
        number: "03",
        title: "Enhanced Customer Personalisation",
        description: `Personalising customer interactions boosts satisfaction and loyalty. Tailoring experiences to individual preferences ensures a more engaging and relevant customer journey.`,
        image: Images.VisualanInteractioDesign
    },
    {
        number: "04",
        title: "Automated Processes",
        description: `Automation reduces errors and increases efficiency by streamlining repetitive tasks. This allows teams to focus on more strategic activities, driving overall productivity.`,
        image: Images.DevelopmenanImplementation
    },
    {
        number: "05",
        title: "Scalable and Flexible Solutions",
        description: `Implementing scalable, flexible digital solutions enables rapid adaptation to market changes. Businesses can adjust quickly to new trends and challenges, maintaining a competitive edge. `,
        image: Images.innphicconcept
    }
];
const bann = [
    {
        img: Images.designban,
        head: "DIGITAL TRANSFORMATION",
        // headone: "Design",
        serpara: "Partner with us for a complete digital transformation.",
    }
];
const service = [
    {
        title: "UI/UX Design",
        description: "Put your users at the heart of your software product with our user-centric design approach, crafting seamless and delightful experiences that drive customer satisfaction and loyalty.",
        image: Images.UseCentricDesign,
        url :"/ui-and-ux-design"
    },
    {
        title: "UI/UX Development",
        description: "Elevate your software product's user experience through our expert UX development and modernization services, creating intuitive and engaging interfaces that captivate your audience.",
        image: Images.UXDevelopmentanModernization,
        url :"/ui-and-ux-development"
    },
    // {
    //     title: "UI Development and Modernization",
    //     description: "Revisualize the digital canvas with our UI modernization techniques; crafting an interface that's both visually compelling and intuitively navigable, elevating user engagement and retention.",
    //     image: Images.UXlModernization
    // },
    // {
    //     title: "New Product Ideation",
    //     description: "Revolutionize Your Digital Presence with Tailor-Made UI Modernization Solutions.",
    //     image: Images.NewpProductIdeation
    // },
    // {
    //     title: "Solution Design",
    //     description: "Unlock the Potential of Your Business with Our Expert Design Services.",
    //     image: Images.SolutiDesign
    // },
];
const faqs = [
        {
            question: "What can you help me with?",
            answer: "We assist you in leveraging digital technologies to improve customer experiences and drive innovation. This includes digital strategy development, process optimization, and technology implementation."
        },
        {
            question: "How do I start a digital transformation project with you?",
            answer: "Contact us through our online form or email with your requirements. We'll meet to discuss your needs and outline a digital transformation strategy tailored to your organization."
        },
        {
            question: "How do you ensure a successful digital transformation?",
            answer: "We assess your current processes and technologies and develop a customized strategy. We then work closely with your team to implement changes, ensuring smooth transitions and minimal disruptions."
        },
        {
            question: "Can you provide ongoing support for digital transformation?",
            answer: "We also offer ongoing support to ensure the continuous improvement of your digital initiatives. We monitor progress, provide updates, and adjust to achieve your business objectives."
        },
    ];
    const listItems = [
        {
            alt: "Specialized Expertise",
            text: "Integrates new tech across all business functions.",
        },
        {
            alt: "Specialized Expertise",
            text: "Uses data analytics for smarter, real-time decisions.",
        },
        {
            alt: "Specialized Expertise",
            text: "Personalizes customer interactions, boosting satisfaction and loyalty",
        },
        {
            alt: "Specialized Expertise",
            text: "Automates processes, reducing errors and improving efficiency.",
        },
        {
            alt: "Specialized Expertise",
            text: "Enables rapid adaptation to market changes.",
        },
        {
            alt: "Specialized Expertise",
            text: "Enhances collaboration, productivity, and information access.",
        },
        {
            alt: "Specialized Expertise",
            text: "Implements scalable, flexible digital solutions.",
        },
        {
            alt: "Specialized Expertise",
            text: "Maintains a competitive edge by embracing tech trends.",
        },
    ];

export { ProductService,bann, service,faqs,listItems };
