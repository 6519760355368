import React from "react";
import { Helmet } from 'react-helmet';
function Seo({ meta_description, meta_title,meta_keyword }) {
    return (
        <div className="seo-section">
            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content={meta_description}
                />
                 <meta
                    name="Keyword"
                    content={meta_keyword}
                />
                <title>{meta_title}</title>
            </Helmet>
        </div>
    );
}

export default Seo;
