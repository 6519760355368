// src/components/BlogSection.jsx
import React, { useState } from 'react';
import { categories, blogPosts } from '../../commonComponents/BlogContent/BlogContent';
import "../../assests/css/Blog.css";
import Images from "../../Images/Images";
import { Link,useNavigate } from "react-router-dom";


const BlogSection = () => {
    const [selectedCategory, setSelectedCategory] = useState("Software");
    const [visiblePosts, setVisiblePosts] = useState(9);
    const [showAllPosts, setShowAllPosts] = useState(false);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setVisiblePosts(9);
        setShowAllPosts(false);
    };

    const handleShowMoreClick = () => {
        setShowAllPosts(!showAllPosts);
        setVisiblePosts(showAllPosts ? 9 : filteredPosts.length);
    };

    const filteredPosts = blogPosts.filter(post => post.category === selectedCategory);

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };
    
    const navigate = useNavigate();
    const handleApplyClick = (link) => {
        navigate(link);
    }; 

    return (
        <div className="container blog-section">
            <div className="row">
                {/* <div className="col-lg-4 col-md-4 col-sm-11 mb-4 blo">
                    <h4>Topics</h4>
                    <ul className="list-group blog-grp">
                        {categories.map((category, index) => (
                            <li
                                key={index}
                                className={`list-group-item blog-topic ${selectedCategory === category ? 'actives' : ''}`}
                                onClick={() => handleCategoryClick(category)}
                                style={{ cursor: 'pointer' }}
                            >
                                {category}
                            </li>
                        ))}
                    </ul>
                </div> */}
                               {filteredPosts.slice(0, visiblePosts).map((post, index) => (
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                        <div
                            className="card blogs-main"
                            onClick={() => handleApplyClick(post.link)}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            style={{
                                background: hoveredIndex === index
                                    ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.83), rgba(255, 255, 255, 0.83)), linear-gradient(95.61deg, #FF9233 -1.73%, #00AEEF 100%)'
                                    : 'transparent',
                            }}
                        >
                            <div className='blo-img'>
                                <img src={post.image} className="card-img-top" alt={post.title} />
                            </div>
                            <div className="card-body blogs-body d-flex flex-column">
                                <div className="flex-grow-1">
                                    <p className="card-title blogs-ca-tit" style={{
                                        color: hoveredIndex === index ? 'rgba(37, 41, 53, 1)' : 'rgba(255, 255, 255, 1)',
                                    }}>{post.title}</p>
                                    <p className="card-text blogs-ca-para" style={{
                                        color: hoveredIndex === index ? 'rgba(37, 41, 53, 1)' : 'rgba(255, 255, 255, 0.6)',
                                    }}>{post.description}</p>
                                </div>
                                <div className='explore-link-whole'>
                                    <Link className='blog-read'>Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}


                {/* {filteredPosts.length > 8 && (
                    <div className="blogs-bot">
                        <button className='fullTimseemores bn-blog bn-coomon' onClick={handleShowMoreClick}>
                            {showAllPosts ? 'Show Less' : 'Show More' } <img className="bt-learn" src={Images.learnmore} />
                        </button>
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default BlogSection;
