import React from 'react'
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import Footer from '../../commonComponents/footer/footer'
import Seo from "../../commonComponents/MetaTag/MetaTag";
// import CareerBan from '../../component/Career/CareerBanner';
import LandingBan from '../../component/Service/ServiceBanneer';
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient'
import SliderComponent from '../../component/Career/JobSlider';
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch';
import WeHiring from '../../component/Career/WeHiring';
import Images from '../../Images/Images';



const Career = () => {

  const bann = [
    {
        img: Images.careerban,
        head: "Careers",
    }
];
  const meta_description="Explore career opportunities at Kartoffel Technologies. We offer exciting roles in technology and innovation. Start your journey with us today!";
  const meta_title=" Join Our Team - Careers at Kartoffel Technologies";
  return (
    <div className='about'>
      <Seo meta_description={meta_description} meta_title={meta_title}/>
      <NavbarHeader />
      <LandingBan  bann={bann}/>
      {/* <OurTrustedPartners /> */}
      <SliderComponent/>
      <WeHiring/>
      <GetInTouch/>
      <Footer />
    </div>
  )
}

export default Career