import React from 'react';
import images from "../../Images/Images";
import "../../assests/css/WebServce.css";

const Features = () => {
    const features = [
        { icon: images.gifseven, title: 'Clean User ', titles: 'Interface', text: 'Stunning and intuitive designs.', },
        { icon: images.giftwo, title: 'User Friendly', text: 'Simple and easy to navigate websites.', design: "web-common" },
        { icon: images.gifthree, title: 'Fast Loading', text: 'Less than 5 seconds loading time.' },
        { icon: images.gifour, title: 'Mobile ', titles: 'Responsive', text: 'Optimized for all screen sizes.' },
        { icon: images.gifive, title: 'Cross Browser ', titles: 'Compatibility', text: 'Works seamlessly on all browsers.', design: "web-common" },
        { icon: images.gifsix, title: 'SEO Friendly', text: 'Optimized for search engines.' },
        { icon: images.gifone, title: 'Secure', text: 'Enhanced security features.', class: "web-spl"  },
        { icon: images.gifnine, title: 'Optimization', text: 'Continuous performance optimization.', design: "web-common", },
        { icon: images.gifeight, title: 'Testing', text: 'Rigorous testing for quality assurance.', class: "web-spl" },
    ];

    return (
        <div className="container web-feature">
            <div className="row">
                {features.map((feature, index) => (
                    <React.Fragment key={index}>
                        <div className={`col-lg-4 col-md-4 col-sm-12 web-col web-land-subhead`}>
                            <div className={`feature-item ${feature.design || ''}`}>
                                <div className='web-lan-img'>
                                    <div className="web-service-img">
                                        <img src={feature.icon} alt={feature.title} className="w-100" />
                                    </div>
                                    <p className={`web-ser-head ${feature.class || ''}`}>{feature.title}<br/>{feature.titles}</p>
                                </div>
                                <p className='web-land-p'>{feature.text}</p>
                            </div>
                        </div>
                        {(index + 1) % 3 === 0 && index !== features.length - 1 && (
                            <div className="col-12">
                                <p className="feature-row-divider"> </p>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default Features;
