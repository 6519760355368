import React, { useState, useEffect } from 'react';
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import Footer from '../../commonComponents/footer/footer'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import CuspFrame from '../../component/Cusp/CuspFrame'
import CuspTabsContent from '../../component/Cusp/CuspTabsContent'
import CuspSection from '../../component/KnowledgeCenter/CuspSection/CuspSection'
import CuspView from '../../component/Cusp/CuspView'
import ServiceBan from '../../component/Service/ServiceBanneer'
import Images from "../../Images/Images"
const CuspPage = () => {
    const [activeTab, setActiveTab] = useState('Consumerization');
    const bann = [
        {
          img: Images.cuspban,
          head: "CUSP",
        }
      ];
  return (
    <div className='know-ledge'>
         <NavbarHeader />
         <ServiceBan bann={bann} />
         
         <CuspFrame/>
         {/* <div className="scrolleble">
          <CuspTabsContent activeTab={activeTab} />
        </div> */}
         {/* <CuspTabsContent/> */}
         {/* <CuspSection /> */}
         <CuspView/>
         <GetInTouch />
      <Footer />
    </div>
  )
}

export default CuspPage