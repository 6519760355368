import React, { useState, useRef } from 'react';
import img from '../../Images/Images';
import '../../assests/css/CareerOpening.css';


const CareerModal = (props) => {
    const { career, isModalOpen, closeModal } = props;

    // Form state variables
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [resume, setResume] = useState(null);
    const [experience, setExperience] = useState('');

    // Validation state variables
    const [nameValid, setNameValid] = useState(true);
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [resumeValid, setResumeValid] = useState(true);
    const [experienceValid, setExperienceValid] = useState(true);
    const [formValid, setFormValid] = useState(false);

    // Validation error messages
    const [nameError, setNameError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [resumeError, setResumeError] = useState('');
    const [experienceError, setExperienceError] = useState('');

    const handleNameChange = (e) => {
        const value = e.target.value;
        setName(value);
        const isValid = value.trim() !== '';
        setNameValid(isValid);
        setNameError(isValid ? '' : 'Name is required');
        updateFormValidity();
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
        const isValid = /^\d{10}$/.test(value);
        setPhoneNumberValid(isValid);
        setPhoneNumberError(isValid ? '' : 'Invalid phone number format');
        updateFormValidity();
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        setEmailValid(isValid);
        setEmailError(isValid ? '' : 'Invalid email format');
        updateFormValidity();
    };

    const handleResumeChange = (e) => {
        const file = e.target.files[0];
        setResume(file);
        const isValid = file !== null;
        setResumeValid(isValid);
        setResumeError(isValid ? '' : 'Please upload a resume (PDF only)');
        updateFormValidity();
    };

    const handleExperienceChange = (e) => {
        const value = e.target.value;
        setExperience(value);
        const isValid = /^\d{1,2}(\.\d{1})?$/.test(value);
        setExperienceValid(isValid);
        setExperienceError(isValid ? '' : 'Experience must be a number up to two digits');
        updateFormValidity();
    };

    const updateFormValidity = () => {
        const isValid = nameValid && phoneNumberValid && emailValid && resumeValid && experienceValid;
        setFormValid(isValid);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Trigger validation for all fields when the form is submitted
        const isNameValid = name.trim() !== '';
        setNameValid(isNameValid);
        setNameError(isNameValid ? '' : 'Name is required');

        const isPhoneNumberValid = /^\d{10}$/.test(phoneNumber);
        setPhoneNumberValid(isPhoneNumberValid);
        setPhoneNumberError(isPhoneNumberValid ? '' : 'PhoneNumber is required');

        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        setEmailValid(isEmailValid);
        setEmailError(isEmailValid ? '' : 'Email is required');

        const isResumeValid = resume !== null;
        setResumeValid(isResumeValid);
        setResumeError(isResumeValid ? '' : 'Resume is Required');

        const isExperienceValid = /^\d{1,2}(\.\d{1,2})?$/.test(experience);
        setExperienceValid(isExperienceValid);
        setExperienceError(isExperienceValid ? '' : 'Experience is Required');

        // Update overall form validity
        const isValid = isNameValid && isPhoneNumberValid && isEmailValid && isResumeValid && isExperienceValid;
        setFormValid(isValid);

        if (isValid) {
            const emailContent = `
                <p><strong>Name:</strong> ${name}</p>
                <p><strong>Email:</strong> ${email}</p>
                <p><strong>Phone Number:</strong> ${phoneNumber}</p>
                <p><strong>Experience:</strong> ${experience} years</p>
                <p><strong>Resume:</strong> ${resume ? resume.name : 'No resume attached'}</p>
            `;

            const payload = {
                to: 'info@kartoffel.in',
                subject: `Job Application: ${career.title}`,
                body: emailContent,
            };

            const formData = new FormData();
            formData.append('to', payload.to);
            formData.append('subject', payload.subject);
            formData.append('body', payload.body);

            if (resume) {
                formData.append('upload_file', resume); 
            }

            try {
                const response = await fetch('https://kartoffel.in/api/send-mail', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    alert('Email sent successfully');
                    closeModal();
                    setName('');
                    setPhoneNumber('');
                    setEmail('');
                    setResume(null);
                    setExperience('');
                } else {
                    console.error('Failed to send email');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            console.log('Form is not valid. Please check your input.');
        }
    };

    const removeResume = () => {
        setResume(null);
        updateFormValidity();
    };

    const jobDetails = {
        101: [
            'Lead Generation: Identify, prospect, and qualify potential clients in the targeted regions. Develop strategies to create new business opportunities.',
            'Relationship Building: Establish and maintain strong relationships with key stakeholders in client organizations. Expand your network to uncover new business prospects.',
            'Sales Initiatives: Design and implement sales and marketing programs tailored to the needs of target accounts. Present our services effectively to meet client requirements.',
            'Account Management: Oversee new sales accounts, ensuring growth through upselling and cross-selling additional services. Maintain strong client relationships post-sale.',
            'Sales Targets: Meet or exceed quarterly and annual sales goals. Continuously track performance and refine strategies to achieve targets.',
            'Collaboration: Work in close coordination with our offshore delivery teams to develop proposals and respond to client needs.',
            'Ensure a thorough understanding of client requirements.',
            'Travel: Occasional travel may be required for client meetings or internal training.'
        ],
        102: [
            'Analyze, document, and propose business solutions with clear functional specifications.',
            'Gather and translate business requirements into actionable project plans.',
            'Document and optimise business processes for improved outcomes.',
            'Act as the bridge between clients, development teams, and third parties throughout the project lifecycle.',
            'Prepare detailed Software Requirement Specifications (SRS) for client sign-off.',
            'Create MIS reports and dashboards for clients and internal stakeholders.',
            'Communicate and collaborate effectively with stakeholders to ensure project success.',
            'Maintain a client-facing role until project sign-off, coordinating with internal teams to ensure timely delivery.'
        ],
        103: [
            'Responsible for maintaining and developing the WordPress sites',
            'Should be able to create pages from scratch, manage news, and use common plugins.',
            'Creating functions, plugins from scratch, it should have knowledge in custom themes. And be able to change custom content.',
            'Should use JS functionalities, for each, map, filter, reduce, create and maintain custom functionality, and be able to add documentation.',
            'Maintain basic functionality using PHP, but more importantly should know how to use it with WordPress.',
            'Should create different hooks that WordPress provides.',
            'Create and maintain the different styles of the page, using the structure that the company gives.',
            'Should have knowledge on how to use branching and pull requests.'
        ],
        104: [
            'Responsible for maintaining and developing the WordPress sites',
            'Should be able to create pages from scratch, manage news, and use common plugins.',
            'Creating functions, plugins from scratch, it should have knowledge in custom themes. And be able to change custom content.',
            'Should use JS functionalities, for each, map, filter, reduce, create and maintain custom functionality, and be able to add documentation.',
            'Maintain basic functionality using PHP, but more importantly should know how to use it with WordPress.',
            'Should create different hooks that WordPress provides.',
            'Create and maintain the different styles of the page, using the structure that the company gives.',
            'Should have knowledge on how to use branching and pull requests.'
        ],
        105: [
            'Responsible for maintaining and developing the WordPress sites',
            'Should be able to create pages from scratch, manage news, and use common plugins.',
            'Creating functions, plugins from scratch, it should have knowledge in custom themes. And be able to change custom content.',
            'Should use JS functionalities, for each, map, filter, reduce, create and maintain custom functionality, and be able to add documentation.',
            'Maintain basic functionality using PHP, but more importantly should know how to use it with WordPress.',
            'Should create different hooks that WordPress provides.',
            'Create and maintain the different styles of the page, using the structure that the company gives.',
            'Should have knowledge on how to use branching and pull requests.'
        ],
        // Add more job details for other IDs
    };


    const responsibilityDetails = {

        101: [
            'Experience: 2 years - Previous experience in sales, particularly in IT services or related fields.',
            'Networking: Proven ability to develop and sustain professional relationships.',
            'Technology Insight: Familiarity with emerging technologies and trends in software development and service solutions.',
            'Outsourcing Models: Understanding of various outsourcing models and service delivery processes.Personal Traits:',
            'Results-Oriented: Committed to achieving sales goals and driving the business forward.',
            'Networking Prowess: Strong ability to build and leverage professional networks.',
            'Energetic Visionary: Enthusiastic, motivated, and forward-thinking.',
            'Skilled Negotiator: Excellent negotiation skills, with a knack for closing deals.',
            'Adaptable: Able to thrive in a fast-paced, ever-changing environment.',
            'Professional Presence: Confident, mature, and able to engage effectively with clients.'
        ],
        102: [
            'Experience a supportive and collaborative work environment that values your input.',
            'Be part of a team where open communication and teamwork are the norm.',
            'Access continuous learning opportunities and mentorship from experienced colleagues.',
            'Work in a culture that recognises and rewards your contributions to our success.',
            
        ],
        103: [
            'Experience with the overall functionality of the Angular framework',
            'Experience in customization of existing libraries.',
            'Expertise in HTML5, CSS3, TypeScript, JavaScript.',
            'Experience with RxJS, NgRx, and other state management libraries.',
            'Strong knowledge in Angular Custom component development and Module development.',
            'Strong experience in Angular service integration.'
        ],
        104: [
            'Experience with the overall functionality of the Angular framework',
            'Experience in customization of existing libraries.',
            'Expertise in HTML5, CSS3, TypeScript, JavaScript.',
            'Experience with RxJS, NgRx, and other state management libraries.',
            'Strong knowledge in Angular Custom component development and Module development.',
            'Strong experience in Angular service integration.'
        ],
        105: [
            'Experience with the overall functionality of the Angular framework',
            'Experience in customization of existing libraries.',
            'Expertise in HTML5, CSS3, TypeScript, JavaScript.',
            'Experience with RxJS, NgRx, and other state management libraries.',
            'Strong knowledge in Angular Custom component development and Module development.',
            'Strong experience in Angular service integration.'
        ],
        // Add more responsibility details for other IDs
    };
    const descripition={
        101:["Key Responsibilities"],
        102:["Responsibilities:"],
    };
    const resp={
        101:["Qualifications:"],
        102:["Why Join Us?"],
    }


    const fileInputRef = useRef(null);
    const openFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <div>
            <div
                className={`modal fade ${isModalOpen ? 'show d-block' : ''}`}
                tabIndex="-1"
                role="dialog"
                style={{ display: isModalOpen ? 'block' : 'none' }}
            >
                <div className="modal-dialog modal-lg">
                    <div className="modal-content workhiremod">
                        <div className="headerContModldetails">
                            <div className="imgandDetails">
                                <div className="">
                                    <img className="profilWorl" src={career.image} alt="" />
                                </div>
                                <div className="updateContentopen">
                                    <p className="contentWrte">
                                        {career.title}{' '}
                                        <span>
                                            <button className="fullTime">{career.fulltime}</button>
                                        </span>
                                    </p>
                                    <div className='pop-second'>
                                        <div className='first-half' style={{ "textAlign": 'left' }}>
                                            <span className='spanREm dark-col' style={{ paddingLeft: "0px" }}>{career.solar}<span className='color-code'><button className='fullTime' style={{ "padding": "0px", "marginLeft": "2px" }}>{career.jobsAvailable}</button></span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pop-close' onClick={closeModal}>
                                <div className="close" >
                                    <span><i className="fa-solid fa-x"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div>
                                <button className="jobDescp"> {descripition[career.id] ? descripition[career.id][0] : 'Description'}</button>
                                <ul className='application'>
                                    {jobDetails[career.id]?.map((item, index) => (
                                        <li key={index} className='jonbdescriptList'>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <button className="jobDescp">{resp[career.id] ? descripition[career.id][0] : 'Description'}</button>
                                <div className='Responsibilitym'>
                                    <ul className='application'>
                                        {responsibilityDetails[career.id]?.map((item, index) => (
                                            <li key={index} className='responsiblityDestList'>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div className='formsCraeres'>
                                <div className='jobsdeDiv'>
                                    <h3 className='appliJobn'>Job Application</h3>
                                </div>
                                <form className='comonFormClas' onSubmit={handleSubmit}>
                                    <div className='form-group commonforms'>
                                        <label>Name</label>
                                        <input
                                            type='text'
                                            value={name}
                                            onChange={handleNameChange}
                                            className={`form-control namef ${nameValid ? '' : 'is-invalid'}`}
                                        />
                                        {!nameValid && <div className="invalid-feedback">{nameError}</div>}
                                    </div>
                                    <div className='form-group commonforms'>
                                        <label>Email</label>
                                        <input
                                            type='email'
                                            value={email}
                                            onChange={handleEmailChange}
                                            className={`form-control emailf ${emailValid ? '' : 'is-invalid'}`}
                                        />
                                        {!emailValid && <div className="invalid-feedback">{emailError}</div>}
                                    </div>
                                    <div className='form-group commonforms'>
                                        <label>Phone Number</label>
                                        <input
                                            type='tel'
                                            value={phoneNumber}
                                            onChange={handlePhoneNumberChange}
                                            className={`form-control numberf ${phoneNumberValid ? '' : 'is-invalid'}`}
                                        />
                                        {!phoneNumberValid && <div className="invalid-feedback">{phoneNumberError}</div>}
                                    </div>
                                    <div className='form-group commonforms'>
                                        <label>Experience</label>
                                        <input
                                            type='number'
                                            value={experience}
                                            onChange={handleExperienceChange}
                                            className={`form-control experiencef ${experienceValid ? '' : 'is-invalid'}`}
                                        />
                                        {!experienceValid && <div className="invalid-feedback">{experienceError}</div>}
                                    </div>
                                    <div className='form-group commonforms new'>
                                        {resume ? (
                                            <div className="uploaded-file">
                                                <img src={img.file} alt='Uploaded File' />
                                                <span>{resume.name}</span>
                                                <button type="button" className="remove-file" onClick={removeResume}>
                                                    <i className="fa-solid fa-xmark"></i>
                                                </button>
                                            </div>
                                        ) : (
                                            <>
                                                <img
                                                    src={img.upload}
                                                    alt=''
                                                    onClick={openFileInput}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <input
                                                    type='file'
                                                    accept='.pdf'
                                                    ref={fileInputRef}
                                                    onChange={handleResumeChange}
                                                    className={`form-control-file filef ${resumeValid ? '' : 'is-invalid'}`}
                                                    style={{ display: 'none' }}
                                                />
                                                {!resumeValid && <div className='invalid-feedback'>{resumeError}</div>}
                                                <label>Upload Resume</label>
                                                <p>Only .pdf</p>
                                            </>
                                        )}
                                    </div>
                                    <p className={`errormesg ${formValid ? 'hidden' : ''}`}></p>
                                </form>
                            </div>
                        </div>
                        <div className="modal-foote workhirefort">
                            <button type='submit' className='btn submtnnn' onClick={handleSubmit}>
                                SUBMIT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && <div className="modal-backdrop fade show"></div>}
        </div>
    );
};

export default CareerModal;
