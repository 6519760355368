import React from 'react'
import NavbarHeader from "../../commonComponents/header/NavbarHeader"
import Footer from "../../commonComponents/footer/footer"
import ServiceCards from '../../component/Service/ServiceCard'
import ProductionIdea from "../../component/Service/ProductionIdea"
import DevelopmentTools from "../../component/Service/DevelopmentTools"
import OutsourcedProducts from "../../component/Service/OutSourced"
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import ServiceBan from '../../component/Service/ServiceBanneer'
import FAQ from '../../component/Service/Faq'
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient'
import Seo from "../../commonComponents/MetaTag/MetaTag";
import { ProductService, bann, service,faqs,listItems } from "../../commonComponents/ServicesData/NewProduct"
import Statistics from '../../commonComponents/ProjectCount/ProjectCount'

const NewProduct = () => {
  const meta_description="From concept to launch, trust us for innovative new product development services.";
  const meta_title="Innovate Confidently - New Product Development | Kartoffel Technologies";
   const meta_keyword = "new product development service, new product development services, product development service, product/service development, R&D product development, new product development service, new development product, new product development services, product development service, product service development."
  const dynamicTitle = {
    one: "Shaping the future with adaptable ",
    two: "products from idea development to",
    three: "market-ready solutions."
  };
  const out_title="What makes Product Engineering essential for your business growth?"
  return (
    <div className='Kart-service'>
       <Seo meta_description={meta_description} meta_title={meta_title} meta_keyword={meta_keyword}/>
      <NavbarHeader />
      <ServiceBan bann={bann} />
      <ServiceCards service={service}  title={dynamicTitle}/>
      <ProductionIdea sliderData={ProductService} />
      {/* <OutsourcedProducts listItems={listItems} out_title={out_title}/> */}
      <Statistics/>
      {/* <OurTrustedPartners /> */}
      <DevelopmentTools />
      <FAQ faqs={faqs}/>
      <GetInTouch />
      <Footer />
    </div>
  )
}

export default NewProduct