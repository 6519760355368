import React, { useState } from 'react';
import '../../assests/css/OurBlog.css';
import { Button } from 'react-bootstrap';
import Images from "../../Images/Images";
import { Link, useNavigate } from "react-router-dom";

const blogData = [
    { date: 'May 22, 2024', content: 'The chaos of tackling an undocumented code.', para: "Undocumented code can be a nightmare for developers.", link: '/tackling' },
    { date: 'May 8, 2024', content: 'Going Native v/s Going Hybrid.', para: 'Crafting a mobile app requires a crucial decision: hybrid or native development?', link: '/native ' },
    { date: 'April 18, 2024', content: `Administrators' Multifaceted Roles`, para: `there's a secret weapon that keeps the whole thing running smoothly: Admins.`, link: '/administrators ' },
    { date: 'April 10, 2024', content: 'Mastering the Metaverse', para: 'I cannot say this enough but virtual reality and the metaverse will always need a skilled tester,', link: '/testing ' },
    { date: 'Feb 22, 2024', content: `Tailoring Software Architecture To Your Start-Up's Needs.`, para: 'In software development, choosing the right architecture is crucial for the success of your project.', link: '/architecture ' },
    { date: 'Feb 14, 2024', content: 'Corporate Recruitment and Attracting Top Talent.', para: 'As an HR Executive deeply involved in recruitment and sourcing', link: '/recruitment ' },
    // { date: 'Feb 5, 2024', content: 'SNAP IT, STITCH IT, SCULPT IT.', para: 'Photogrammetry is a technique with which we can generate a proprietary 3D file format called FilmBox (FBX) with textures using 2D images.', link: '/Photogrammetry' },
    // { date: 'Jan 31, 2024', content: 'Employee Off boarding', para: 'Every company meticulously crafts an onboarding process, warmly welcoming each new team member who walks through their doors', link: '/offboarding' },
    // { date: 'Jan 24, 2024', content: 'The Core Identity Of Your Game.', para: ' the game industry has been around long enough to encapsulate the best bits from all of them.', link: '/game' },
];

const OurBlogs = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };
    const navigate = useNavigate();
    const handleApplyClick = (link) => {
        navigate(link);
    };

    return (
        <div className='our-blogs'>
            <div className='container'>
                <p className='text-center head'>OUR BLOGS</p>
                <div className='row blog-rows'>
                    {blogData.map((blog, index) => (
                        <div
                            key={index}
                            className='col-lg-4 col-md-6 col-sm-12 mb-4'
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div
                                className="blog-card d-flex flex-column"
                                style={{
                                    background: hoveredIndex === index
                                        ? 'rgba(0, 0, 0, 0.22)'
                                        : 'linear-gradient(0deg, rgba(255, 255, 255, 0.83), rgba(255, 255, 255, 0.83)), linear-gradient(95.61deg, #FF9233 -1.73%, #00AEEF 100%)',
                                }}
                            >
                                <div>
                                    <p
                                        className='date'
                                        style={{
                                            color: hoveredIndex === index ? 'rgba(255, 255, 255, 0.6)' : 'rgba(37, 41, 53, 0.6)',
                                        }}
                                    >
                                        {blog.date}
                                    </p>
                                    <p
                                        className='content'
                                        style={{
                                            color: hoveredIndex === index ? 'rgba(255, 255, 255, 1)' : 'rgba(37, 41, 53, 1)',
                                        }}
                                    >
                                        {blog.content}
                                    </p>
                                    <p
                                        className='content-para'
                                        style={{
                                            color: hoveredIndex === index ? 'rgba(255, 255, 255, 1)' : 'rgba(37, 41, 53, 1)',
                                        }}
                                    >
                                        {blog.para}
                                    </p>
                                </div>
                                <div className='explore-link-whole'>
                                    <Link to={blog.link} className='explore-link'>Read More</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className='text-center'>
                    <Button className="bn-visit bn-coomon" onClick={() => handleApplyClick("/blogs")}>Visit Blogs Pages <img className="bt-learn" src={Images.learnmore} alt="Learn more" /></Button>
                </div> */}
                <div className='hover-image-container'>
                    <img className='hover-image  blo-unique' src={Images.button} alt="Know More" />
                    <img className='button-image blo-unique' src={Images.buttonclick} alt="Know More Hover" />
                    <div className='hover-text' onClick={() => handleApplyClick("/blogs")}>
                        <p className='home-know'>Visit Blogs Pages</p>
                        <span className='arrow-icon'>
                            <img src={Images.rightarrow} alt="Right Arrow" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurBlogs;
