// src/components/Service/sliderData.js
import Images from "../../Images/Images";

const ProductService = [
    {
        number: "01",
        title: "Comprehensive Development Management",
        description: `We manage the entire product lifecycle, from ideation to post-launch, ensuring a streamlined and efficient process. Our approach keeps projects aligned with timelines and quality standards, reducing time to market.`,
        image: Images.productcardone
    },
    {
        number: "02",
        title: "Feature Innovation Across Platforms",
        description: `We drive the integration of cutting-edge features across mobile and web platforms, enhancing user experience and engagement. Our focus on innovation ensures that our products stand out in a competitive market.`,
        image: Images.second
    },
    {
        number: "03",
        title: "High Standards and Compliance",
        description: `By enforcing rigorous testing and adhering to industry standards, we ensure our products meet the highest quality benchmarks. This commitment to excellence minimises errors and boosts product reliability.`,
        image: Images.productcardthree
    },
    {
        number: "04",
        title: "Collaborative Teamwork for Enhanced Products",
        description: `We enhance products through collaborative efforts, bringing together expertise from various disciplines. This cross-functional teamwork results in well-rounded solutions that meet diverse user needs.`,
        image: Images.productcardfour
    },
    {
        number: "05",
        title: "Scalability and Customisation",
        description: `Our products are designed to be scalable and customisable, allowing businesses to grow and adapt seamlessly. We continuously refine and update our offerings to keep them relevant and competitive in dynamic markets.`,
        image: Images.productcardfive
    }
];
const bann = [
    {
        img: Images.serBan,
        head: "PRODUCT ENGINEERING",
        // headone: "MANAGEMENT",
        serpara: "Leveraging our expertise in transforming your ideas to marketable digital products.",
    }
];
const service = [
    {
        title: "Mobile App Development",
        description: "Transform your ideas into groundbreaking mobile applications with our agile development process, ensuring rapid iterations and swift delivery.",
        image: Images.digitalproduct,
        url: "/mobile-app-development"
    },
    {
        title: "Web App Development ",
        description: "Bring your web applications to life with our streamlined development process, delivering robust and scalable solutions swiftly. ",
        image: Images.service,
        url: "/web-app-development"
    },
    {
        title: "Modernization Services​",
        description: "Revitalize your legacy systems with our modernization services, enhancing performance and extending the lifespan of your applications.",
        image: Images.viable,
        url: "/modernization-services"
    },
    {
        title: "Maintenance and Support",
        description: "Ensure your product's longevity and optimal performance with our dedicated maintenance and support services.",
        image: Images.consulting,
        url: "/maintenanceandsupport"
    },
];
const faqs = [
    {
        question: "What can you help me with?",
        answer: "Our Comprehensive Approach to Product Development."
    },
    {
        question: "How do I create a product with you?",
        answer: "Contact us with your idea via our online form, email, or phone. We'll set up a meeting to discuss your vision in detail. Providing as much information as possible will help streamline the process. If you need help getting started, our blog has resources on how to write a project brief."
    },
    {
        question: "What technologies do you use for product development?",
        answer: "We are adaptable to your project requirements. We use PHP, Express.js, and Mongo DB for back-end development and React and Node.js for front-end development. Based on your project requirements, we select the best technology stack to ensure your project's success."
    },
    {
        question: "How do you ensure the quality of your products?",
        answer: "Our commitment to quality is unwavering. We conduct rigorous testing, including functional, performance, and security tests. We also gather user feedback during development to make necessary adjustments and improvements, ensuring a high-quality product."
    },
];
const listItems = [
    {
        alt: "Specialized Expertise",
        text: "Oversee concept to market, ensuring smooth progression.",
    },
    {
        alt: "Specialized Expertise",
        text: "Use design thinking for unique, standout products.",
    },
    {
        alt: "Specialized Expertise",
        text: "Respond to trends and demands to stay relevant.",
    },
    {
        alt: "Specialized Expertise",
        text: "Enhance quality with cross-disciplinary teamwork.",
    },
    {
        alt: "Specialized Expertise",
        text: "Balance quality with budget for effective products.",
    },
    {
        alt: "Specialized Expertise",
        text: "Prototype and test early to avoid issues.",
    },
    {
        alt: "Specialized Expertise",
        text: "Prioritize feedback to meet user needs.",
    },
];

export { ProductService, bann, service, faqs ,listItems};
