// src/components/Service/sliderData.js
import Images from "../../Images/Images";

const ProductService = [
    {
        number: "01",
        title: "Immersive Storytelling with Technical Expertise",
        description: `We blend technical prowess with compelling storytelling to create truly immersive gaming experiences. Our approach ensures that games captivate players and keep them engaged from start to finish.`,
        image: Images.gameengone
    },
    {
        number: "02",
        title: "Advanced Graphics and Animation",
        description: `Our team pushes the boundaries of graphics and animation, delivering visually stunning games that set new industry standards. This attention to detail enhances the overall player experience, making each game memorable.`,
        image: Images.gameengtwo
    },
    {
        number: "03",
        title: "Robust Engineering for Seamless Gameplay",
        description: `We ensure games run smoothly and without disruptions through robust engineering and meticulous performance optimisation. Our focus on responsiveness guarantees a fluid gaming experience across all devices.`,
        image: Images.gameengthree
    },
    {
        number: "04",
        title: "Broad Accessibility Across Platforms",
        description: `Our games are optimised for wide accessibility, making them enjoyable on various platforms and devices. We cater to different audiences, ensuring that every player can access and appreciate our games.`,
        image: Images.gameengfour
    },
    {
        number: "05",
        title: "Community-Centric Approach",
        description: `We prioritise community interaction and provide ongoing support to keep players engaged and satisfied. By listening to player feedback and incorporating it into updates, we create games that evolve with their audience.`,
        image: Images.gameengfive
    }
];
const bann = [
    {
        img: Images.gameban,
        head: "GAME ENGINEERING ",
        // headone: "Design",
        serpara: "Join forces with our team of gamers to bring life into your gaming ideas.",
    }
];
const service = [
    {
        title: "Game Porting",
        description: "By partnering with Kartoffel Technologies, you can significantly expand your game's reach and ensure a seamless gaming experience across multiple platforms.",
        image: Images.NeTecMigration,
        url :"/game-porting"
    },
    {
        title: "Co-Development",
        description: "Kartoffel Technologies offers co-development services to ensure your game development process is efficient, collaborative, and successful.",
        image: Images.MigratIntegration,
        url :"/co-development"
    },
    {
        title: "Unity Game Development",
        description: "Our team of expert Unity developers creates high-quality 2D, 3D games for various platforms, including Android, iOS, and PC. We specialize in developing diverse game types, including simulations, web apps, and AR/VR experiences.",
        image: Images.UXmok,
        url :"/unity-game-development"
    },
    {
        title: "Unreal Game Development",
        description: "We offer full-cycle game development services, managing everything from the concept stage to release.",
        image: Images.consulting_auditing,
        url :"/unreal-game-development"
    },
    {
        title: "Game Testing",
        description: "Our team of experienced testers use various methodologies and tools to thoroughly test your game, ensuring it is free of bugs and glitches and meets industry standards. Gain the expertise and scalability needed for a successful game release through us.",
        image: Images.games,
        url :"/game-testing"
    },
];
const faqs = [
    {
        question: "What can you help me with?",
        answer: "We design and develop interactive and engaging games, covering game mechanics, graphics, sound, and user interfaces. Our services span from initial concept through development and deployment."
    },
    {
        question: "What platforms do you develop games for?",
        answer: "We develop games for multiple platforms, including PCs, consoles, mobile devices, and web browsers. We ensure that your game is optimized for the platform of your choice."
    },
    {
        question: "How do you ensure an engaging gaming experience?",
        answer: "We focus on creating compelling game mechanics, immersive graphics, and intuitive user interfaces. We conduct extensive playtesting to gather feedback and make improvements."
    },
    {
        question: "Can you help with game monetization?",
        answer: "We can integrate monetization strategies, such as in-app purchases, ads, and subscription models, to help you generate revenue from your game."
    },
];

const listItems = [
    {
        alt: "Specialized Expertise",
        text: "Blend technical expertise with storytelling for immersive experiences.",
    },
    {
        alt: "Specialized Expertise",
        text: "Push limits with cutting-edge graphics and animation.",
    },
    {
        alt: "Specialized Expertise",
        text: "Ensure responsive, disruption-free gaming through robust engineering.",
    },
    {
        alt: "Specialized Expertise",
        text: "Optimize games for broad accessibility across platforms.",
    },
    {
        alt: "Specialized Expertise",
        text: "Focus on engaging experiences tailored to player needs.",
    },
    {
        alt: "Specialized Expertise",
        text: "Deliver top performance on resource-limited devices.",
    },
    {
        alt: "Specialized Expertise",
        text: "Introduce unique mechanics to stand out in the market.",
    },
    {
        alt: "Specialized Expertise",
        text: "Prioritize community interaction and provide ongoing support.",
    },
];

export { ProductService,bann, service ,faqs,listItems};
