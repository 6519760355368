import React, { useEffect } from 'react'
import NavbarHeader from "../../commonComponents/header/NavbarHeader"
import Footer from "../../commonComponents/footer/footer"
import ServiceCards from '../../component/Service/ServiceCard'
import ProductionIdea from "../../component/Service/ProductionIdea"
import DevelopmentTools from "../../component/Service/DevelopmentTools"
import OutsourcedProducts from "../../component/Service/OutSourced"
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import ServiceBan from '../../component/Service/ServiceBanneer'
import FAQ from '../../component/Service/Faq'
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient'
import Seo from "../../commonComponents/MetaTag/MetaTag";
import { ProductService, bann, service,faqs,listItems } from "../../commonComponents/ServicesData/Modernization"
import Statistics from '../../commonComponents/ProjectCount/ProjectCount'
import VideoCarousel from '../../component/Service/GameGallery'
import Videoslide from '../../component/Service/GameGallery'
import SliderComponent from '../../component/Service/GameGallery'

const Modernization = () => {
  const meta_description=" Upgrade and modernize your systems with our comprehensive modernization services.";
  const meta_title="Upgrade Your Systems - Modernization Services | Kartoffel Technologies";
   const meta_keyword = "game development 3D, game development Berlin, C game dev, C game development, is game development a good career, game development Unreal Engine 5, Unity development company, VR game development Unity, QA game development, 3D game development, Unity game development services."
  const dynamicTitle = {
    one: "With interactive entertainment evolving,",
    two: "we design and engineer immersive,",
    three: "innovative games built for success."
  };
  const out_title="Why should you partner with us for Game Development?"
  return (
    <div className='Kart-service'>
       <Seo meta_description={meta_description} meta_title={meta_title}  meta_keyword={meta_keyword}/>
      <NavbarHeader />
      <ServiceBan bann={bann} />
      <ServiceCards service={service}  title={dynamicTitle}/>
      <SliderComponent/>
      <ProductionIdea sliderData={ProductService} />
      {/* <OutsourcedProducts  listItems={listItems} out_title={out_title} /> */}
      <Statistics/>
      {/* <OurTrustedPartners /> */}
      <DevelopmentTools />
      <FAQ faqs={faqs}/>
      <GetInTouch />
      <Footer />
    </div>
  )
}

export default Modernization