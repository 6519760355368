import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import necessary hooks from react-router-dom
import Images from '../../Images/Images';
import '../../assests/css/OurBlog.css';

const CuspView = () => {
    const blogData = [
        { 
          date: 'May 22, 2024', 
          content: 'Applying CUSP in FemTech - A Leading Healthcare Product Development Company', 
          para: "Kartoffel Technologies applied the CUSP framework to a leading FemTech healthcare product development company, enhancing its market fit and operational efficiency", 
          link: '', 
          image: Images.pati // Add image path for each blog
        },
        { 
          date: 'May 8, 2024', 
          content: 'Applying CUSP in Supply Chain Solutions - A Leading Supply Chain Solutions Provider', 
          para: 'Kartoffel Technologies applied the CUSP framework to transform a leading supply chain solutions provider through the development of a digital platform', 
          link: '', 
          image: Images.wols 
        },
       
      ];

  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleApplyClick = (link) => {
    navigate(link);
  };

  return (
    <div className='our-blogs' style={{padding:"5%"}}>
      <div className='container'>
        {/* <p className='text-center head'style={{fontSize:"40px"}}>CUSP</p> */}
        <div className='row blog-rows' style={{alignItems:"center"}}>
          {blogData.map((blog, index) => (
            <div
              key={index}
              className='col-lg-6 col-md-12 col-sm-12 mb-4'
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              style={{justifyContent:"center",display:"flex"}}
            >
              <div
                className="blog-card d-flex flex-column cuspsp"
                style={{
                  background: hoveredIndex === index
                  ? 'linear-gradient(0deg, rgba(255, 255, 255, 0.83), rgba(255, 255, 255, 0.83)), linear-gradient(95.61deg, #FF9233 -1.73%, #00AEEF 100%)'
                  : 'transparent',
                   width:"80%"
                }}
              >
                <div>
                <div className='blo-img'>

                                <img src={blog.image} className="card-img-top" alt={blog.title} />
                            </div>
                  <p
                    className='content cuspsp'
                    style={{
                      color: hoveredIndex === index ? 'rgba(37, 41, 53, 1)' : 'rgba(255, 255, 255, 1)',
                       width:"80%"
                    }}
                  >
                    {blog.content}
                  </p>
                  <p
                    className='content-para viwsk cuspsp'
                    style={{
                        color: hoveredIndex === index ? 'rgba(37, 41, 53, 1)' : 'rgba(255, 255, 255, 0.6)',
                        width:"80%"
                    }}
                  >
                    {blog.para}
                  </p>
                </div>
                <div className='explore-link-whole'>
                  <Link to={blog.link} className='explore-link'>Read More</Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className='hover-image-container'>
          <img className='hover-image blo-unique' src={Images.button} alt="Know More" />
          <img className='button-image blo-unique' src={Images.buttonclick} alt="Know More Hover" />
          <div className='hover-text' onClick={() => handleApplyClick("/blogs")}>
            <p className='home-know'>Visit Cusp </p>
            <span className='arrow-icon'>
              <img src={Images.rightarrow} alt="Right Arrow" />
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default CuspView