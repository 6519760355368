import React, { useState, useEffect } from 'react';
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import Footer from '../../commonComponents/footer/footer'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import ServiceBan from '../../component/Service/ServiceBanneer'
import Images from "../../Images/Images"
import CaseThap from '../../component/CaseStudySub/CaseThap';
const CaseStudySubPage = () => {
    const [activeTab, setActiveTab] = useState('Consumerization');
    const bann = [
        {
          img: Images.cuspban,
          head: "Thap",
        }
      ];
  return (
    <div>
         <NavbarHeader />
         <ServiceBan bann={bann} />
         <CaseThap/>
         <GetInTouch />
         <Footer />
    </div>
  )
}

export default CaseStudySubPage