import React, { useState } from 'react';
import img from '../../Images/Images';
import "../../assests/css/KartFamily.css";
import { Link } from "react-router-dom";
import CareerModal from '../Career/CareerOpening';

const KartoFam = () => {
    const [activeCard, setActiveCard] = useState(null);
    const [selectedCareer, setSelectedCareer] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const joncard = [
        {
            id: 101,
            title: 'Wordpress Developer',
            jobsAvailable: '1-2 Years',
            image: img.wordpress,
            fulltime: "Full Time",
            solar: "Experience",
            desc: "We are hiring WordPress developers who designs and implement websites using the WordPress creation tool and can customize themes and plugins for the project. Should be able to creative attractive and user friendly websites.",
        },
        {
            id: 102,
            title: 'Angular Developer',
            jobsAvailable: '1-2 Years',
            image: img.angdev,
            fulltime: "Full Time",
            solar: "Experience",
            desc: "We are hiring Angular developers who can build efficient and modular front-end applications using Angular, collaborate with back-end developers, and ensure seamless integration of user-facing elements.",
        },
        {
            id: 103,
            title: 'Node Developer',
            jobsAvailable: '1-2 Years',
            image: img.nodedev,
            fulltime: "Full Time",
            solar: "Experience",
            desc: "We are hiring Node.js developers who can build scalable network applications, write reusable, testable, and efficient code, and implement low-latency, high-availability, and performance applications.",
        },
    ];

    const handleMouseEnter = (id) => {
        setActiveCard(id);
    };

    const handleMouseLeave = () => {
        setActiveCard(null);
    };

    const handleApplyClick = (career) => {
        setSelectedCareer(career);
        setIsModalOpen(true);
    };

    return (
        <div className='kartoFmly'>
            <div className="container mt-5">
                <div className='detailsFamlk'>
                    <p className='familyKartihir'>Join the Kartoffel Family</p>
                    <p className='hiringFamdet'>
                        Join our small team of similar-minded innovators working towards building scalable, go-to-market digital solutions. Want to rebuild technology like we do? If you are a dynamic thinker with a passion for innovation, don't hesitate to make mistakes, learn from it, and push the boundaries around a little, we'd love to have you be a part of our team!
                    </p>
                </div>
                <div className="row hirngFmlyh">
                    {joncard.map((detail) => (
                        <div 
                            key={detail.id}
                            className="col-lg-4 col-md-4 col-sm-12 detislTeamcon"
                            onMouseEnter={() => handleMouseEnter(detail.id)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div className={`familyHiring ${activeCard === detail.id ? 'active' : ''}`}>
                                <img className='hiringLogom mb-2' src={detail.image} alt={detail.title} />
                                <p className={`jobTitlv ${activeCard === detail.id ? 'active' : ''}`}>{detail.title}</p>
                                <p className='subTtiljob'>{detail.jobsAvailable}</p>
                                <p className={`descJobn ${activeCard === detail.id ? 'active' : ''}`}>{detail.desc}</p>
                                <div className='linkApply'>
                                    <button
                                        onClick={() => handleApplyClick(detail)}
                                        className={`applyBtn ${activeCard === detail.id ? 'active' : ''}`}
                                    >
                                        <img src={activeCard === detail.id ? img.applysel : img.apply} alt="Apply" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="explorHire mt-5">
                    <Link className='exploreHireaplu' to="/careers">
                        <div className='hover-image-container'>
                            <img className='hover-image img-unique' src={img.button} alt="Know More" />
                            <img className='button-image img-unique' src={img.buttonclick} alt="Know More Hover" />
                            <div className='hover-text'>
                                <p className='home-know'>Learn More</p>
                                <span className='arrow-icon'>
                                    <img src={img.rightarrow} alt="Right Arrow" />
                                </span>
                            </div>
                        </div>
                    </Link>
                </div>
                {isModalOpen && (
                    <CareerModal
                        isModalOpen={isModalOpen}
                        closeModal={closeModal}
                        career={selectedCareer}
                    />
                )}
            </div>
        </div>
    );
};

export default KartoFam;
