import React from 'react';
import img from '../../Images/Images';
import "../../assests/css/ProductAnimations.css"


const Card = ({ title, details, description, image }) => {
  return (
    <div className="card ceoCradCard">
     
      <div className="card-body">
        <p className='technlogyY'>{title}</p>
        <img className='techyWdh' src={image} alt="" />
      </div>
    </div>
  );
};

const CeoCard = ({item,index}) => {
  console.count('object');
  // const cardData = [
  //   {
  //     title: "Mr. Karthick",
  //     details: "Ceo",
  //     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at ante ultricies, dictum lorem quis, tempus neque.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam atLorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at ante ultricies, dictum lorem quis, tempus neque. ante ultricies, dictum lorem quis, tempus neque.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at ante ultricies, dictum lorem quis, tempus neque.",
  //     image: img.flight
  //   },
    // {
    //   title: "Type 2",
    //   details: "Details of Type 2",
    //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at ante ultricies, dictum lorem quis, tempus neque.",
    //   image: img2
    // },
    // {
    //   title: "Type 3",
    //   details: "Details of Type 3",
    //   description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at ante ultricies, dictum lorem quis, tempus neque.",
    //   image: img3
    // }
  // ];

  return (
    <div className='ceoOffering'>
      <div className="container">
    
       
        <div className="row ">
          {/* {cardData.map((card, index) => ( */}
            <div key={index} className={`col-xl-6 col-lg-6 col-md-6 col-sm-12  ceo-${index + 1}`}>
              <Card tname={item.ceo.tname} title={item.ceo.title} details={item.ceo.details} description={item.ceo.description} image={item.ceo.image} />
            </div>
          {/* ))} */}
        </div>
      </div>
    </div>
  );
};

export default CeoCard;
