// SpecificBanner.js
import React from 'react';
import '../../assests/css/ServiceInnerBan.css';
import Images from "../../Images/Images";
import ContactModal from './Contactodal';

// const bannerContent = {
//     title: "Why should you consult us?",
//     description: "Product audits are crucial for ensuring your product's success. They go beyond bug testing, providing a comprehensive view of strengths and weaknesses, helping you make informed decisions for improvement.",
//     buttonText: "Request Call Back",
//     imageSrc: Images.serviceproductone,
//     imageAlt: "Consultation"
// };

const SpecificBanner = ({ bannerContent }) => {
    if (!bannerContent) {
        return null;
    }
    return (
        <div className="specific-banner">
            <div className="banner-content">
                <h1>{bannerContent.title}</h1>
                <p>{bannerContent.description}</p>
                <button className='bn-ta btn bannerMobl bn-coomon' data-bs-toggle="modal" data-bs-target="#staticBackdrop">{bannerContent.buttonText}</button>
            </div>
            <ContactModal/>
            <div className="banner-image">
                <img src={bannerContent.imageSrc} alt={bannerContent.imageAlt} />
            </div>
        </div>
    );
};

export default SpecificBanner;
