import React, { useState, useEffect, useRef } from 'react';
import '../../assests/css/WebAchievements.css';

const Achievements = () => {
  const [websiteCount, setWebsiteCount] = useState(0);
  const [expertCount, setExpertCount] = useState(0);
  const [enterpriseCount, setEnterpriseCount] = useState(0);
  const [businessCount, setBusinessCount] = useState(0);
  const [salesBoosted, setSalesBoosted] = useState(0);
  const [manHours, setManHours] = useState(0);
  const [hasStarted, setHasStarted] = useState(false);
  const achievementsRef = useRef(null);

  useEffect(() => {
    const currentElement = achievementsRef.current;

    const updateCount = (setter, endValue, increment = 1, speed = 20) => {
      let count = 0;
      setter(count);
      const intervalId = setInterval(() => {
        if (count >= endValue) {
          clearInterval(intervalId);
        } else {
          count += increment;
          setter(Math.min(count, endValue));
        }
      }, speed);
      return intervalId;
    };

    const handleIntersect = (entries) => {
      if (entries[0].isIntersecting && !hasStarted) {
        setHasStarted(true);
        updateCount(setWebsiteCount, 150);
        updateCount(setExpertCount, 50);
        updateCount(setEnterpriseCount, 19);
        updateCount(setBusinessCount, 90);
        updateCount(setSalesBoosted, 2.5, 0.01, 20); // Smaller increment for decimal values
        updateCount(setManHours, 11, 1, 15); // Set increment to 1 for integer value
      }
    };

    const observer = new IntersectionObserver(handleIntersect, {
      threshold: 0.1,
    });

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [hasStarted]);

  return (
    <div className="achievements-container container" ref={achievementsRef}>
      <h2>Our Achievements</h2>
      <div className="achievements-grid">
        <div className="achievement">
          <h3>{websiteCount}+</h3>
          <p>Websites Delivered</p>
        </div>
        <div className="achievement">
          <h3>{expertCount}+</h3>
          <p>Certified Experts</p>
        </div>
        <div className="achievement">
          <h3>{enterpriseCount}+</h3>
          <p>Enterprises Modernized</p>
        </div>
        <div className="achievement">
          <h3>{businessCount}+</h3>
          <p>Businesses Empowered</p>
        </div>
        <div className="achievement">
          <h3>${salesBoosted.toFixed(2)}+</h3>
          <p>Sales Boosted</p>
        </div>
        <div className="achievement">
          <h3>{manHours}M+</h3>
          <p>11M+ Total Man Hours</p>
        </div>
      </div>
    </div>
  );
};

export default Achievements;
