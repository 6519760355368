import React, { useEffect, useState } from 'react'
import "../../assests/css/Common.css"
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import Footer from '../../commonComponents/footer/footer'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import BlogInnerBanner from '../../component/BlogInner/BlogInnerBan'
import InnerPage from '../../commonComponents/BlogInnerContentPage/InnerPage'
import { DataURL,WebappURL,ModernizationURL,TestingURL,MaintenanceURL,UIDesignURL,UIDevelopmentURL,OffBoardingURL,GameURL} from '../../commonComponents/BlogInnerContentPage/InnerBlogData';

const BlogInnerPage = () => {

    const [staticContent, setsStaticContent] = useState({});
    const [renderStatus, setRenderStatus] = useState(true);

    useEffect(() => {
        const currentPath = window.location.pathname.split('/');
        const pathParam = currentPath[currentPath.length - 1];

        if (pathParam && renderStatus) {
            const dataContent = DataURL[pathParam];
            const webappContent = WebappURL[pathParam];
            const modernizationContent = ModernizationURL[pathParam];
            const TestingContent =TestingURL[pathParam];
            const maintenanceContent = MaintenanceURL[pathParam];
            const uidesignContent = UIDesignURL[pathParam];
            const uidevelopmentContent = UIDevelopmentURL[pathParam];
            const offBoardingContent = OffBoardingURL[pathParam];
            const gameContent = GameURL[pathParam];


            setsStaticContent({ ...dataContent,...webappContent,...modernizationContent ,...TestingContent,...maintenanceContent,...uidesignContent,...uidevelopmentContent,...offBoardingContent,...gameContent});
            setRenderStatus(false);
        }
    }, [renderStatus]);

  return (
    <div className='Blogswhole'>
      <NavbarHeader />
      <BlogInnerBanner bannerImg={staticContent.bannerImg}/>
      <InnerPage blogContent={staticContent.blogContent}/>
      <GetInTouch/>
      <Footer />
    </div>
  )
}

export default BlogInnerPage