import React, { useState } from 'react';
import '../../assests/css/Elevate.css';

const ElevateOnlinePresenceForm = () => {
    // Form state variables
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [projectDetails, setProjectDetails] = useState('');

    // Validation state variables
    const [nameValid, setNameValid] = useState(true);
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);
    const [projectDetailsValid, setProjectDetailsValid] = useState(true);
    const [formValid, setFormValid] = useState(false);

    // Validation error messages
    const [nameError, setNameError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [projectDetailsError, setProjectDetailsError] = useState('');

    const validateName = (value) => {
        const isValid = value.trim() !== '';
        setNameValid(isValid);
        setNameError(isValid ? '' : 'Name is required');
        return isValid;
    };

    const validatePhoneNumber = (value) => {
        const isValid = /^\d{10}$/.test(value);
        setPhoneNumberValid(isValid);
        setPhoneNumberError(isValid ? '' : 'Invalid phone number format');
        return isValid;
    };

    const validateEmail = (value) => {
        const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        setEmailValid(isValid);
        setEmailError(isValid ? '' : 'Invalid email format');
        return isValid;
    };

    const validateProjectDetails = (value) => {
        const isValid = value.trim() !== '';
        setProjectDetailsValid(isValid);
        setProjectDetailsError(isValid ? '' : 'Project details are required');
        return isValid;
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        setName(value);
        validateName(value);
        updateFormValidity();
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
        validatePhoneNumber(value);
        updateFormValidity();
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        validateEmail(value);
        updateFormValidity();
    };

    const handleProjectDetailsChange = (e) => {
        const value = e.target.value;
        setProjectDetails(value);
        validateProjectDetails(value);
        updateFormValidity();
    };

    const updateFormValidity = () => {
        const isValid = nameValid && phoneNumberValid && emailValid && projectDetailsValid;
        setFormValid(isValid);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Trigger validation checks on all fields
        const isNameValid = validateName(name);
        const isPhoneNumberValid = validatePhoneNumber(phoneNumber);
        const isEmailValid = validateEmail(email);
        const isProjectDetailsValid = validateProjectDetails(projectDetails);

        // Check if the form is valid
        const isValid = isNameValid && isPhoneNumberValid && isEmailValid && isProjectDetailsValid;
        setFormValid(isValid);

        // Prevent form submission if invalid
        if (!isValid) {
            return;
        }

        // If the form is valid, proceed with submission
        const emailContent = `
            Name: ${name}
            Phone Number: ${phoneNumber}
            Email: ${email}
            Project Details: ${projectDetails}
        `;

        const payload = {
            to: 'info@kartoffel.in',
            subject: 'Elevate Online Presence Form Submission',
            body: emailContent,
        };

        const formData = new FormData();
        formData.append('to', payload.to);
        formData.append('subject', payload.subject);
        formData.append('body', payload.body);

        try {
            const response = await fetch('https://kartoffel.in/api/send-mail', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                alert('Form submitted successfully');
                setName('');
                setPhoneNumber('');
                setEmail('');
                setProjectDetails('');
            } else {
                alert('Failed to submit the form. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again.');
        }
    };

    return (
        <div>
            <div className='elevate-heading container'>
                <p className="elevate-heading-cont">READY TO ELEVATE ONLINE PRESENCE ?</p>
            </div>
            <div className="elevate-form-container">
                <p className="elevate-subheading">Fill Out The Form Below To Discuss Your Project Needs</p>

                <form className="elevate-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        className={`form-input ${nameValid ? '' : 'is-invalid'}`}
                        placeholder="Name"
                        value={name}
                        onChange={handleNameChange}
                    />
                    {!nameValid && <div className="invalid-feedback">{nameError}</div>}

                    <input
                        type="email"
                        className={`form-input ${emailValid ? '' : 'is-invalid'}`}
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                    {!emailValid && <div className="invalid-feedback">{emailError}</div>}

                    <input
                        type="tel"
                        className={`form-input ${phoneNumberValid ? '' : 'is-invalid'}`}
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={handlePhoneNumberChange}
                    />
                    {!phoneNumberValid && <div className="invalid-feedback">{phoneNumberError}</div>}

                    <textarea
                        className={`form-input form-area ${projectDetailsValid ? '' : 'is-invalid'}`}
                        placeholder="Project Details"
                        value={projectDetails}
                        onChange={handleProjectDetailsChange}
                    ></textarea>
                    {!projectDetailsValid && <div className="invalid-feedback">{projectDetailsError}</div>}

                    <button type="submit" className="submit-btn" disabled={!formValid}>CONTACT US</button>
                </form>
            </div>
        </div>
    );
};

export default ElevateOnlinePresenceForm;
