import React from 'react';
import '../../assests/css/Careers.css';
import Images from "../../Images/Images";
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";



const CareerSection = () => {
    const navigate = useNavigate();
    const handleApplyClick = (link) => {
        navigate(link);
    };  
    return (
        <div className='career-section'>
            <div className='container'>
                <div className='row career-content'>
                    <div className='col-lg-6 col-md-6 col-sm-12 career-cont'>
                        <div className='career-left'>
                            <p className='career-head'>Careers at Kartoffel</p>
                            <p className='career-para'>
                                The pillar that holds us together, the heart and soul of our company is our team of
                                inspiring multi-cultural and multi-talented individuals who bring different, unique
                                perspectives to their dynamic work desks every single day.
                            </p>
                            <div className='text-career'>
                                <Button  onClick={() => handleApplyClick("/careers")}  className="bn-career bn-coomon">Visit Career Page <img className="bt-learn" src={Images.learnmore} alt='button' /></Button>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 career-graph'>
                        <div className='career-right'>
                            <img src={Images.graph} className='globe-image' alt="Globe Illustration" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CareerSection;
