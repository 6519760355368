import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Images from "../../Images/Images";
import "../../assests/css/Develoment.css";

const DevelopmentTools = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        centerMode: true,
        // slidesToScroll: 1,
        speed: 3000,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        pauseOnHover: false,


        responsive: [
            {
                breakpoint: 1024, // Medium devices
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 768, // Small devices
                settings: {
                    slidesToShow: 3,
                    // slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480, // Extra small devices
                settings: {
                    slidesToShow: 1,
                    // slidesToScroll: 1,
                },
            },
        ],
    };

    // Array of image paths
    const images = [Images.angular, Images.cc, Images.css,Images.cplus, Images.docker, Images.js, Images.mango, Images.node, Images.sql];

    return (
        <div className='developmentToolsSection'>
            <div className='developmentToolsHeader container'>
                <img className='developmentTools' src={Images.development} alt="development tools" width={"100%"} />
            </div>
            <div className='slidobt'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-sm-12 col-12'>
                            <Slider {...settings}>
                              
                                {images.map((image, index) => (
                                    <div key={index} className='slider-item developmentToolsImg'>
                                        <img className='slider-image' src={image} alt={`Image ${index + 1}`} />
                                    </div>
                                ))}
                            </Slider>
                            <Slider {...settings} rtl={true} initialSlide={images.length - 1}>
                                
                                {images.map((image, index) => (
                                    <div key={index} className='slider-item developmentToolsImg'>
                                        <img className='slider-image' src={image} alt={`Image ${index + 1}`} />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DevelopmentTools;
