import './App.css';
import { BrowserRouter as Router, Route, Routes,Navigate } from "react-router-dom";
import routes from "./routes/routes"
import ScrollToTop from "./commonComponents/Scroll";
import "./assests/css/Common.css"

const staging = "/"

function App() {
  return (
    <div className="App">
       <Router basename={staging}>
       <ScrollToTop />
        <Routes >
          {routes.map((route) => {
            return (
              <Route key={route.name} path={route.path} exact={route.exact} name={route.name} element={<route.element />}
              />
            );
          })}
           <Route path="/mobile-game" element={<Navigate to="/game-engineering" />} />
           <Route path="/productengineering" element={<Navigate to="/product-engineering"/>} />
           <Route path="/digitalexperience" element={<Navigate to="/digital-transformation" />} />
           <Route path="/productdevelopment" element={<Navigate to="/product-development" />} />
           <Route path="/gameengineering" element={<Navigate to="/game-engineering" />} />
           <Route path="/interactiveengineering" element={<Navigate to="/interactive-engineering" />} />
           <Route path="/contact" element={<Navigate to="/contact-us" />} />
           <Route path="/mobileapp" element={<Navigate to="/mobile-app-development" />} />
           <Route path="/webapp" element={<Navigate to="/web-app-development" />} />
           <Route path="/uidesign" element={<Navigate to="/ui-and-ux-design" />} />
           <Route path="/uidevelopment" element={<Navigate to="/ui-and-ux-development" />} />
           <Route path="/newproduct" element={<Navigate to="/new-product-development" />} />
           <Route path="/mvpdevelopment" element={<Navigate to="/mvp-development" />} />
           <Route path="/productroadmap" element={<Navigate to="/product-roadmap" />} />
           <Route path="/gameporting" element={<Navigate to="/game-porting" />} />
           <Route path="/codevelopment" element={<Navigate to="/co-development" />} />
           <Route path="/unitygame" element={<Navigate to="/unity-game" />} />
           <Route path="/unrealgame" element={<Navigate to="/unreal-game" />} />
           <Route path="/gametesting" element={<Navigate to="/game-testing" />} />
           <Route path="/interactivedesign" element={<Navigate to="/interactive-design" />} />
           <Route path="/arvrmrengineering" element={<Navigate to="/arvrmr-engineering" />} />
           <Route path="/blogcontent" element={<Navigate to="/blog-content" />} />
           <Route path="/modernization" element={<Navigate to="/modernization-services" />} />
           <Route path="/new-product-development" element={<Navigate to="/new-product-assessment" />} />
           <Route path="/unity-game" element={<Navigate to="/unity-game-development" />} />
           <Route path="/unreal-game" element={<Navigate to="/unreal-game-development" />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
