import React from 'react';
import '../../assests/css/BlogInner.css';

const InnerPage = ({ blogContent = [] }) => {
    if (!blogContent.length) {
        return <p>Loading content...</p>;
    }

    const { title, paragraphs } = blogContent[0] || {};

    return (
        <div className="blog-post ">
            <div className=' blog-con'>
                <div className="blog-header">
                    <p className="bloginner-title mb-4">{title}</p>
                </div>
                <div className="blog-content">
                    {paragraphs && paragraphs.map((paragraph, index) => (
                        <p key={index} className={paragraph.className}>
                            {paragraph.text}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InnerPage;
