import React from 'react'
import img from '../../Images/Images'
import "../../assests/css/WeHiring.css"
import { useState } from 'react'
// import ContactModal from '../../CommonComponent/ContactUs/ContactModal'
import CareerModal from './CareerOpening'

const WeHiring = () => {

    const [selectedCareer, setSelectedCareer] = useState(null);
    const openModal = (career) => {
        setSelectedCareer(career);
        setIsModalOpen(true);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);


    console.log('isModalOpen==', isModalOpen);
    // const openModal = () => {
    //     setIsModalOpen(!isModalOpen);
    // };

    const closeModal = () => {
        setIsModalOpen(false);
    };


    const [details, setdetailsData] = useState([
        {
            title: 'Business Growth Executive',
            jobsAvailable: '2 Years',
            image: img.angulardev,
            img: img.time,
            imgcu: img.rupees,
            imgs: img.level,
            imgm: img.remote,
            clock: "8 Hour",
            mappi: "Remote",
            curenc: "30K - 35K",
            solar: "Experience",
            fulltime: "Full Time",
            apply: "Apply ",
            id: 101,
            name: "one",
        },
        {
            title: 'Business Analyst',
            jobsAvailable: ' 0-1 Years',
            image: img.angulardev,
            img: img.time,
            imgcu: img.rupees,
            imgs: img.level,
            imgm: img.remote,
            clock: "8 Hour",
            mappi: "Remote",
            curenc: "30K - 35K",
            solar: "Experience",
            fulltime: "Full Time",
            apply: "Apply ",
            id: 102,
            name: "two",
        },

        // {
        //     title: 'Wordpress Developer',
        //     jobsAvailable: ' 1-2 Years',
        //     image: img.press,
        //     img: img.time,
        //     imgcu: img.rupees,
        //     imgs: img.level,
        //     imgm: img.remote,
        //     clock: "8 Hour",
        //     mappi: "Remote",
        //     curenc: "30K - 35K",
        //     solar: "Experience",
        //     fulltime: "Full Time",
        //     apply: "Apply ",
        //     id: 101,
        //     name: "one",

        // },
        // {
        //     title: 'Angular Developer',
        //     jobsAvailable: ' 1-2 Years',
        //     image: img.angulardev,
        //     img: img.time,
        //     imgcu: img.rupees,
        //     imgs: img.level,
        //     imgm: img.remote,
        //     clock: "8 Hour",
        //     mappi: "Remote",
        //     curenc: "30K - 35K",
        //     solar: "Experience",
        //     fulltime: "Full Time",
        //     apply: "Apply ",
        //     id: 102,
        //     name: "two",
        // },
        // {
        //     title: 'Node Developer',
        //     jobsAvailable: ' 1-2 Years',
        //     image: img.nodejs,
        //     img: img.time,
        //     imgcu: img.rupees,
        //     imgs: img.level,
        //     imgm: img.remote,
        //     clock: "8 Hour",
        //     mappi: "Remote",
        //     curenc: "30K - 35K",
        //     solar: "Experience",
        //     fulltime: "Full Time",
        //     apply: "Apply ",
        //     id: 103,
        //     name: "three",
        // },
        // {
        //     title: 'MEAN Stack Developer',
        //     jobsAvailable: ' 1-2 Years',
        //     image: img.mean,
        //     img: img.time,
        //     imgcu: img.rupees,
        //     imgs: img.level,
        //     imgm: img.remote,
        //     clock: "8 Hour",
        //     mappi: "Remote",
        //     curenc: "30K - 35K",
        //     solar: "Experience",
        //     fulltime: "Full Time",
        //     apply: "Apply ",
        //     id: 104,
        //     name: "four",
        // },
        // {
        //     title: 'Game Developer',
        //     jobsAvailable: ' 1-2 Years',
        //     image: img.gamedev,
        //     img: img.time,
        //     imgcu: img.rupees,
        //     imgs: img.level,
        //     imgm: img.remote,
        //     clock: "8 Hour",
        //     mappi: "Remote",
        //     curenc: "30K - 35K",
        //     solar: "Experience",
        //     fulltime: "Full Time",
        //     apply: "Apply ",
        //     id: 105,
        //     name: "five",
        // },
        // {
        //     title: 'anga',
        //     jobsAvailable: '1-2 Years',
        //     image: img.angulrJ,
        //     img: img.clock,
        //     imgcu: img.curency,
        //     imgs: img.solargraphlinear,
        //     imgm: img.mappin,
        //     clock: "8 Hour",
        //     mappi: "Remote",
        //     curenc: "30K - 35K",
        //     solar: "Experience",
        //     fulltime: "Fulltime",
        //     apply: "Apply ",
        //     id: 106,
        // },
        // {
        //     title: 'anga',
        //     jobsAvailable: '1-2 Years',
        //     image: img.angulrJ,
        //     img: img.clock,
        //     imgcu: img.curency,
        //     imgs: img.solargraphlinear,
        //     imgm: img.mappin,
        //     clock: "8 Hour",
        //     mappi: "Remote",
        //     curenc: "30K - 35K",
        //     solar: "Experience",
        //     fulltime: "Fulltime",
        //     apply: "Apply ",
        //     id: 107,
        // },
    ]);


    const [searchDetails, setsearchDetails] = useState('')



    const handleSearchInput = (event) => {
        setsearchDetails(event.target.value);
    }
    const filteredDetails = details.filter((detail) => {
        const title = detail.title.toLowerCase();
        return title.includes(searchDetails.toLowerCase());
    });
    console.log('filteredDetails', filteredDetails);
    const [showAllDetails, setShowAllDetails] = useState(false);

    const detailsToShow =
        // showAllDetails ?
        filteredDetails
    //  : filteredDetails.slice(0, 5);
    return (

        <div className='careerOpeningsall'>

            <div className="container">
                <div className='row'>
                    <div className="fulldetailsContent col-lg-6 col-md-6 col-sm-12">
                        <h3 className='hirngTextmail'>WE’RE HIRING</h3>
                        <p className='HiringTeamSubdetails'>Join our team as we're actively seeking new talent and expanding our workforce!
                        </p>
                    </div>
                    <div className="search-bar col-lg-6 col-md-6 col-sm-12">
                        <input
                            type="text"
                            placeholder="Search by job title"
                            value={searchDetails}
                            onChange={handleSearchInput}
                            className="search-inputm"
                        />
                        {/* <img
                        src={img.searchin} // Replace with the path to your search icon image
                        alt="Search"
                        className="search-icon"
                    /> */}
                    </div>
                </div>

                <div className="allOpenHire">

                    {detailsToShow.map((detail, index) => (
                        <div className='' key={index}>
                            <div>

                                <div className='contentUpdateAllfire row ' id={detail.name}>
                                    <div className="col-lg-10 col-md-10 col-sm-12 fostDetalsnj">
                                        <div>
                                            <div className='jobDescandFolo '>
                                                <div className='imgMinaDibn'>
                                                    <img className='profilWorl' src={detail.image} alt="" />
                                                </div>
                                                <div className="updateContentopenv">
                                                    <p className='contentWrtv'>{detail.title} <span><button className='fullTime'>{detail.fulltime}</button></span></p>
                                                    {/* <p className='jobsAvailbv'>{detail.jobsAvailable}</p> */}

                                                    <div className='allSideImg row'>
                                                        <div></div>
                                                        {/* <div className='imgTextvn col-lg-2 col-md-3 col-6'><img src={detail.imgm} alt="" /><span className='spanREm'>{detail.mappi}</span></div> */}
                                                        {/* <div className='imgTextvn col-lg-2  col-md-3 col-6'>
                                                            <img src={detail.img} alt="" /><span className='spanREm'>{detail.clock}</span>
                                                        </div> */}
                                                        <div className='imgTextvn col-lg-6  col-md-6 col-6'>
                                                            <span className='spanREm' style={{paddingLeft:"0px"}}>{detail.solar}<span className='color-code'><button className='fullTime' style={{"padding":"0px","marginLeft":"2px"}}>{detail.jobsAvailable}</button></span></span>
                                                        </div>
                                                        {/* <div className='imgTextvn col-lg-3  col-md-3 col-6'>
                                                            <img src={detail.imgcu} alt="" /><span className='spanREm'>{detail.curenc}</span>
                                                        </div> */}

                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='col-lg-2 col-md-2 col-sm-12 aplycOL'>
                                        <button
                                            className="btn btn-primary applyJobmk"
                                            onClick={() => openModal(detail)} // Pass the career details to openModal
                                        >
                                            Apply Now
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                    ))}
                    {isModalOpen && <CareerModal isModalOpen={isModalOpen} closeModal={closeModal} career={selectedCareer} />}
                    {/* <div className='seeMoreJobsfull'>
    <button className='fullTimseemore' onClick={() => setShowAllDetails(!showAllDetails)}>
        {showAllDetails ? 'See Less Jobs' : 'See More Jobs'}
    </button>
</div> */}


                </div>
            </div>




        </div>
    )
}

export default WeHiring