import React from 'react'
import NavbarHeader from "../../commonComponents/header/NavbarHeader"
import Footer from "../../commonComponents/footer/footer"
import ServiceCards from '../../component/Service/ServiceCard'
import ProductionIdea from "../../component/Service/ProductionIdea"
import DevelopmentTools from "../../component/Service/DevelopmentTools"
import OutsourcedProducts from "../../component/Service/OutSourced"
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import ServiceBan from '../../component/Service/ServiceBanneer'
import FAQ from '../../component/Service/Faq'
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient'
import Seo from "../../commonComponents/MetaTag/MetaTag";
import { ProductService, bann, service,faqs,listItems } from "../../commonComponents/ServicesData/GameAndInteractive"
import Statistics from '../../commonComponents/ProjectCount/ProjectCount'

const GameInteractive = () => {
  const meta_description=" Create immersive experiences with our expert game and interactive engineering solutions.Product Engineering Management";
  const meta_title="Engage Users - Game and Interactive Engineering | Kartoffel Technologies";
  const dynamicTitle = {
    one: "We design cutting-edge interactive",
    two: "products that engage users today and",
    three: "anticipate tomorrow’s needs."
  };
  const out_title="Why Invest in Interactive Engineering?"
  return (
    <div className='Kart-service'>
       <Seo meta_description={meta_description} meta_title={meta_title}/>
      <NavbarHeader />
      <ServiceBan bann={bann} />
      <ServiceCards service={service} title={dynamicTitle}/>
      <ProductionIdea sliderData={ProductService} />
      {/* <OutsourcedProducts listItems={listItems}  out_title={out_title} /> */}
      <Statistics/>
      {/* <OurTrustedPartners /> */}
      <DevelopmentTools />
      <FAQ faqs={faqs}/>
      <GetInTouch />
      <Footer />
    </div>
  )
}

export default GameInteractive