import React from 'react'
import "../../assests/css/Common.css"
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import Footer from '../../commonComponents/footer/footer'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import BlogInnerBanner from '../../component/BlogInner/BlogInnerBan'
import BlogInnerContent from '../../component/BlogInner/BlogInnerContent'
import Images from "../../Images/Images" 


const BlogInnerPages = () => {

 const bannerImg = {
    imageSrc: Images.blogban, 
    imageAlt: "Banner"
  };


  return (
    <div className='Blogswhole'>
      <NavbarHeader />
      <BlogInnerBanner bannerImg={bannerImg} />
      <BlogInnerContent />
      <GetInTouch />
      <Footer />
    </div>
  )
}

export default BlogInnerPages