import React from 'react'
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient'
import "../../assests/css/Common.css"
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import Footer from '../../commonComponents/footer/footer'
import AboutBan from '../../component/About/AboutBanner'
import TeamSection from '../../component/About/TeamSection'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import CareerSection from '../../component/Home/Careers'
import OurOffice from '../../component/About/OurOffice'
import KartoFam from '../../component/About/KartFamily'
import Seo from "../../commonComponents/MetaTag/MetaTag";


const About = () => {
  const meta_description=" Welcome to Kartoffel Technologies. We specialize in advanced technology solutions and comprehensive services, transforming businesses globally.";
  const meta_title=" About Us | Kartoffel Technologies";
  return (
    <div className='about'>
      <Seo meta_description={meta_description} meta_title={meta_title}/>
      <NavbarHeader />
      <AboutBan />
      {/* <OurTrustedPartners /> */}
      <OurOffice/>
      <TeamSection/>
      <KartoFam/>
      <CareerSection/>
      <GetInTouch/>
      <Footer />
    </div>
  )
}

export default About