import React from 'react';
import '../../assests/css/BlogInner.css';

const blogContent = [
    {
        title: 'KARTOFFEL - Terms And Conditions',
        paragraphs: [
            { text: "Welcome to our website. By continuing to use this website, you agree to comply and be bound by the following terms and conditions of use, which, along with our privacy policy, govern Kartoffel Technologies' relationship with you in connection to this website. If you disagree with these terms and conditions, refrain from using our website." },
            { text: "The term 'Kartoffel Technologies', 'we', or 'us' refers to the website owner, headquartered at Office No. 28, Workflo by OYO,Greeta Towers, 99, Rajiv Gandhi Salai, Phase-1, Industrial Estate, Perungudi, Chennai-600096." },
            // { text: "" },
            { text: "Our company registration number is AAL-2971, registered in Chennai, India. The term 'you' refers to the visitor of our website." },
            { text: "Use of this website is subject to the following terms:",  className: "extra-font" },
            { text: "-> The content on this website is provided for general information and use only. It may be subject to change without prior information." },
            { text: "-> We do not provide a warranty/ guarantee about the accuracy, performance, or suitability of the information and materials offered on this website for any specific purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law." },
            { text: "-> Using any information or materials on this website is entirely at your own risk, and we will not be liable for any damages. You are responsible for ensuring that any services or information available through this website meet your specific needs." },
            { text: "-> Unauthorized use of this website may give rise to a claim for damages and constitute a criminal offence." },
            { text: "-> This website may include links to other websites from time to time. These links are provided for your convenience to offer further information. They do not signify that we endorse the linked website(s). We hold no responsibility for the content of any linked website(s)." },
            { text: "-> Your use of this website and any dispute arising from such use is subject to the laws of India." },
        ]
    }
];

const TermsAndCondition = () => {
    const { title, paragraphs } = blogContent[0];

    return (
        <div className="blog-post p-0 ">
            <div className='blog-con '>
                <div className="blog-header mt-5">
                    <p className="bloginner-title mb-4">{title}</p>
                </div>
                <div className="blog-content">
                    {paragraphs.map((paragraph, index) => (
                        <p key={index} className={paragraph.className || ''}>
                            {paragraph.text}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TermsAndCondition;
