import React, { useState } from 'react';
import "../../assests/css/ContactModal.css"
import img from '../../Images/Images';
import Select from 'react-select';

const ContactModal = () => {
  const [inputType, setInputType] = useState("select");
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    countryCode: '+91',
    idea: '',
  });
  const [formError, setFormError] = useState(false);
  const [msgError, setMsgError] =useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/\s/g, ''); // Remove all spaces

    if (e.target.name === 'countryCode') {
      // Allow only numbers, +, and -, limit length to 5 characters
      value = value.replace(/[^0-9+-]/g, '').slice(0, 5);
    } else if (e.target.name === 'number') {
      // Allow only numbers, limit length to 10 digits
      value = value.replace(/\D/g, '').slice(0, 10);
    }

    setFormData({ ...formData, [e.target.name]: value });
  };

  const validateForm = () => {
    let errMsg = '';

    if (!formData.name || !formData.email || !formData.number || !formData.idea) {
      errMsg = 'Please fill out all fields.';
    } else if (formData.email) {
      const regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!regexEmail.test(formData.email)) {
        errMsg = 'Invalid email format.';
      }
    }

    if (formData.number) {
      if (formData.number.length !== 10) {
        errMsg = 'Phone number must be 10 digits long.';
      }
    }

    if (!formData.countryCode || formData.countryCode.length < 2) {
      errMsg = 'Invalid country code.';
    }

    if (!isChecked && !errMsg) {
      errMsg = 'Please agree to the Terms and Conditions.';
    }

    return errMsg;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errMsg = validateForm();

    if (errMsg) {
      setFormError(true);
      setMsgError(errMsg);
      return;
    }

    try {
      document.querySelector('.sub').disabled = true;

      const requestBody = {
        to: "contactus@kartoffel.in",
        subject: `New Contact from ${formData.name}`,
        body: `<p><strong>Name:</strong>${formData.name}</p>
          <p><strong>Email:</strong> ${formData.email}</p>
          <p><strong>Phone Number:</strong> ${formData.countryCode} ${formData.number}</p>
          <p><strong>Service:</strong>  ${formData.idea}</p>`,
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      };

      const response = await fetch('https://kartoffel.in/api/send-mail', requestOptions);
      const result = await response.json();
      console.log(result);

      setSubmissionSuccess(true);
      setTimeout(() => {
        setSubmissionSuccess(false);
      }, 5000);
      document.querySelector('.sub').removeAttribute('disabled');
    } catch (error) {
      console.log('error', error);
    }

    setFormData({
      name: '',
      email: '',
      number: '',
      countryCode: '+91',
      idea: '',
    });
    setSelectedIdeas([]);
    setFormError(false);
    setIsChecked(false);
  };

  const details = [
    {
      image: img.list,
      text: 'Swift response from us.',
    },
    {
      image: img.list,
      text: 'Detailed one-one discovery & consultation.',
    },
    {
      image: img.list,
      text: 'Customized solution proposal will be shared.',
    },
    {
      image: img.list,
      text: 'Strict adherence to timeline and milestones.',
    },
    {
      image: img.list,
      text: 'Seamless collaboration and progress.',
    },
  ];

  return (
    <div>
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header hed">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body popUp">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 contactSinputS">
                  <h3 className="contactSlide">CONTACT US </h3>
                  <form className='formIn' onSubmit={handleSubmit}>
                    <div className={`form-group name ${formData.name ? 'filled' : ''}`}>
                      <input
                        type="text"
                        placeholder='Name *'
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={`form-group email ${formData.email ? 'filled' : ''}`}>
                      <input
                        type="text"
                        placeholder='Email *'
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className={`form-group num ${formData.number ? 'filled' : ''}`}>
                      <div className="country-code">
                        <input
                          type="text"
                          placeholder=""
                          className="form-control"
                          id="countryCode"
                          name="countryCode"
                          value={formData.countryCode}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="phone-number">
                        <input
                          type="tel"
                          placeholder="Number"
                          className="form-control"
                          id="number"
                          name="number"
                          value={formData.number}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className={`form-group idea ${formData.idea ? 'filled' : ''}`}>
                      {inputType === "select" ? (
                        <Select
                          className="react-select-container"
                          classNamePrefix="react-select"
                          value={selectedIdeas}
                          onChange={(selectedOptions) => {
                            setSelectedIdeas(selectedOptions);
                            setFormData({ ...formData, idea: selectedOptions.map((option) => option.value).join(", ") });
                          }}
                          options={[
                            { value: 'Product Engineering Management', label: 'Product Engineering Management' },
                            { value: 'Digital Experience Design', label: 'Digital Experience Design' },
                            { value: 'New Product Development', label: 'New Product Development' },
                            { value: 'Modernization Services', label: 'Modernization Services' },
                            { value: 'Game and Interactive Engineering', label: 'Game and Interactive Engineering' },
                          ]}
                          isMulti
                          placeholder="Select Service*"
                        />
                      ) : (
                        <input
                          type="text"
                          placeholder="Enter Service*"
                          className="form-control"
                          name="idea"
                          value={formData.idea}
                          onChange={handleInputChange}
                        />
                      )}
                    </div>

                    <div className="form-group input-type-toggle">
                      <label className='toggrl'>
                        <input
                          type="radio"
                          name="inputType"
                          value="select"
                          checked={inputType === "select"}
                          onChange={() => setInputType("select")}
                        />
                        Select Service
                      </label>
                      <label className='toggrl'>
                        <input
                          type="radio"
                          name="inputType"
                          value="text"
                          checked={inputType === "text"}
                          onChange={() => setInputType("text")}
                        />
                        Enter Service Manually
                      </label>
                    </div>

                    <div className="form-group terms">
                      <label htmlFor="termsCheckbox" className="checkbox-label box">
                        <input
                          type="checkbox"
                          id="termsCheckbox"
                          name="termsCheckbox"
                          checked={isChecked}
                          onChange={() => setIsChecked(!isChecked)}
                        />
                        I agree to the Terms and Conditions
                      </label>
                    </div>

                    <button type="submit" className="btn btn-primary sub">
                      Submit
                    </button>
                    {formError && (
                      <p className="text-danger mt-2">
                        {msgError}
                      </p>
                    )}
                    {submissionSuccess && (
                      <p className="text-success mt-2">
                        Form submitted successfully!
                      </p>
                    )}
                  </form>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 whatHpnsSlide">
                  <h3 className='hpnsnxtbnm'>WHAT HAPPENS NEXT?</h3>
                  <div className="happnsNextDetails">
                    {details.map((detail, index) => (
                      <div key={index} className="starTextt">
                        <span><img src={detail.image} alt="" /></span>
                        <p>{detail.text}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
