import React, { useEffect } from 'react'
import NavbarHeader from "../../commonComponents/header/NavbarHeader"
import Footer from "../../commonComponents/footer/footer"
import ServiceCards from '../../component/Service/ServiceCard'
import ProductionIdea from "../../component/Service/ProductionIdea"
import DevelopmentTools from "../../component/Service/DevelopmentTools"
import OutsourcedProducts from "../../component/Service/OutSourced"
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import ServiceBan from '../../component/Service/ServiceBanneer'
import FAQ from '../../component/Service/Faq'
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient'
import Seo from "../../commonComponents/MetaTag/MetaTag";
import { ProductService, bann, service,faqs,listItems } from "../../commonComponents/ServicesData/ProductEngineeringManagement"
import Statistics from '../../commonComponents/ProjectCount/ProjectCount'

const Service = () => {
  const meta_description=" Optimize your product development process with our efficient engineering management services.";
  const meta_title=" Streamline Development - Product Engineering Management | Kartoffel Technologies";
  const meta_keyword = "mobile app development service, mobile app as a service, best mobile app development software, app development service, mobile app development companies in USA, mobile app development service provider, mobile app development services company, Web app Development Service, web development services company, web design and development service, web development service providers, website development services provider, website development services USA, B2B web development, web development specialist, Shopify website developers, professional website developers, website developer WordPress, WordPress website developers."
  const dynamicTitle = {
    one: "Get Tailored IT Product",
    two: "Engineering Solutions That Fit Your",
    three: "Business Needs."
  };
  const out_title="How can effective Product Development drive your business success?"
  return (
    <div className='Kart-service'>
       <Seo meta_description={meta_description} meta_title={meta_title} meta_keyword={meta_keyword}/>
      <NavbarHeader />
      <ServiceBan bann={bann} />
      <ServiceCards service={service}  title={dynamicTitle} />
      <ProductionIdea sliderData={ProductService} />
      {/* <OutsourcedProducts  listItems={listItems} out_title={out_title} /> */}
      <Statistics/>
      <DevelopmentTools />
      {/* <OurTrustedPartners /> */}
      <FAQ faqs={faqs}/>
      <GetInTouch />
      <Footer />
    </div>
  )
}

export default Service