import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import "../../assests/css/ServiceCard.css";
import Images from "../../Images/Images";
import { useNavigate } from "react-router-dom";


const ServiceCards = ({ service,title }) => {
    const navigate = useNavigate();
    const handleApplyClick = (link) => {
        navigate(link);
    };
    return (
        <div className='service-page'>
            <Container className="service-cards service-all">
                <h2 className="text-center">{title.one}<br/>{title.two}<br/>{title.three}</h2>
                <Row>
                    {service.map((service, index) => (
                        <Col xs={12} md={6} lg={6} className="mb-4" key={index}>
                            <div className="card h-100">
                                <img src={service.image} className="card-img-top" alt={service.title} />
                                <div className="card-body">
                                    <h5 className="card-title">{service.title}</h5>
                                    <p className="card-text">{service.description}</p>
                                    {/* <Button className="bn-learnmore bn-coomon" onClick={() => handleApplyClick(service.url)}>Learn More <img className="bt-learn" src={Images.learnmore} /></Button> */}
                                    <div className='hover-image-container'>
                                        <img className='hover-image ser-unique' src={Images.button} alt="Know More" />
                                        <img className='button-image ser-unique' src={Images.buttonclick} alt="Know More Hover" />
                                        <div className='hover-text' onClick={() => handleApplyClick(service.url)}>
                                            <p className='home-know'>Know More</p>
                                            <span className='arrow-icon'>
                                                <img src={Images.rightarrow} alt="Right Arrow" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
};

export default ServiceCards;
