import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Images from '../../Images/Images';
import "../../assests/css/OurClient.css"


const WebDomain = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 0,
        pauseOnHover: false,
        speed: 3000, 
      
        responsive: [
            {
                breakpoint: 1025, // Medium devices
                settings: {
                    slidesToShow: 4,
                    // slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768, // Small devices
                settings: {
                    slidesToShow: 3,
                    // slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480, // Extra small devices
                settings: {
                    slidesToShow: 1,
                    // slidesToScroll: 1,
                },
            },
        ],
    };

    // Array of image paths
    const images = [Images.webfoodtech, Images.webfintech, Images.webedutech, Images.webhealthtech, Images.webecom, Images.webenter,Images.webenergy, Images.webinternet,Images.webtele,Images.weblog
    //      img.Hotel_Saray, img.Etisalat, img.Allobaby, img.Credo_Health, img.Anna_Kirana, img.Spartek, img.Mobility_MEA, img.Pro_Connect, img.Kiddle, img.Eazybuy, img.My_LabConnect, img.Neuberg, img.Laiqa, img.Twisty_Tails, img.Thea,
    // img.Casa, img.Salad_Box, img.Chef_Mate, img.Champs_Academy, img.Digidoc, img.Epsilon_Academy, img.Volvo, img.Prabha_Physio_Care, img.Olive_Tree, img.VDelivr, img.periodpal_logo, img.TANUVAS, img.alike_store, img.Word_Clan, img.Your_perfect_stitches,
    // img.Light_Rider, img.Savage_Dealz, img.Selvi_School_of_Sonography, img.Shvedh, img.Hafawa_Lighthouse, img.Thap, img.Power_of_N,
];

    return (
        <div className='OurProductTrustedClients mb-5'>
            <div className='trustedPartners'>
                <p className='titleOurTrustedPartners'>Domains We Have Worked On</p>
            </div>
            <div className='slidobt'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-sm-12 col-12 clients'>
                            <Slider {...settings}>
                                {images.map((image, index) => (
                                    <div key={index} className='slider-item'>
                                        <img
                                            className='slider-image trs'
                                            src={image}
                                            alt={`Image ${index + 1}`}
                                            style={{
                                                maxWidth: '300px',
                                                height: '160px',
                                                margin: '0 auto',
                                            }}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebDomain;
