import React from 'react';
import '../../assests/css/MobileLandWhat.css';
import Images from '../../Images/Images';

const WhatWeOffer = () => {
    const offers = [
        { id: 1, imgSrc: Images.mobilelandone, title: 'Tailored Functionality' },
        { id: 2, imgSrc: Images.mobilelandtwo, title: 'Rich UI/UX' },
        { id: 3, imgSrc: Images.mobilelandthree, title: 'Platform Compatibility' },
        { id: 4, imgSrc: Images.mobilelandfour, title: 'Scalability' },
        { id: 5, imgSrc: Images.mobilelandfive, title: 'Security' },
        { id: 6, imgSrc: Images.mobilelandsix, title: 'Third-Party Integration' },
        { id: 7, imgSrc: Images.mobilelandseven, title: 'Data Security' },
        { id: 8, imgSrc: Images.mobilelandeight, title: 'Data Visualization' },
    ];

    return (
        <div className="what-we-offer container">
            <p className="mobile-land-title">WHAT DO WE OFFER?</p>
            <div className="row">
                {offers.map((offer, index) => (
                    <div className={`col-lg-3 col-md-3 col-sm-12 web-col web-land-subhead`} key={index}>
                        <div key={offer.id} className={`offer-item ${offer.highlighted ? 'highlighted' : ''}`}>
                            <img src={offer.imgSrc} alt={offer.title} className="offer-icon" />
                            <p className="offer-title">{offer.title}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WhatWeOffer;

