import React from 'react';
import '../../assests/css/Address.css';
import Images from "../../Images/Images"

const AddressMap = () => {
    const defaultLatitude = 12.95585635;
    const defaultLongitude = 80.24327299999999;
    return (
        <div className="address-map-container">
            <div className="row">
                <div className="col-lg-6 address-section">
                    <p className='address-section-para'>ADDRESS</p>
                    <div className="address-info">
                        <div className="address-item">
                            <img src={Images.loc} alt="Location Icon" />
                            <p>Office No. 28, Workflo by OYO,<br />
                                Greeta Towers, 99, Rajiv Gandhi Salai, Phase-1, Industrial Estate, Perungudi, Chennai-600096</p>
                        </div>
                        <div className="address-item">
                            <img src={Images.mail} alt="Mail Icon" />
                            <p>Email: contactus@kartoffel.in</p>
                        </div>
                        <div className="address-item">
                            <img src={Images.phn} alt="Phone Icon" />
                            <p>Phone: +91 9876543210</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 map-section">
                    <iframe
                        className="map"
                        src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15552.999490996586!2d${defaultLongitude}!3d${defaultLatitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1690810697613!5m2!1sen!2sin`}
                        width="100%"
                        height="350"
                        style={{ border: '0', borderRadius: '0' }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Maps Location"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default AddressMap;
