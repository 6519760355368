// SpecificBanner.js
import React from 'react';
import '../../assests/css/BlogsBan.css';
import Images from "../../Images/Images";
import { Link } from 'react-router-dom';

const bannerContent = {
    title: "How to Choose the Right Software Development Methodology for Your Project",
    description: "Discuss various methodologies like Agile, Scrum, Waterfall, and DevOps, and provide guidance on selecting the best one for different project types.",
    imageSrc: Images.blogban,
    imageAlt: "Consultation"
};

const BlogBanner = () => {

    return (
        <div className="Blog-banner">
            <div className="blog-whole">
                <div className='blogs-img'>
                <img src={bannerContent.imageSrc} alt={bannerContent.imageAlt} className='w-100'/>
                </div>
                <div className="blog-banner-content">
                    <div className='d-flex gap-3'>
                        <p className='back-blogs'>Software</p>
                        <p className='back-blogs'>Agile</p>
                    </div>
                    <p className='blogs-heads'>{bannerContent.title}</p>
                    <p className='blogs-subheads'>{bannerContent.description}</p>
                    <Link to="/blog-content" className='blogs-links'>Read More</Link>
                </div>
            </div>
        </div>
    );
};

export default BlogBanner;
