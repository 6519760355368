import Servicespage from "../Pages/servicePage/Service.jsx";
import Homepage from "../Pages/Home/HomePage.jsx";
import AboutPage from "../Pages/AboutPage/AboutPage.jsx";
import CareerPage from "../Pages/Career/CareerPage.jsx";
import Digital from "../Pages/servicePage/DigitalExperience.jsx"
import NewProduct from "../Pages/servicePage/NewProduct.jsx"
import Modernization from "../Pages/servicePage/Modernization.jsx"
import Game from "../Pages/servicePage/GameAndInteractive.jsx"
import Contact from "../Pages/ContactUs/ContactUsPage.jsx"
import serviceinn from "../Pages/servicePage/ServiceInnerPage.jsx"
import Blogs from "../Pages/Blogs/BlogsPage.jsx"
import BlogsInner from "../Pages/BlogInnerPage/BlogInnerPage.jsx"
import BlogContent from "../Pages/InnerBlogPage/InnerBlogPage.jsx"
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPage.jsx"
import TermsAndCondition from "../Pages/TermsAndCondition/TermsAndConditionPage.jsx"
import WebLand from "../Pages/WebLandingPage/WebLandingPage.jsx"
import MobileLand from "../Pages/MobileLandingPage/MobileLandingPage.jsx"
import CaseStudy from "../Pages/CaseStudy/CaseStudy.jsx"
import CaseStudySubPage from "../Pages/CaseStudy/CaseStudySubPage.jsx"
import KnowledgeCenter from "../Pages/KnowledgeCenter/KnowledgeCenter.jsx"
import CuspPage from "../Pages/CuspPage/CuspPage.jsx"


const routes = [
    { path: "/", name: "Home", element: Homepage, exact: true, },
    { path: "/product-engineering", name: "service", element: Servicespage, exact: true, },
    { path: "/digital-transformation", name: "Digital", element: Digital, exact: true, },
    { path: "/product-development", name: "NewProduct", element: NewProduct, exact: true, },
    { path: "/about", name: "about", element: AboutPage, exact: true, },
    { path: "/careers", name: "career", element: CareerPage, exact: true, },
    { path: "/game-engineering", name: "Modernization", element: Modernization, exact: true, },
    { path: "/interactive-engineering", name: "Game", element: Game, exact: true, },
    { path: "/contact-us", name: "Contact", element: Contact, exact: true, },
    { path: "/mobile-app-development", name: "mobileappdevelopment", element: serviceinn, exact: true, },
    { path: "/web-app-development", name: "webappdevelopment", element: serviceinn, exact: true, },
    { path: "/modernization-services", name: "modernizationappdevelopment", element: serviceinn, exact: true, },
    { path: "/maintenanceandsupport", name: "maintenanceappdevelopment", element: serviceinn, exact: true, },
    { path: "/ui-and-ux-design", name: "uidesigndevelopment", element: serviceinn, exact: true, },
    { path: "/ui-and-ux-development", name: "uidevelopment", element: serviceinn, exact: true, },
    { path: "/new-product-assessment", name: "newproductdevelopment", element: serviceinn, exact: true, },
    { path: "/mvp-development", name: "mvpdevelopment", element: serviceinn, exact: true, },
    { path: "/product-roadmap", name: "productroadmapdevelopment", element: serviceinn, exact: true, },
    { path: "/game-porting", name: "gameportingdevelopment", element: serviceinn, exact: true, },
    { path: "/co-development", name: "codevelopment", element: serviceinn, exact: true, },
    { path: "/unity-game-development", name: "unitygame", element: serviceinn, exact: true, },
    { path: "/unreal-game-development", name: "unrealgame", element: serviceinn, exact: true, },
    { path: "/game-testing", name: "gametesting", element: serviceinn, exact: true, },
    { path: "/interactive-design", name: "interactivedesign", element: serviceinn, exact: true, },
    { path: "/arvrmr-engineering", name: "arvrmrengineering", element: serviceinn, exact: true, },
    { path: "/blogs", name: "BlogsPage", element: Blogs, exact: true, },
    { path: "/blog-content", name: "BlogsinnerPage", element: BlogsInner, exact: true, },
    { path: "/tackling", name: "tackling", element: BlogContent, exact: true, },
    { path: "/native", name: "native", element: BlogContent, exact: true, },
    { path: "/administrators", name: "Administrators", element: BlogContent, exact: true, },
    { path: "/testing", name: "testing", element: BlogContent, exact: true, },
    { path: "/architecture", name: "architecture", element: BlogContent, exact: true, },
    { path: "/recruitment", name: "recruitment", element: BlogContent, exact: true, },
    { path: "/Photogrammetry", name: "Photogrammetry", element: BlogContent, exact: true, },
    { path: "/offboarding", name: "offboarding", element: BlogContent, exact: true, },
    { path: "/game", name: "offboarding", element: BlogContent, exact: true, },
    { path: "/privacy-policy", name: "PrivacyPolicy", element: PrivacyPolicy, exact: true, },
    { path: "/terms-and-condition", name: "TermsandCondition", element: TermsAndCondition, exact: true, },
    { path: "/web-app-development-service", name: "webLand", element: WebLand, exact: true, },
    { path: "/mobile-app-development-service", name: "mobileLand", element: MobileLand, exact: true, },
    { path: "/case-study", name: "casestudy", element: CaseStudy, exact: true, },
    { path: "/case-study-thap", name: "CaseStudySubPage", element: CaseStudySubPage, exact: true, },
    { path: "/knowledge-center", name: "KnowledgeCenter", element: KnowledgeCenter, exact: true, },
    { path: "/cusp-page", name: "CuspPage", element: CuspPage, exact: true, },
    
];
export default routes   