import React from 'react'
import Images from '../../Images/Images'
import { Container, Button } from 'react-bootstrap';
import "../../assests/css/GetInTouch.css"
import { useNavigate } from "react-router-dom";


const GetInTouch = () => {
    const navigate = useNavigate();
    const handleApplyClick = (link) => {
        navigate(link);
    }; 
    return (
        <div className='git '>
            <Container className='git-con'>
                <div className='d-flex flex-column flex-md-row git-content'>
                    <div className='work mt-2'>
                        <p className='together'>LET’S WORK TOGETHER. <br />GET IN TOUCH.</p>
                    </div>
                    {/* <div className='tals mt-md-0'> */}
                        {/* <Button className="bn-talk bn-coomon" onClick={() => handleApplyClick("/contact-us")} >Lets Talk <img className="bt-learn" src={Images.learnmore} /></Button> */}
                        <div className='hover-image-container tals'>
                            <img className='hover-image  img-unique' src={Images.button} alt="Know More" />
                            <img className='button-image img-unique' src={Images.buttonclick} alt="Know More Hover" />
                            <div className='hover-text'  onClick={() => handleApplyClick("/contact-us")}>
                                <p className='home-know'>Lets Talk</p>
                                <span className='arrow-icon'>
                                    <img src={Images.rightarrow} alt="Right Arrow" />
                                </span>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </Container>
        </div>
    )
}

export default GetInTouch