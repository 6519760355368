// src/components/Service/sliderData.js
import Images from "../../Images/Images";

const ProductService = [
    {
        number: "01",
        title: "Engaging Digital Experiences",
        description: `We specialise in creating interactive digital experiences that captivate users and keep them engaged. Our designs are focused on storytelling and technology, ensuring that every interaction is memorable and immersive.`,
        image: Images.Ilmdeation
    },
    {
        number: "02",
        title: "Human-Centered Design",
        description: `Our approach prioritises user-friendly, human-centered designs to enhance user experience (UX). By understanding user behaviour and preferences, we develop interfaces that are intuitive and personalised.`,
        image: Images.DdesigDevelopment
    },
    {
        number: "03",
        title: "Seamless Integration Across Devices",
        description: `We ensure that digital solutions integrate seamlessly across all devices and platforms, providing a consistent experience for users. Our expertise guarantees smooth functionality and accessibility, regardless of the device specifications.`,
        image: Images.plPrototyping
    },
    {
        number: "04",
        title: "Optimised Performance",
        description: `We focus on delivering top performance, ensuring that digital experiences run smoothly even on resource-limited devices. Our engineering approach emphasises efficiency and responsiveness to provide the best possible user experience.`,
        image: Images.TklkestingIteration
    },
    {
        number: "05",
        title: "Data-Driven Enhancements",
        description: `We use data analytics to continuously monitor and improve user satisfaction. By leveraging insights, we fine-tune interactive features to meet evolving user needs and drive ongoing engagement.`,
        image: Images.LauncOptimization
    }
];
const bann = [
    {
        img: Images.modernban,
        head: "INTERACTIVE ENGINEERING",
        // headone: "Design",
        serpara: "Team up to re-define and create engaging and immersive digital experiences.",
    }
];
const service = [
    {
        title: "Interactive Design ",
        description: "Create immersive, engaging, and addictive games that captivate your audience, driving player engagement and boosting revenue.",
        image: Images.interactive,
        url :"/interactive-design"
    },
    {
        title: "AR / VR / MR Engineering",
        description: "Transform your vision into reality with cutting-edge AR/VR solutions that revolutionize user experiences and unlock limitless possibilities.",
        image: Images.arvr,
        url :"/arvrmr-engineering"
    },
    // {
    //     title: "Digital Asset Creation",
    //     description: "Bring your ideas to life with stunning visuals, captivating animations, and compelling digital assets that enhance your brand and captivate your audience.",
    //     image: Images.UXmok
    // },
    // {
    //     title: "Maintenance and Upgrade",
    //     description: "Ensure seamless performance, fix bugs, and unlock new features with our reliable maintenance and upgrade services, keeping your game or interactive experience at its best.",
    //     image: Images.consulting_auditing
    // },
];
const faqs = [
    {
        question: "What can you help me with?",
        answer: "We design and develop interactive applications tailored to your specific needs. We also create engaging user interfaces, integrating multimedia elements and ensuring a seamless user experience."
    },
    {
        question: "What types of interactive applications do you develop?",
        answer: "AR, VR, and Mixed Reality are some interactive applications we develop."
    },
    {
        question: "How do you ensure the effectiveness of interactive applications?",
        answer: "We use analytics to track user interactions and optimize the application for better engagement. Our internal quality audit team also ensures the application's quality and efficiency."
    },
    {
        question: "Can you integrate interactive applications with other systems?",
        answer: "We can integrate interactive applications with existing systems/ applications."
    },
];
const listItems = [
    {
        alt: "Specialized Expertise",
        text: "Create engaging digital experiences that captivate users.",
    },
    {
        alt: "Specialized Expertise",
        text: "Develop user-friendly, human-centered designs for enhanced UX.",
    },
    {
        alt: "Specialized Expertise",
        text: "Ensure seamless integration across all devices and platforms.",
    },
    {
        alt: "Specialized Expertise",
        text: "Integrate mechanisms to boost engagement and satisfaction.",
    },
    {
        alt: "Specialized Expertise",
        text: "Design interfaces that personalize based on user behavior.",
    },
    {
        alt: "Specialized Expertise",
        text: "Combine technology and narrative for captivating stories.",
    },
    {
        alt: "Specialized Expertise",
        text: "Focus on optimal performance across all device specifications.",
    },
    {
        alt: "Specialized Expertise",
        text: "Use data to monitor and improve user satisfaction continuously.",
    },
    {
        alt: "Specialized Expertise",
        text: "Ensure seamless integration across all devices and platforms.",
    },
];

export { ProductService,bann, service,faqs,listItems };
