import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import "../../assests/css/Navbar.css";
import Images from "../../Images/Images";
import { Link} from 'react-router-dom';
import LandContactModal from "../../commonComponents/ServiceInnerBan/LandContactModal"
import React, { useState } from 'react';

const NavbarLanding = ({ onKnowMoreClick, onOurBlog }) => {
   
    const [showModal, setShowModal] = useState(false);

    return (
        <Navbar expand="lg" className="nav-land">
            <Container fluid className='nav-kart'>
                <Navbar.Brand as={Link} to="/">
                    <img src={Images.logotwo} alt="Kartoffel logo" className="nav-logo" />
                </Navbar.Brand>
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="custom-toggler-icon">
                    <i class="fa-solid fa-bars"></i>
                    </span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav" >
                    {/* <Button variant="outline-lights bn-coomon"  onClick={() => setShowModal(true)} className="get-in-touch-btn">Let's Talk</Button> */}
                </Navbar.Collapse>
            </Container>
            <LandContactModal show={showModal} setShow={setShowModal} />
        </Navbar>
    );
}

export default NavbarLanding;
