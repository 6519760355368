import React from 'react'
import "../../assests/css/OurOffice.css"

const OurOffice = () => {
    return (
        <div className='our-office'>
            <div className='container office-head'>
                <p className='offfice-content'>
                    Our office is more than just a workplace.<br />
                    It's a hub of creativity and collaboration,<br />
                    designed to inspire greatness.
                </p>

            </div>
        </div>
    )
}

export default OurOffice