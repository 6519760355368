import React, { useState } from 'react';
import '../../assests/css/OurService.css';
import Images from '../../Images/Images';
import { useNavigate } from 'react-router-dom';
import "../../assests/css/Common.css"

const OurService = () => {
    const navigate = useNavigate();

    const pageDirect = (url, type) => {
        if (type) {
            localStorage.setItem('url_type', type);
        }
        navigate(url);
    };

    const [currentCard, setCurrentCard] = useState(0);

    const flipCards = [
        {
            className: 'flip-card-1',
            frontContent: (
                <div className='innerContentc'>
                    <div className='d-flex ser-si mb-3 align-items-center'>
                        <img className='flip-box-img' src={Images.computer} alt="Front Image" />
                        <h3 className='flip-box-header'>Product Engineering</h3></div>
                    <div>
                        <p className='flip-p'>We design, develop and launch high-quality products.
                            Our managed services ensure ongoing support and maintenance for faster time to market.</p></div>
                </div>
            ),
            url: '/product-engineering',
            type: 1
        },
        {
            className: 'flip-card-2',
            frontContent: (
                <div className='innerContentc'>
                    <div className='d-flex ser-si mb-3 align-items-center'>
                        <img className='flip-box-img' src={Images.digital} alt="Front Image" />
                        <h3 className='flip-box-header'>Digital Transformation </h3>
                    </div>
                    <p className='flip-p'>Turn website visits to conversions with our intuitive digital experiences.
                        Our approach guarantees smooth performance and creative design on every platform.  </p>
                </div >
            ),
            url: '/digital-transformation',
            type: 2
        },
        {
            className: 'flip-card-3',
            frontContent: (
                <div className='innerContentc'>
                     <div className='d-flex ser-si mb-3 align-items-center'>
                    <img className='flip-box-img' src={Images.rocket} alt="Front Image" />
                    <h3 className='flip-box-header'>
                        Product Development</h3>
                        </div>
                    <p className='flip-p'>We guide your product journey through ideation,
                        research, prototyping, testing and development until ready to launch.   </p>
                </div>
            ),
            url: '/product-development',
            type: 3
        },
        {
            className: 'flip-card-4',
            frontContent: (
                <div className='innerContentc'>
                     <div className='d-flex ser-si mb-3 align-items-center'>
                    <img className='flip-box-img' src={Images.chess} alt="Front Image" />
                    <h3 className='flip-box-header'>Game Engineering</h3>
                    </div>
                    <p className='flip-p'>Our expert team specializes in powering immersive gaming experiences that push the boundaries of technology. From advanced features to modernized platforms, we ensure your games remain powerful, competitive and unforgettable. </p>
                </div>
            ),
            url: '/game-engineering',
            type: 4
        },
        {
            className: 'flip-card-5',
            frontContent: (
                <div className='innerContentc'>
                     <div className='d-flex ser-si mb-3 align-items-center'>
                    <img className='flip-box-img' src={Images.technology} alt="Front Image" />
                    <h3 className='flip-box-header'>Interactive Engineering </h3>
                    </div>
                    <p className='flip-p'>We build engaging experiences that captivate and inspire. Our skilled team of engineers develop innovative solutions that bring your projects to life in compelling ways, enhancing user engagement and satisfaction.</p>
                </div>
            ),
            url: '/interactive-engineering',
            type: 5
        },
        {
            className: 'flip-card-6',
            frontContent: (
                <div className='innerContentc unique-card'>

                    {/* <h3 className='flip-box-header'>Product Engineering Management</h3> */}
                    <p className='flip-p card-six'>Talk With Our Team  </p>
                    <img className='flip-box-img arrow-home' src={Images.arrowhome} alt="Front Image" />
                </div>
            ),
            url: '/contact-us',
            type: 1
        }
    ];

    const handleClick = (index) => {
        setCurrentCard(index);
        const { url, type } = flipCards[index];
        pageDirect(url, type);
    };

    const renderFlipCards = () => {
        return (
            <div className="pfrSec">
                <div className="container">
                    <div className='heading-1'>
                        <p className='service-head'>OUR SERVICES</p>
                    </div>
                    <div className="row allFlipysvrow">
                        {flipCards.map((card, index) => (
                            <div
                                className="col-lg-4 col-md-6 col-sm-12 cardFlipyv"
                                key={index}
                                onClick={() => handleClick(index)}
                            >
                                <div
                                    className={`flip-card ${card.className} ${index === currentCard ? 'flipped' : ''}`}
                                >
                                    <div className="flip-card-inner">
                                        <div className={`flip-card-front flip-card-front-${index + 1}`}>
                                            {card.frontContent}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* <div className='col-lg-12 col-md-12 col-xs-12 certified-home mb-5 mt-5'>
                            <div className='container'>
                                <div>
                                    <img src={Images.certified} className='w-100' />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    };

    return renderFlipCards();
};

export default OurService;
