import React from 'react';
import Images from "../../Images/Images";
import "../../assests/css/CaseCard.css"

const CaseStudies = () => {
    return (
        <div className="casestudy-card container my-4">
            <div className="row">
                {/* First two columns, each taking half the width on larger screens */}
                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                    <div className="card h-100 case-heading">
                        <div className='w-100'>
                            <img src={Images.casephone} className="card-img-top" alt="The Happiness Project" />
                        </div>
                        <div className="card-body case-subheading">
                            <div className="case-number">
                                01
                            </div>
                            <h6 className="casestudy-card-head">Mobile</h6>
                            <h5 className="case-card-title">The Happiness Project</h5>
                            <p className="case-card-text">THAP is an app for consultation with a wellness coach via teleconsultation. It has inbuilt assessment tests, personalized plans with exercises and diet recommendations.</p>
                            <div className='hover-image-container mb-4'>
                                <img className='hover-image  blo-unique' src={Images.button} alt="Know More" />
                                <img className='button-image blo-unique' src={Images.buttonclick} alt="Know More Hover" />
                                <div className='hover-text' >
                                    <p className='home-know'>Visit Case Study</p>
                                    <span className='arrow-icon'>
                                        <img src={Images.rightarrow} alt="Right Arrow" />
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                    <div className="card h-100 case-heading">
                        <div className='w-100'>
                            <img src={Images.caselap} className="card-img-top" alt="ProConnect" />
                        </div>
                        <div className="card-body case-subheading">
                            <div className="case-number">
                                02
                            </div>
                            <h6 className="casestudy-card-head">Website</h6>
                            <h5 className="case-card-title">ProConnect</h5>
                            <p className="case-card-text">ProConnect, a division of the USD 6.5 billion Redington Group, specializes in end-to-end supply chain solutions for over 160 leading brands. </p>
                            <div className='hover-image-container mb-4'>
                                <img className='hover-image  blo-unique' src={Images.button} alt="Know More" />
                                <img className='button-image blo-unique' src={Images.buttonclick} alt="Know More Hover" />
                                <div className='hover-text' >
                                    <p className='home-know'>Visit Case Study</p>
                                    <span className='arrow-icon'>
                                        <img src={Images.rightarrow} alt="Right Arrow" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Third column taking full width on all screens */}
                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                    <div className="card h-100 case-heading">
                        <div className='w-100'>
                            <img src={Images.casemobiles} className="card-img-top" alt="PeriodPal by LAIQA" />
                        </div>
                        <div className="card-body case-subheading">
                            <div className="case-number">
                                03
                            </div>
                            <h6 className="casestudy-card-head">Mobile</h6>
                            <h5 className="case-card-title">PeriodPal by LAIQA</h5>
                            <p className="case-card-text">PeriodPal provides women an easy period tracking mobile platform. They can also track moods, sleeping habits, and water intake. The app also provides teleconsultations and chat support on demand.</p>
                            <div className='hover-image-container mb-4'>
                                <img className='hover-image  blo-unique' src={Images.button} alt="Know More" />
                                <img className='button-image blo-unique' src={Images.buttonclick} alt="Know More Hover" />
                                <div className='hover-text' >
                                    <p className='home-know'>Visit Case Study</p>
                                    <span className='arrow-icon'>
                                        <img src={Images.rightarrow} alt="Right Arrow" />
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default CaseStudies;
