import React from 'react';
import '../../assests/css/ServiceConnection.css';
// import Images from "../../Images/Images";

// const cards = [
//     {
//         title: 'Press a button',
//         text: 'Choose which invoices you want us to collect. If you have existing orders in the accounts, you can set up automatic processes.',
//         imgSrc: Images.boost,
//         imgAlt: 'Left Icon',
//         imgPosition: 'left',
//         number:"one"
//     },
//     {
//         title: 'We start discussing with the client',
//         text: 'You should be confident that Bradia always solves the case in the best possible way. Using telephones, e-mail and SMS, we start a meaningful dialogue to resolve any matter.',
//         imgSrc: Images.fix,
//         imgAlt: 'Right Icon',
//         imgPosition: 'right',
//          number:"two"
//     },
//     {
//         title: 'Full insight and control along the way',
//         text: 'You have a full overview and control and follow all contact points that are made during the process in a common Dashboard.',
//         imgSrc: Images.boost,
//         imgAlt: 'Left Icon',
//         imgPosition: 'left',
//           number:"one"
        
//     },
//     {
//         title: 'Full insight and control along the way',
//         text: 'You have a full overview and control and follow all contact points that are made during the process in a common Dashboard.',
//         imgSrc: Images.boost,
//         imgAlt: 'Left Icon',
//         imgPosition: 'right',
//           number:""
        
//     }
// ];

const ConnectedFeatures = ({ cards = [] }) => {
    return (
        <div className="connect my-5 container">
            {cards.map((card, index) => {
                // Check if the current index is even
                if (index % 2 === 0) {
                    return (
                        <div className="row split row-bottom-space" key={index} id={card.id}>
                            <div className={`col-lg-6 col-md-12 col-sm-12 ${card.imgPosition === 'left' ? 'card-one' : 'card-two'} position`} >
                                <img className={card.imgPosition === 'left' ? 'left-image' : 'right-image'} src={card.imgSrc} alt={card.imgAlt} />
                                <p className='connect-head'>{card.title}</p>
                                <p className='connect-subhead'>{card.text}</p>
                                <div className= {`${card.number === 'one' ? 'one' : ''} line`}></div>
                            </div>
                            {cards[index + 1] && (
                                <div className={`col-lg-6 col-md-12 col-sm-12 ${cards[index + 1].imgPosition === 'left' ? 'card-one' : 'card-two'} position`}>
                                    <img className={cards[index + 1].imgPosition === 'left' ? 'left-image' : 'right-image'} src={cards[index + 1].imgSrc} alt={cards[index + 1].imgAlt} />
                                    <p  className='connect-head'>{cards[index + 1].title}</p>
                                    <p  className='connect-subhead'>{cards[index + 1].text}</p>
                                    <div className={`${cards[index + 1].number === 'two' ? 'two' : ''} line`}></div>
                                </div>
                            )}            
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
};

export default ConnectedFeatures;
