import React,{useState,useEffect} from 'react'
import CeoCard from './CeoCard'
import ProductDesc from './ProductDesc'
import img from '../../Images/Images'
import "../../assests/css/ProductAnimations.css"


const MobileAnimations = (props) => {
  console.log('props',props);
  const [data,setData] = useState([])
  useEffect(()=>{
    setData(props?.data)
  },[props?.data])
  console.log("ffhgsss",data);
  // const data= [
  //   {
  //     // tname:"Recent Projects",
  //     title:"The Happiness Project (THAP) ",
  //     describe:"THAP is an app for consultation with a wellness coach via teleconsultation. It has inbuilt assessment tests, personalized plans with exercises and diet recommendations.  ",
  //     keyfact:"key focus :",
  //     focus:"Data privacy and secure video teleconsultation with chat functionality  ",
  //     ceo:{
  //       title: "Technologies:",
       
  //       image: img.thechn
  //     }
  //   },
  //   {
  //     // tname:"Recent Projects",
  //     title:"THEA (Breast Pumping Tracker App) ",
  //     describe:"THEA offers features like recording pumping sessions and collected milk, option to handle data online and offline for up to 36 hours  ",
  //     keyfact:"Key Impact:",
  //     focus:"Easy Offline tracking & Increased purchase of breast pumps  ",
  //     ceo:{
  //       title: "Technologies:",
       
  //       image: img.jsre
  //     }
  //   },
  //   {
  //     // tname:"Recent Projects",
  //     title:"PeriodPal by LAIQA ",
  //     describe:"PeriodPal provides women an easy period tracking mobile platform. They can also track moods, sleeping habits, and water intake. The app also provides teleconsultations and chat support on demand. It also has an in-app shop for menstrual wellness products.",
  //     keyfact:"key focus :",
  //     focus:"Performance, teleconsultation capabilities, and a smooth onboarding experience ",
  //     ceo:{
  //       title: "Technologies:",
       
  //       image: img.perio
  //     }
  //   }
  // ]
  // const data ={
  //   drop1:[
  //     {
  //       // tname:"Recent Projects",
  //       title:"The Happiness Project (THAP) ",
  //       describe:"THAP is an app for consultation with a wellness coach via teleconsultation. It has inbuilt assessment tests, personalized plans with exercises and diet recommendations.  ",
  //       keyfact:"key focus :",
  //       focus:"Data privacy and secure video teleconsultation with chat functionality  ",
  //       ceo:{
  //         title: "Technologies:",
         
  //         image: img.thechn
  //       }
  //     },
  //     {
  //       // tname:"Recent Projects",
  //       title:"THEA (Breast Pumping Tracker App) ",
  //       describe:"THEA offers features like recording pumping sessions and collected milk, option to handle data online and offline for up to 36 hours  ",
  //       keyfact:"Key Impact:",
  //       focus:"Easy Offline tracking & Increased purchase of breast pumps  ",
  //       ceo:{
  //         title: "Technologies:",
         
  //         image: img.jsre
  //       }
  //     },
  //     {
  //       // tname:"Recent Projects",
  //       title:"PeriodPal by LAIQA ",
  //       describe:"PeriodPal provides women an easy period tracking mobile platform. They can also track moods, sleeping habits, and water intake. The app also provides teleconsultations and chat support on demand. It also has an in-app shop for menstrual wellness products.",
  //       keyfact:"key focus :",
  //       focus:"Performance, teleconsultation capabilities, and a smooth onboarding experience ",
  //       ceo:{
  //         title: "Technologies:",
         
  //         image: img.perio
  //       }
  //     }
  //   ],
  //   drop2:[
  //     {
  //       // tname:"Recent Projects",
  //       title:"The Happiness Project (THAP) ",
  //       describe:"THAP is an app for consultation with a wellness coach via teleconsultation. It has inbuilt assessment tests, personalized plans with exercises and diet recommendations.  ",
  //       keyfact:"key focus :",
  //       focus:"Data privacy and secure video teleconsultation with chat functionality  ",
  //       ceo:{
  //         title: "Technologies:",
         
  //         image: img.thechn
  //       }
  //     },
  //     {
  //       // tname:"Recent Projects",
  //       title:"THEA (Breast Pumping Tracker App) ",
  //       describe:"THEA offers features like recording pumping sessions and collected milk, option to handle data online and offline for up to 36 hours  ",
  //       keyfact:"Key Impact:",
  //       focus:"Easy Offline tracking & Increased purchase of breast pumps  ",
  //       ceo:{
  //         title: "Technologies:",
         
  //         image: img.jsre
  //       }
  //     },
  //     {
  //       // tname:"Recent Projects",
  //       title:"PeriodPal by LAIQA ",
  //       describe:"PeriodPal provides women an easy period tracking mobile platform. They can also track moods, sleeping habits, and water intake. The app also provides teleconsultations and chat support on demand. It also has an in-app shop for menstrual wellness products.",
  //       keyfact:"key focus :",
  //       focus:"Performance, teleconsultation capabilities, and a smooth onboarding experience ",
  //       ceo:{
  //         title: "Technologies:",
         
  //         image: img.perio
  //       }
  //     }
  //   ],
  //   drop3:[
  //     {
  //       // tname:"Recent Projects",
  //       title:"The Happiness Project (THAP) ",
  //       describe:"THAP is an app for consultation with a wellness coach via teleconsultation. It has inbuilt assessment tests, personalized plans with exercises and diet recommendations.  ",
  //       keyfact:"key focus :",
  //       focus:"Data privacy and secure video teleconsultation with chat functionality  ",
  //       ceo:{
  //         title: "Technologies:",
         
  //         image: img.thechn
  //       }
  //     },
  //     {
  //       // tname:"Recent Projects",
  //       title:"THEA (Breast Pumping Tracker App) ",
  //       describe:"THEA offers features like recording pumping sessions and collected milk, option to handle data online and offline for up to 36 hours  ",
  //       keyfact:"Key Impact:",
  //       focus:"Easy Offline tracking & Increased purchase of breast pumps  ",
  //       ceo:{
  //         title: "Technologies:",
         
  //         image: img.jsre
  //       }
  //     },
  //     {
  //       // tname:"Recent Projects",
  //       title:"PeriodPal by LAIQA ",
  //       describe:"PeriodPal provides women an easy period tracking mobile platform. They can also track moods, sleeping habits, and water intake. The app also provides teleconsultations and chat support on demand. It also has an in-app shop for menstrual wellness products.",
  //       keyfact:"key focus :",
  //       focus:"Performance, teleconsultation capabilities, and a smooth onboarding experience ",
  //       ceo:{
  //         title: "Technologies:",
         
  //         image: img.perio
  //       }
  //     }
  //   ]
  // }
  return (
    <div className='prdCeo'>
      {data?.map((item,index)=>(
         <React.Fragment key={index}>
      
          <ProductDesc item={item} index={index} /> 
          <CeoCard item={item} index={index} />
        </React.Fragment>
       ))}
    </div>
  )
}

export default MobileAnimations