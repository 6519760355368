// src/components/Service/ServiceBan.js
import React from 'react';
import "../../assests/css/serBan.css";

const ServiceBan = ({ bann }) => {
    return (
        <div className='aboutBannersection'>
            {bann.map((item, index) => (
                <div key={index} className='aboutBanMain'>
                    <img className='aboutImgBan' src={item.img} alt="banner-img" />
                    <div className='bannerContent'>
                        <h1>{item.head}<br/>{item.headone}</h1>
                        <p>{item.serpara}</p>
                        {/* <button className="bannerButton">Know More</button> */}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ServiceBan;
