import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assests/css/GameGallery.css';
import LazyLoad from 'react-lazyload';

const SliderComponent = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const videoRefs = useRef([]); // Array to store references to video elements

  const settings = {
    centerMode: true,
    centerPadding: '0px', // Remove padding so only 3 slides show fully
    slidesToShow: 3,
    focusOnSelect: true,
    dots: true,
    infinite: true,
    beforeChange: (oldIndex, newIndex) => {
      // Pause the current video when switching slides
      if (videoRefs.current[oldIndex]) {
        videoRefs.current[oldIndex].pause(); // Pause the video on the old slide
      }
    },
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px', // Ensure no padding on smaller screens too
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1,
        },
      },
    ],
  };

  const videos = [
    {
      videoUrl: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/videos/alien_rescue_saga.mp4',
      thumbnail: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/thumbnails/alien_rescue_saga.png',
    },
    {
      videoUrl: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/videos/arjunas_arc.mp4',
      thumbnail: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/thumbnails/arjunas_arc.png',
    },
    {
      videoUrl: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/videos/ccfc_stars.mp4',
      thumbnail: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/thumbnails/ccfc_stars.png',
    },
    {
      videoUrl: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/videos/ccfc_vr.mp4',
      thumbnail: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/thumbnails/ccfc_vr.png',
    },
    {
      videoUrl: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/videos/defence_of_the_wild.mp4',
      thumbnail: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/thumbnails/defence_of_the_wild.png',
    },
    {
      videoUrl: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/videos/indrajith.mp4',
      thumbnail: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/thumbnails/indrajith.png',
    },
    {
      videoUrl: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/videos/kangeyam.mp4',
      thumbnail: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/thumbnails/kangeyam.png',
    },
    {
      videoUrl: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/videos/mix_mash.mp4',
      thumbnail: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/thumbnails/mix_mash.png',
    },
    {
      videoUrl: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/videos/strike_force.mp4',
      thumbnail: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/thumbnails/strike_force.png',
    },
    {
      videoUrl: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/videos/word_clan.mp4',
      thumbnail: 'https://d1v7saaejtesdp.cloudfront.net/youtube-videos/thumbnails/word_clan.png',
    },
  ];

  return (
    <div className="container game-gallery mb-3">
      <h1>Game Gallery</h1>
      <Slider {...settings} className="center">
        {videos.map((video, index) => (
          <div key={index} className="slide">
            {index === currentSlide ? (
                  <LazyLoad height={315} offset={100}>
              <video
                ref={(el) => (videoRefs.current[index] = el)} // Store video refs
                width="100%"
                height="315"
                controls={index === currentSlide} // Only show controls on the current slide
                preload="none"
                poster={video.thumbnail} // Set the video thumbnail
                autoPlay={false} // Only autoplay the video on the current (zoomed) slide
                muted={index !== currentSlide} // Mute all other videos
              >
                <source src={video.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              </LazyLoad>
            ) : (
              <img
                src={video.thumbnail}
                alt={`Thumbnail for ${video.videoUrl}`}
                width="100%"
                height="315"
                className='sli-img'
              />
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderComponent;
