import React from 'react';
import '../../assests/css/BlogInner.css';

const blogContent = [
    {
        title: 'KARTOFFEL - VRIL PRIVACY POLICY',
        paragraphs: [
            { text: "INTRODUCTION ", className: "extra-font" },
            { text: "Kartoffel or RAM develops and publishes mobile apps, web applications, website and social games for web and mobile devices. “Kartoffel,” “we,” “our,” or “us” or “RAM” in this Privacy Policy refers to Kartoffel and any other corporate entities under common ownership with Kartoffel including without limitation Vril Interactive brand name" },
            { text: "For purposes of data protection laws, wherever you are in the world, Kartoffel is the “data controller” of your personal information." },
            { text: "This Privacy Policy applies whenever you use our “Services,” which means our mobile app, website, web applications, games, products, services, content, Kartoffel.com, and/or other domains or websites operated by RAM. It describes how we collect, store, use, disclose, and otherwise process your personal information when providing these Services to you, and your rights and choices concerning your information. The Privacy Policy’s sections are listed and hyperlinked in the Table of Contents above for easier navigation." },
            { text: "If you do not want RAM to collect, store, use, or share your information in the ways described in this Privacy Policy, you may not use any of our “Services” listed above." },
            { text: "INFORMATION WE COLLECT AND HOW WE COLLECT IT", className: "extra-font" },
            { text: "Information about you that you share with us directly" },
            { text: "When you use our Services, you may give us information directly (like when you’re setting up your account), and we’ll store that information on our systems and use it for the purposes described in this Privacy Policy." },
            { text: "Some mobile apps, websites, web apps, or games or parts of our Services may use a more traditional registration or account set-up process where you may be asked to give us information like:" },
            { text: "-> your first and last name" },
            { text: "-> your username" },
            { text: "-> your gender" },
            { text: "-> your age or birthday" },
            { text: "-> your e-mail address" },
            { text: "-> a password; and/or" },
            { text: "-> other information that helps us to identify you or helps us to provide or improve our services. We may also let you create a player profile that other players can see. Your player profile may include information like:" },
            { text: "-> your profile photo" },
            { text: "-> your first and last name" },
            { text: "-> your game username" },
            { text: "-> your gender" },
            { text: "-> your biographic details (like your age or age range)" },
            { text: "INFORMATION ABOUT YOU THAT WE GET FROM CONNECTED THIRD-PARTY APPLICATIONS, INCLUDING SOCIAL NETWORKS", className: "extra-font" },
            { text: "If you consume any of our Services on connected third-party applications or connect our Services to any third-party applications, including social networks like Facebook, we may receive certain information about you from the provider of the third-party application. The information we receive depends on the service that you consume, the third-party application, your privacy settings, and, if applicable, your friends’ privacy settings on that third-party application." },
            { text: "For example, we may collect and store some or all of the following information from the provider of the connected third-party application:" },
            { text: "-> your first and last name" },
            { text: "-> your profile picture or its URL" },
            { text: "-> your user identification number (like your Facebook ID number), which may be linked to publicly-available information like your name and profile photo" },
            { text: "-> your friends’ user ID numbers and other public data" },
            { text: "-> the e-mail address you provided to that third-party application" },
            { text: "-> your approximate physical location and that of the devices you use to access our Services" },
            { text: "-> your gender" },
            { text: "-> your birthday, age, and/or age range" },
            { text: "-> information about your activities on or through the connected third-party application" },
            { text: "-> other publicly-available information on the third-party application; and/or" },
            { text: "-> any other information that you or the provider of the third-party application share with RAM" },
            { text: "If you access our Services from a third-party application or connect our Services to a third-party application, you should also read that third-party application’s terms of service and privacy policy." },
            { text: "If you are unclear about what information a third-party application is sharing with us, please go to the third-party application to find out more about their privacy practices." },
            { text: "HOW WE USE THE INFORMATION WE COLLECT", className: "extra-font" },
            { text: "We use your information under several legal bases, including to establish and perform our contract with you, for our legitimate interests, to comply with our legal obligations, to establish, use and protect our rights, or otherwise with your consent." },
            { text: "The main use of the information we collect and store is to provide you with the Services you request from us and to improve your user experience. The uses for which we collect and store your information include:" },
            { text: "-> to improve and optimize our Services and our users experiences" },
            { text: "-> to create your user accounts and allow you to use our services" },
            { text: "-> to identify and suggest connections with other users and personalize our Services to you" },
            { text: "-> to communicate with you about the Services you’re using, including in-app and in-game updates, new Services, and promotional offers that we think might be of interest to you" },
            { text: "-> to enable players to communicate with each other" },
            { text: "-> to provide technical support and respond to player inquiries" },
            { text: "-> to protect the safety and well-being of our players" },
            { text: "-> to protect our rights and property in connection with our Services" },
            { text: "-> to prevent fraud or potentially illegal activities" },
            { text: "-> to manage and deliver contextual and behavioral advertising" },
            { text: "-> to administer rewards, surveys, sweepstakes, contests, or other promotional activities or events sponsored or managed by us or our business partners" },
            { text: "-> to comply with our legal obligations, resolve any disputes we may have with you or other players, and to enforce our agreements with third parties; and/or" },
            { text: "-> to conduct research" },
            { text: "However, we’ll normally collect information from you only where we need it to perform our contract with you (i.e., our Terms of Service)," },
            { text: "where the processing is in our legitimate interests (provided that these aren’t overridden by your interests or rights), or if we otherwise have your consent." },
            { text: "CHILDREN SPECIFIC POLICY", className: "extra-font" },
            { text: "Most of our Services are not intended for children under the age of 13 (or under 16 for children located in the European Countries) (collectively, “Children”). Children are not permitted to use most of our Services, and we do not knowingly collect any personal information from Children." },
            { text: "Though our Services do not target Children as the primary audience, we may collect age information before allowing a user to proceed for certain Services." },
            { text: "In some services, fore.g.: games, we create relevant and learning content for Children. For users who identify themselves as Children in our age-gate on such Services, if we allow Children to use the Services, we will either provide a version of that Service that does not collect, use, or disclose “personal information”." },
            { text: "If we learn that we have inadvertently gathered personal information that is not subject to the law, we will take reasonable measures to promptly remove that information from our records. At no point will the information collected be shared with any third party or internal stakeholders." },
            { text: "YOUR RIGHTS AND ACCESS TO INFORMATION", className: "extra-font" },
            { text: "General information to all countries’ Users’ Rights", className: "extra-font" },
            { text: "We believe that our end user has all the rights and should have all access to any and all information on them when they consume our services. We will comply with their wishes as well as the jurisdictional law of the country that they are from and are accessing any of our services from. At the same time, certain data might have to be collected to protect our rights and our compliance with the laws of the region as well." },
            { text: "European Countries’ Users’ Rights", className: "extra-font" },
            { text: "If you are located in the European Countries, you have the following data protection rights:" },
            { text: "-> You can access or request deletion of your personal information by submitting a request through our Contact form on our website" },
            { text: "-> You can correct or update your personal information, object to processing of your personal information, ask us to restrict processing of your personal information or request portability of your personal information." },
            { text: "-> If we have collected and processed your personal information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent." },
            { text: "-> You have the right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority" },
            { text: "-> To exercise your rights to access or deletion, please submit a request through our contact form If you have any problems with any of the services or would like to exercise any of your other rights, you can contact us. . We respond to all data protection requests we receive in accordance with applicable data protection laws." },
            { text: "Accessing and Deleting Personal Information Held by RAM", className: "extra-font" },
            { text: "To review and update personal information associated with your user profile in any of our services, visit the “settings” or “my profile” page in that service." },
            { text: "If you want to review or delete personal information associated with your game account data, please submit a request through our contact form." },
            { text: "For deletion requests, we will take reasonable measures to delete your personal information from our records. If you have sent content through or posted content on the Service, we may not be able to delete it. We will keep certain records (e.g., personal information relating to payments or customer service matters) where we need (and have rights) to, such as for legal or accounting purposes. We’ll also keep information in order to exercise, defend, or establish our rights." },
            { text: "INFORMATION SECURITY", className: "extra-font" },
            { text: "We implement reasonable and appropriate security measures to help protect the security of your information both online and offline and to ensure that your data is treated securely and in accordance with this Privacy Policy. These measures vary based upon the sensitivity of your information." },
            { text: "If you have an account registered directly with any of our service , your profile information is protected by the password you use to access your account. It is important that you protect and maintain your account’s security and that you immediately tell us of any unauthorized use of your account." },
            { text: "If you forget the password to your account, the Services allow you to request that instructions be sent to you that explain how to reset your password. So, be careful to keep your social network account information, including your social network account password, secure as well. We urge you to log out of your account and any social network account you have used to access our Services when you are finished using them." },
            { text: "When you sign into your account or enter payment information, we and our third-party payment processor use encryption to transmit that information." },
            { text: "While we take precautions against possible security breaches of our Services and our customer databases and records, no website or Internet transmission is completely secure. We cannot guarantee that unauthorized access, hacking, data loss, or other breaches will never occur, and we cannot guarantee the security of your information while it is being transmitted to our Service. Any transmission is at your own risk. If you have questions about the security of our websites, please contact us." },






            { text: "In Conclusion: Tailoring the Approach to Your Needs", className: "extra-font" },
            { text: "The ideal approach hinges on your project's specific requirements. Consider these factors:" },
            { text: "-> Target audience: Who are you building the app for? If cross-platform reach is crucial, hybrid might be ideal." },
            { text: "-> Budget and timeline: Hybrid development can be faster and more cost-effective." },
            { text: "-> App complexity: For demanding functionalities, native development might be necessary for optimal performance." },
            { text: "By carefully evaluating these factors, you'll be well-equipped to choose the development path that empowers your mobile app to thrive. Remember, the goal is to prioritize user experience, performance, and scalability to ensure your app's success." },
        ]
    }
];

const PrivacyPolicy = () => {
    const { title, paragraphs } = blogContent[0];

    return (
        <div className="blog-post p-0">
            <div className='blog-con '>
                <div className="blog-header mt-5">
                    <p className="bloginner-title mb-4">{title}</p>
                </div>
                <div className="blog-content">
                    {paragraphs.map((paragraph, index) => (
                        <p key={index} className={paragraph.className || ''}>
                            {paragraph.text}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
