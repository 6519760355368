// AuditBanner.js
import React from 'react';
import '../../assests/css/AuditKartoffel.css'
import Images from "../../Images/Images"

const features = [
    {
        icon: Images.expert,
        title: "Expert Analysis",
        description: "Our team brings years of experience and specialized knowledge to provide an in-depth understanding of your product's strengths and weaknesses."
    },
    {
        icon: Images.tailored,
        title: "Tailored Solutions",
        description: "We create custom audits to address your specific needs, offering targeted recommendations to meet your business goals."
    },
    {
        icon: Images.actionable,
        title: "Actionable Insights",
        description: "Our detailed reports provide clear, actionable insights to guide your decision-making and improve your product's performance."
    },
    {
        icon: Images.optimise,
        title: "Optimize your Product",
        description: "Our detailed reports provide clear, actionable insights to guide your decision-making and improve your product's performance."
    }
];

const AuditBanner = () => {
    return (
        <div className="audit-banner">
            <p className="audit-title">Comprehensive Audit with Kartoffel</p>
            <p className='audit-subheading'>Why should Kartoffel Technologies be your Auditing Consultant for your product/service?</p>
            <p className="audit-description">
                Using our team of experienced analysts, uncover hidden strengths and areas for improvement in your product with a comprehensive audit by Kartoffel Technologies.
            </p>
            <div className="row">
                {features.map((feature, index) => (
                    <div key={index} className="col-lg-3 col-md-3 col-sm-12 feature">
                        <img src={feature.icon} alt={feature.title} />
                        <p className='icon-subheading'>{feature.title}</p>
                        <p className='icon-para'>{feature.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AuditBanner;
