import React from 'react';
import '../../assests/css/Cusp.css';
import Images from '../../Images/Images';

// Data array for tab content
const tabContents = [
  {
    id: 'Consumerization',
    image: Images.Consumerization,
    alt: 'Consumerization Icon',
    definition: 'This involves understanding the client’s business and identifying the quickest and most effective way to scale their operations and achieve market fit through technology and digital tools. The focus is on tailoring products and services to meet the evolving needs of end consumers.',
    impact: 'Businesses that implement consumerization effectively can achieve faster time-to-market, enhanced customer satisfaction, and increased brand loyalty.',
  },
  {
    id: 'Uberization',
    image: Images.Uberization,
    alt: 'Uberization Icon',
    definition: 'Uberization refers to making an existing business model more scalable and accessible to a broader audience through technology. It involves creating a platform-based approach that allows businesses to offer their services on-demand, thereby democratizing service delivery.',
    impact: 'This strategy can significantly increase market reach, improve customer acquisition rates, and allow businesses to rapidly scale operations.',
  },
  {
    id: 'Servitization',
    image: Images.Servitization,
    alt: 'Servitization Icon',
    definition: 'Servitization is the process of transforming products into services to generate recurring revenue and foster long-term customer relationships. It shifts the focus from one-time product sales to ongoing service engagements.',
    impact: 'This transformation leads to consistent revenue streams, improved customer retention, and stronger client relationships.',
  },
  {
    id: 'Productization',
    image: Images.Productization,
    alt: 'Productization Icon',
    definition: 'Productization refers to converting services into standardized, marketable products. This allows businesses to package their expertise and deliver it as a product that can be easily marketed and sold.',
    impact: 'Productization enables businesses to scale more efficiently, open up new revenue streams, and reduce dependency on labor-intensive services.',
  },
];

const CuspTabsContent = () => {
  return (
    <div className="container tabsContentss">
      {tabContents.map((tab, index) => (
        <div key={index} className={`tabs${index + 1}`} id={tab.id}>
          <div className="row allc">
            {/* Image Section */}
            <div className="col-lg-4 col-md-12 image-container">
              <img src={tab.image} alt={tab.alt} className="content-image" />
            </div>
            
            {/* Text Content Section */}
            <div className="col-lg-8 col-md-12 content-textcuspos">
              <h3 className='def'>Definition</h3>
              <ul>
                <li className='def'>{tab.definition}</li>
              </ul>

              <h3 className='def'>Impact</h3>
              <ul>
                <li className='def'>{tab.impact}</li>
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CuspTabsContent;
