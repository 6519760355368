import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assests/css/TeamSection.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Images from "../../Images/Images";
import LazyLoad from 'react-lazyload';

const teamMembers = [
    { id: 1, src: Images.memone, alt: 'Member 1' },
    { id: 2, src: Images.memtwo, alt: 'Member 2' },
    { id: 3, src: Images.memthree, alt: 'Member 3' },
    { id: 4, src: Images.memfour, alt: 'Member 4' },
    { id: 5, src: Images.memfive, alt: 'Member 5' },
    { id: 6, src: Images.memsix, alt: 'Member 6' },
    { id: 7, src: Images.memseven, alt: 'Member 7' },
    { id: 8, src: Images.memeight, alt: 'Member 8' },
    { id: 9, src: Images.memnine, alt: 'Member 9' },
    { id: 10, src: Images.memten, alt: 'Member 10' },
   
   
    { id: 11, src: Images.memthreeone, alt: 'Member 10' },
    // { id: 32, src: Images.memthreetwo, alt: 'Member 10' },
    { id: 12, src: Images.memthreethree, alt: 'Member 10' },
    

];
const teamMember = [
    { id: 1, src: Images.memtenone, alt: 'Member 10' },
    { id: 2, src: Images.memtentwo, alt: 'Member 10' },
    { id: 3, src: Images.memtenthree, alt: 'Member 10' },
    { id: 4, src: Images.memtenfour, alt: 'Member 10' },
    { id: 5, src: Images.memtenfive, alt: 'Member 10' },
    { id: 6, src: Images.memtensix, alt: 'Member 10' },
    { id: 7, src: Images.memtenseven, alt: 'Member 10' },
    { id: 8, src: Images.memteneight, alt: 'Member 10' },
    { id: 9, src: Images.memtennine, alt: 'Member 10' },
    { id: 10, src: Images.memtenten, alt: 'Member 10' },
    { id: 11, src: Images.memthreefour, alt: 'Member 10' },
    { id: 12, src: Images.memthreefive, alt: 'Member 10' },
];

const team = [
    { id: 1, src: Images.memtwoone, alt: 'Member 10' },
    { id: 2, src: Images.memtwotwo, alt: 'Member 10' },
    { id: 3, src: Images.memtwothree, alt: 'Member 10' },
    { id: 4, src: Images.memtwofour, alt: 'Member 10' },
    { id: 5, src: Images.memtwofive, alt: 'Member 10' },
    { id: 6, src: Images.memtwosix, alt: 'Member 10' },
    { id: 7, src: Images.memtwoseven, alt: 'Member 10' },
    { id: 8, src: Images.memtwoeight, alt: 'Member 10' },
    { id: 9, src: Images.memtwonine, alt: 'Member 10' },
    { id: 10, src: Images.memtwoten, alt: 'Member 10' },
    { id: 11, src: Images.memtensix, alt: 'Member 10' },
    { id: 12, src: Images.memeight, alt: 'Member 8' },
];

const TeamSection = () => {
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 767) {
                window.location.reload();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settingsUpward = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        arrows: false,
        touchMove: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        pauseOnDotsHover: false,
        responsive: [
            // Add responsive settings if needed
        ]
    };

    const settingsDownward = {
        dots: false,
        infinite: true,
        speed: 5000,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        autoplaySpeed: 0,
        cssEase: 'linear',
        arrows: false,
        touchMove: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        pauseOnDotsHover: false,
        // initialSlide: teamMembers.length - 1,
        rtl: true,
        responsive: [
            // Add responsive settings if needed
        ]
    };

    const getBorderClass = (index) => {
        if (index % 3 === 0) {
            return 'border-style-one';
        } else if (index % 3 === 1) {
            return 'border-style-two';
        } else {
            return 'border-style-three';
        }
    };
    const getBorderClassone = (index) => {
        if (index % 3 === 0) {
            return 'border-style-one';
        } else if (index % 3 === 1) {
            return 'border-style-two';
        } else {
            return 'border-style-three';
        }
    };
    const getBorderClasstwo = (index) => {
        if (index % 3 === 0) {
            return 'border-style-one';
        } else if (index % 3 === 1) {
            return 'border-style-two';
        } else {
            return 'border-style-three';
        }
    };

    return (
        <div className="team-section about-heading">
            <div className="container">
                <div className="row align-items-center about-left">
                    <div className="col-lg-6 col-md-6 col-xs-12">
                        <p className="text-left-head">OUR TEAM</p>
                        <p className="text-left-subheading">
                        Meet the heart and soul of Kartoffel Technologies,<br />
                        our team. We're a community of passionate professionals who love what we do.,
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 about-slide">
                        <div className='slide-first'>
                            <Slider {...settingsUpward}>
                                {teamMembers.map((member, index) => (
                            //   <LazyLoad key={member.id} height={200} offset={100} once>
                                    <div key={member.id} className={`team-member ${index === 1 ? 'move-up' : index === 2 ? 'move-down' : ''}`}>
                                        <img src={member.src} alt={member.alt} className={`img-fluid ${getBorderClass(index)}`} />
                                    </div>
                                    //  </LazyLoad>  
                                ))}
                        </Slider>
                    </div>
                    <div className='slide-second'>
                        <Slider {...settingsDownward}>
                            {teamMember.map((member, index) => (
                            //    <LazyLoad height={200} offset={100} once>
                                <div key={member.id} className={`team-member ${index === 1 ? 'move-up' : index === 2 ? 'move-down' : ''}`}>
                                    <img src={member.src} alt={member.alt} className={`img-fluid ${getBorderClassone(index)}`} />
                                </div>
                                // </LazyLoad>
                            ))}
                        </Slider>
                    </div>
                    <div className='slide-third'>
                        <Slider {...settingsUpward}>
                            {team.map((member, index) => (
                            //    <LazyLoad height={200} offset={100} once>
                                <div key={member.id} className={`team-member ${index === 1 ? 'move-up' : index === 2 ? 'move-down' : ''}`}>
                                    <img src={member.src} alt={member.alt} className={`img-fluid ${getBorderClasstwo(index)}`} />
                                </div>
                                //  </LazyLoad>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
        </div >
    );
};

export default TeamSection;
