import React, { useEffect, useState } from 'react'
import "../../assests/css/Common.css"
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import Footer from '../../commonComponents/footer/footer'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import TermsAndCondition from './TermsAndCondition'


const TermsPage = () => {

  return (
    <div className='Blogswhole'>
      <NavbarHeader />
      
      <TermsAndCondition/>
      <GetInTouch/>
      <Footer />
    </div>
  )
}

export default TermsPage