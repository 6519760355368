// src/components/Service/sliderData.js
import Images from "../../Images/Images";

const ProductService = [
    {
        number: "01",
        title: "Concept-to-Market Management",
        description: `We oversee the entire product development journey, ensuring a smooth progression from concept to market. Our structured approach minimizes disruptions and accelerates time-to-market.`,
        image: Images.andIdeation
    },
    {
        number: "02",
        title: "Design Thinking Approach",
        description: `By employing design thinking, we create unique and standout products that resonate with users. This approach helps us deliver innovative solutions tailored to customer needs.`,
        image: Images.ConceptCreation
    },
    {
        number: "03",
        title: "Trend Responsiveness",
        description: `We stay ahead of market trends and evolving demands, adapting our strategies to keep products relevant and competitive. Our agility allows us to pivot quickly in response to industry changes.`,
        image: Images.VisualanInteractioDesign
    },
    {
        number: "04",
        title: "Cross-Disciplinary Collaboration",
        description: `Our development process involves cross-disciplinary teamwork to enhance product quality. By leveraging diverse expertise, we ensure that every product is robust and well-rounded.`,
        image: Images.DevelopmenanImplementation
    },
    {
        number: "05",
        title: "Prototyping and Testing",
        description: `We prioritize early prototyping and testing to identify potential issues before they escalate. This proactive approach helps us refine products and meet user needs efficiently.`,
        image: Images.innphicconcept
    }
];
const bann = [
    {
        img: Images.newproductban,
        head: "PRODUCT DEVELOPMENT",
        // headone: "MANAGEMENT",
        serpara: "Collaborate to create impactful products with our offerings.",
    }
];
const service = [
    {
        title: "New Product Feasibility Assessment",
        description: "Transforming your vision into a winning product. We generate innovative ideas, craft user-centric designs, and a roadmap that aligns with your business objectives.",
        image: Images.IdeatiaSolutioDesign,
        url :"/new-product-development"
    },
    {
        title: "MVP Development",
        description: "We specialize in rapid prototyping, iterative development, and user feedback integration to help you launch a minimal viable product that resonates with your target audience.",
        image: Images.MVDesigavelopment,
        url :"/mvp-development"
    },
    {
        title: "Product Roadmap Development",
        description: "Our comprehensive new product development services cover every step of the journey. We offer end-to-end support, including market research, concept validation, design, development, and launch strategies.",
        image: Images.IdetMarket,
        url :"/product-roadmap"
    },
    // {
    //     title: "Consulting and Market Study",
    //     description: "Leverage our expertise to uncover market trends, analyze competition, and identify lucrative opportunities for your new product.",
    //     image: Images.Consuland
    // },
];
const faqs = [
    {
        question: "What can you help me with?",
        answer: "We handle the entire product development lifecycle, from concept, design, development, testing, and deployment. We also provide ongoing maintenance and support to ensure your product's long-term success."
    },
    {
        question: "How do I create a product with you?",
        answer: "Contact us with your idea via our online form, email, or phone. We'll set up a meeting to discuss your vision in detail. Providing as much information as possible will help streamline the process. If you need help getting started, our blog has resources on how to write a project brief."
    },
    {
        question: "How do you ensure product quality?",
        answer: "We conduct rigorous testing, including functional, performance, and security tests. We also gather user feedback during development to make necessary adjustments and improvements."
    },
    {
        question: "Will you deliver on timeline?",
        answer: "We provide an estimated timeline based on the project scope and budget. We continuously update you on progress and ensure timely delivery through iterative development and feedback cycles."
    },
    {
        question: "Can the product be upgraded in the future?",
        answer: "Enhancement and time-to-time migration are the two most important activities for any software product. We can work with you to improve, upgrade, and further develop your product as new technology becomes available. We ensure your product remains up-to-date and competitive in the market."
    }
];
const listItems = [
    {
        alt: "Specialized Expertise",
        text: "Manage end-to-end development from ideation to post-launch.",
    },
    {
        alt: "Specialized Expertise",
        text: "Drive cutting-edge features across mobile and web platforms.",
    },
    {
        alt: "Specialized Expertise",
        text: "Ensure high standards through rigorous testing and industry compliance.",
    },
    {
        alt: "Specialized Expertise",
        text: "Enhance products with collaborative teamwork across disciplines.",
    },
    {
        alt: "Specialized Expertise",
        text: "Align efforts with business goals, optimizing resources and time.",
    },
    {
        alt: "Specialized Expertise",
        text: "Develop scalable and customizable products for growth and adaptation.",
    },
    {
        alt: "Specialized Expertise",
        text: "Proactively identify and address risks to minimize disruptions.",
    },
    {
        alt: "Specialized Expertise",
        text: "Continuously refine and update products to stay relevant and competitive.",
    },
];

export { ProductService, bann, service,faqs,listItems };
