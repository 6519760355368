import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../../assests/css/Testimony.css';
import Images from "../../Images/Images"

const TestimonialCarousel = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const indicatorStyles = {
    background: '#ccc',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0 8px',
  };

  return (
    <div className="testimonial-carousel">
      <Carousel
        showThumbs={false}
        showIndicators={true}
        showStatus={false}
        infiniteLoop={true}
        centerMode={true}
        centerSlidePercentage={isMobile ? 100 : 60}
        autoPlay={true}             // Enable auto-scroll
        interval={3000}            // Set auto-scroll interval to 10 seconds
        stopOnHover={true}          // Stops scrolling on hover
        showArrows={false}
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <li
            style={{ ...indicatorStyles, background: isSelected ? '#ff9233' : '#ccc' }}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            value={index}
            key={index}
            role="button"
            tabIndex={0}
            aria-label={`${label} ${index + 1}`}
          />
        )}
      >
        <div className="testimonial-slide">
          <div className="testimonial-contents">
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/25jT2ypB9M8?si=BRwjJKzjCt6oaoP1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="testimonial-slide">
          <div className="testimonial-content">
            <img src={Images.drarul} alt="Dr. Arul, Founder" className="testimonial-image" />
            <h3 className="testimonial-name">Dr. Arul, Founder</h3>
            <p className="testimonial-position">Sahil School of Sociology</p>
            <p className="testimonial-text">Kartoffel team provided us with a clear plan as to how the design should be, how the process should be, all the way up to launch of the website and the app and how it must be from an end user perspective. It was clean and simple.</p>
            <div className="testimonial-quote">
              <img src={Images.quotes} alt="quote" className="quote-image" />
            </div>
          </div>
        </div>

        <div className="testimonial-slide">
          <div className="testimonial-content">
            <img src={Images.jaya} alt="Jayendran N" className="testimonial-image" />
            <h3 className="testimonial-name">Jayendran N</h3>
            <p className="testimonial-position">Chief Technology Officer, ProConnect Supply Chain Solutions Ltd</p>
            <p className="testimonial-text">We have been availing the services of Kartoffel Technologies since 2022 in the areas of Development, Enhancement, and Maintenance of our web applications, particularly the intranet portal and business portal. They have been offering very Reliable, Consistent, and Quality Services in all required areas.</p>
            <div className="testimonial-quote">
              <img src={Images.quotes} alt="quote" className="quote-image" />
            </div>
          </div>
        </div>

        <div className="testimonial-slide">
          <div className="testimonial-content">
            <img src={Images.karthi} alt="Karthik Iyer" className="testimonial-image" />
            <h3 className="testimonial-name">Karthik Iyer</h3>
            <p className="testimonial-position">Product Manager, THAP</p>
            <p className="testimonial-text">Kartoffel team provided us with a clear plan as to how the design should be, how the process should be, all the way up to launch of the website and the app and how it must be from an end user perspective. It was clean and simple.</p>
            <div className="testimonial-quote">
              <img src={Images.quotes} alt="quote" className="quote-image" />
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default TestimonialCarousel;
