import Images from "../../Images/Images";

export const DataURL = {
    "mobile-app-development": {
        cards: [
            {
                title: 'UI/UX Development:',
                text: 'We create aesthetically pleasing and high-quality apps that deliver seamless experiences. We combine critical insights with technical expertise to launch mobile apps that resonate with your target audience.',
                imgSrc: Images.mobileone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Cross-Platform/Hybrid  Development – React Native:',
                text: 'Our team specializes in building high-quality mobile apps for both Android and iOS with a single codebase using React Native. The benefit of doing so, you save time and resources while delivering robust and scalable applications. Write once, deploy everywhere! ​',
                imgSrc: Images.mobiletwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'AppStore Optimization:',
                text: ` We help enhance your app's visibility and downloads with expert AppStore Optimization (ASO) strategies. Our team specializes in optimizing apps to maximize visibility on platforms, ensuring increased downloads and user engagement. `,
                imgSrc: Images.mobilethree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Native App Development – Android:',
                text: ' We develop top performing Android apps with Java and Kotlin, optimized to harness the unique capabilities of Android devices. This approach guarantees a smooth and sleek user experience, specifically for the Android environment.​',
                imgSrc: Images.mobilefour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            },
            {
                title: 'QA and Testing: ',
                text: 'We conduct comprehensive testing to ensure your app performs flawlessly across all scenarios. This approach guarantees high quality results and reliability which is what we work towards. ​',
                imgSrc: Images.mobilefive,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Native App Development – iOS:',
                text: ' We build high-quality iOS apps using Swift and Objective-C, tailored to leverage the unique features of Apple devices. This ensures a high-performance user experience while delivering robust and scalable applications. Designed specifically for the Apple ecosystem!​',
                imgSrc: Images.mobilesix,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            }
        ],
        bannerContent: {
            title: "How can Kartoffel Technologies enhance your mobile app development?",
            description: "We offer comprehensive mobile app development to help your business stand out, engage users and generate revenue. Our developers are skilled in meeting your application requirements within predefined timelines.​",
            buttonText: "Request Call Back",
            imageSrc: Images.mobileban,
            imageAlt: "Consultation"
        },
         meta_description:"Transform your ideas into groundbreaking digital Mobile Applications with our agile development process, enabling rapid iterations and delivery.",
        meta_title:"Mobile App Development Company",
    }
}
export const WebappURL = {
    "web-app-development": {
        cards: [
            {
                title: 'Custom  Web App Development:',
                text: 'Our custom web application development services follow an agile methodology, transforming concepts into fully customized solutions that enhance user engagement.​',
                imgSrc: Images.webone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'SaaS Web App Development:',
                text: 'Take advantage of Software as a Service (SaaS) with our specialized development services, offering scalable and accessible web applications for long-term success.',
                imgSrc: Images.webtwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Custom CMS Development:',
                text: ` Gain control over your content with our custom CMS development services, providing flexibility and ease of use tailored to your unique requirements.`,
                imgSrc: Images.webthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'E-Commerce Development​:',
                text: 'Boost your online business with our e-commerce web application expertise, creating powerful sales platforms that enhance user experiences and increase revenue.',
                imgSrc: Images.webfour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            },
            {
                title: 'Web Portal Development: ',
                text: 'Empower your business with feature-rich web portals, expertly built to facilitate seamless interactions and engagement with your target audience.',
                imgSrc: Images.webfive,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            }, {
                title: 'Web App Support and Maintenance:',
                text: ' Ensure peak performance of your web applications with our dedicated support and maintenance services, offering proactive monitoring and regular updates for peace of mind.',
                imgSrc: Images.websix,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            }
        ],
        bannerContent: {
            title: "How can Kartoffel Technologies amplify your web app development?",
            description: "At Kartoffel Technologies, we build web applications that mean business. We turn your ideas into powerful digital tools, working closely from start until launch. We guarantee a unique solution that fits your business vision. ",
            buttonText: "Request Call Back",
            imageSrc: Images.webban,
            imageAlt: "Consultation"
        },
        meta_description:"Bring your ideas to life with our agile development process. We quickly turn them into cutting-edge web applications, with rapid iterations and delivery.",
        meta_title:" web app development company",
    }
}
export const ModernizationURL = {
    "modernization-services": {
        cards: [
            {
                title: 'High-Level Transformation Plan:',
                text: 'We begin with an overview of your existing system, identifying shortcomings and planning the solution. We include documenting activities, stages, time, and cost estimations.',
                imgSrc: Images.modernone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'In-Depth Review of Source Systems:',
                text: 'Our experts analyze your current application platform, technology, functionalities, and pain points, resulting in a source system architecture and functionality document.',
                imgSrc: Images.moderntwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Technical Design Document:',
                text: `We define and document the project's desired endpoint in an Architecture Technical Design Document (TDD), which captures the system architecture, required components, and data migration process.​`,
                imgSrc: Images.modernthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Target System Development:',
                text: ' Our team develops the target system architecture and functionality based on the TDD specifications, using the appropriate technology and development practices.​',
                imgSrc: Images.modernfour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            },
            {
                title: 'QA & UAT:',
                text: `We perform rigorous Quality Assurance (QA) and collaborative User Acceptance Testing (UAT) to ensure the modernized application's high quality and validation.​`,
                imgSrc: Images.modernfive,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            }, {
                title: 'Data Migration:',
                text: ' We manage the seamless data migration from the source system to the modernized one, ensuring streamlined data integration and an error-free transition.​',
                imgSrc: Images.modernsix,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            },
            {
                title: 'Support & Maintenance:',
                text: `Post-launch, we provide ongoing support with periodic checks, regular updates, and upgrades to ensure your applications and services run optimally.​`,
                imgSrc: Images.modernseven,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "",
                id: "connect-bottom"

            },
        ],
        bannerContent: {
            title: "How can Kartoffel Technologies transform your legacy applications through modernization services?",
            description: "Kartoffel Technologies offers comprehensive services to modernize your legacy applications, ensuring more effortless scalability, maintenance, and upgrades. We refactor and re-architect legacy applications from monolithic to microservices-based architecture, enabling seamless integration into modern IT ecosystems.​",
            buttonText: "Request Call Back",
            imageSrc: Images.moderizationban,
            imageAlt: "Consultation"
        },
        meta_description:"Explore our app development and maintenance services with app modernization to boost productivity. Unlock new capabilities and keep your apps up-to-date.",
        meta_title:" App Modernization Services & Software Development",
    }
}
export const MaintenanceURL = {
    maintenanceandsupport: {
        cards: [
            {
                title: 'Application Maintenance and Support:',
                text: 'Expand, modify, or enhance your applications. We drive automation, optimize business processes, identify risks, and explore next-gen solutions.​',
                imgSrc: Images.maintanceone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Web Maintenance Services: ',
                text: 'Update websites, run backups, test page speeds, and check security updates to keep your website performing optimally.​',
                imgSrc: Images.maintancetwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Android App Maintenance Services: ',
                text: `With on-demand and ongoing maintenance support, ensure your Android apps are responsive, compliant, and secure.​​`,
                imgSrc: Images.maintancethree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'iOS App Maintenance Services:',
                text: 'Keep your iOS apps updated, active, and error-free. Fix bugs and enhance user-friendliness to stay competitive and boost revenue.​',
                imgSrc: Images.maintancefour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            },
            {
                title: 'Remote IT Support Services: ',
                text: `Get timely technical support to troubleshoot critical issues with our global team of skilled resources available round-the-clock.`,
                imgSrc: Images.maintancefive,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "",
                id: "connect-bottom"

            },
        ],
        bannerContent: {
            title: "How can Kartoffel Technologies assist with software maintenance and support services?",
            description: `Have acquisitions and mergers left you with complex legacy systems and applications? Is your IT department overloaded with mundane software maintenance support? Delegate software maintenance to save on hiring and operational costs. ​Kartoffel Technologies offers dedicated enterprise software maintenance services, allowing you to concentrate on your core business. Our expert software professionals, with over a decade of industry experience, provide effective long-term maintenance and real-time support for all your software needs.`,
            buttonText: "Request Call Back",
            imageSrc: Images.mainban,
            imageAlt: "Consultation"
        },
        meta_description:"Get flexible software maintenance and support for both web and mobile, including ongoing updates, regular security reviews, compliance checks, and SLA-driven support",
        meta_title:" Software Maintenance and Support Services ",
    }
}
export const UIDesignURL = {
    "ui-and-ux-design": {
        cards: [
            {
                title: 'Digital Branding:',
                text: 'Build a robust, cohesive brand identity that resonates with users.',
                imgSrc: Images.designone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'User Experience Design: ',
                text: 'Create seamless and enjoyable user journeys that drive engagement.​',
                imgSrc: Images.designtwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'User Interface Design: ',
                text: `Develop visually appealing and user-friendly interfaces for better interaction.`,
                imgSrc: Images.designthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Interaction Design:',
                text: ` Enhance user engagement with interactive elements that delight users.`,
                imgSrc: Images.designfour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            },
            {
                title: 'Digital Prototyping:',
                text: ' Create functional prototypes to test and refine designs before full development.',
                imgSrc: Images.designfive,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Motion Graphics:',
                text: ` Add dynamic visual elements to make the user experience more engaging.​`,
                imgSrc: Images.designsix,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            }, {
                title: 'Illustrations and Iconography:',
                text: ' Develop unique visual assets to support and enhance your brand.',
                imgSrc: Images.designseven,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "",
                id: "connect-bottom"

            },

        ],
        bannerContent: {
            title: "Why consult Kartoffel Technologies for cutting-edge digital prototyping and UI/UX design?",
            description: "Kartoffel Technologies is a UI/UX design company that uses scalable, futuristic solutions to bring the most creative ideas to life. Our research includes design audits, usability testing, UI research, and UX research, digital branding, interaction design, digital prototyping, motion graphics, and illustrations.",
            buttonText: "Request Call Back",
            imageSrc: Images.uiuxdesignban,
            imageAlt: "Consultation"
        },
        meta_description:"Discover how Kartoffel tech UI and UX design services can transform your digital presence with engaging mobile and web applications. Get started today!",
        meta_title:"UI & UX Design and Consulting Services",
    }
}
export const UIDevelopmentURL = {
    "ui-and-ux-development": {
        cards: [
            {
                title: 'Front-End Development: ',
                text: 'We build responsive and interactive front-end solutions using the latest technologies to ensure a seamless user experience across all devices.​',
                imgSrc: Images.developmentone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Back-End Integration:',
                text: ' Our development team integrates robust back-end systems to support front-end functionality and ensure smooth operations.​​',
                imgSrc: Images.developmenttwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Custom Development: ',
                text: `Tailored development solutions to meet your business needs, ensuring the final product aligns with your goals.`,
                imgSrc: Images.developmentthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Quality Assurance:',
                text: ` Rigorous testing processes ensure the product is bug-free, functional, and provides a smooth user experience.`,
                imgSrc: Images.developmentfour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            },
            {
                title: 'Performance Optimization: ',
                text: 'Enhancing the performance of your applications to ensure fast loading times and efficient operation.	',
                imgSrc: Images.developmentfive,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Security Implementation: ',
                text: `Implementing security best practices to protect your digital products and user data from potential threats.​`,
                imgSrc: Images.developmentsix,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            }, {
                title: 'Basic SEO:',
                text: ' Integrating fundamental SEO practices during development to improve search engine visibility and drive organic traffic.',
                imgSrc: Images.developmentseven,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "",
                id: "connect-bottom"

            },

        ],
        bannerContent: {
            title: "Why consult Kartoffel Technologies for UI/UX development?​",
            description: "Our UI/UX development services translate your designs into pixel-perfect, adaptable, scalable digital products.​",
            buttonText: "Request Call Back",
            imageSrc: Images.uiuxban,
            imageAlt: "Consultation"
        },
        meta_description:"Our UI/UX design services include digital product design, market and user research, qualitative and quantitative studies, and digital product vision and strategy.",
        meta_title:"UI/UX Design and Development Service",
    }
}
export const NewProductURL = {
    "new-product-assessment": {
        cards: [
            {
                title: 'Ideation Stage:',
                text: 'We assist clients in generating and gathering new ideas from internal teams and external research. This phase includes brainstorming sessions to identify potential solutions to existing problems.',
                imgSrc: Images.productone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Idea Screening: ',
                text: 'We help clients evaluate and prioritize ideas to identify the most promising ones. Using criteria such as impact, effort, and confidence level, we score and select the best ideas for further development.​',
                imgSrc: Images.producttwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Concept Development:',
                text: `Our team develops detailed concepts for the selected ideas. We create comprehensive descriptions and specifications to ensure that feasible ideas align with the client's strategic objectives.`,
                imgSrc: Images.productthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Engineering Assessment and Best Practices:',
                text: `​We mitigate risks, enhance code quality, and ensure successful project delivery while reducing costs. Services include code reviews, tech debt management, CI/CD, architecture design, QA assessment, and project and product management.`,
                imgSrc: Images.productfour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            },
           
        ],
        bannerContent: {
            title: "How can partnering with Kartoffel Technologies to optimize your product help you? ",
            description: `Kartoffel Technologies optimizes business programs and systems through digital platforms, Agile software development, and quality assurance, improving overall software efficiency. We offer comprehensive new product development services to help clients bring their ideas to life and align them with strategic objectives. We ensure timely market entry without compromising on quality.`,
            buttonText: "Request Call Back",
            imageSrc: Images.proban,
            imageAlt: "Consultation"
        },
        meta_description:"Transforming your vision into a winning product. We generate innovative ideas, craft user-centric designs, and a roadmap that aligns with your business objectives.",
        meta_title:"New Product Feasibility Assessment",
    }
}
export const MvpdevelopmentURL = {
    "mvp-development": {
        cards: [
            {
                title: 'MVP Consulting:',
                text: `We assist companies in creating and implementing their MVP strategy, ensuring the product meets its intended objectives.`,
                imgSrc: Images.mvpone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Marketplace Model​: ',
                text: 'Our MVP marketplace model allows customers to test transactions and the marketplace business model, simplifying the development of a streamlined marketplace.',
                imgSrc: Images.mvptwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Licensing or White Label Model:',
                text: `Accelerate development by using white-label solutions to rebrand or modify products, or license third-party technology, focusing on core value and quick delivery.`,
                imgSrc: Images.mvpthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'MVP End-to-End Development:',
                text: `We handle the entire MVP process from concept to deployment, enabling incremental development based on user feedback and market validation.`,
                imgSrc: Images.mvpfour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            },
           
        ],
        bannerContent: {
            title: "How can Kartoffel Technologies enhance your MVP development?",
            description: `Our MVP solutions help you "Start Fast and Learn Smart" by creating your product efficiently, keeping you ahead in the competition.`,
            buttonText: "Request Call Back",
            imageSrc: Images.mvpban,
            imageAlt: "Consultation"
        },
        meta_description:" At Kartoffel Tech, we’re a top MVP development company that has helped many startups and large businesses build MVP apps and turn their ideas into successful products with our specialized MVP services.",
        meta_title:"Minimum Viable Product (MVP) Development Services",
    }
}
export const  ProductRoadmapDevelopmentURL = {
    "product-roadmap": {
        cards: [
            {
                title: 'Experience Design:',
                text: `Create user-loved products that enhance loyalty and boost sales. We offer design research, ideation, wireframes, user testing, prototypes, and UI/interaction design.`,
                imgSrc: Images.roadmapone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Product Development: ',
                text: 'We build the product according to the agreed specifications. This stage includes designing, coding, and creating prototypes to meet the defined requirements and standards.',
                imgSrc: Images.roadmaptwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Product Testing:',
                text: ` We conduct thorough testing of the product and prepare it for market launch. This includes internal QA tests, gathering feedback from real users through alpha and beta testing, and refining the product before its official release.`,
                imgSrc: Images.roadmapthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Support Services:',
                text: `We provide thorough training and documentation for support teams and end-users to ensure smooth adoption and usage. We additionaly help client if they need a plan to execute marketing strategies to create buzz and awareness about the product launch.`,
                imgSrc: Images.roadmapfour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            },
           
        ],
        bannerContent: {
            title: "How can Kartoffel Technologies assist in your Product Development?",
            description: `​Kartoffel Technologies enhances every stage of product development. From integrating cutting-edge technology to custom software development, we bring your vision to life. Our focus on user experience, scalability, and compliance ensures your product is market-ready and competitive. Partner with Kartoffel Technologies for a seamless and efficient development journey.`,
            buttonText: "Request Call Back",
            imageSrc: Images.roadmapban,
            imageAlt: "Consultation"
        },
        meta_description:"Our comprehensive new product development services cover every step of the journey. We offer end-to-end support, including market research, concept validation, design, development, and launch strategies.",
        meta_title:"Product Roadmap Development ",
    }
}
export const  GamePortingURL = {
    "game-porting": {
        cards: [
            {
                title: 'Full-Cycle Porting:',
                text: `We offer complete porting services, whether from PC to mobile or to consoles. We also do technological porting from one game engine to another. `,
                imgSrc: Images.portone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Comprehensive Analysis and Optimization:',
                text: 'Our team thoroughly analyses your game and ports it to multiple platforms to ensure the best gaming experience is delivered.​',
                imgSrc: Images.porttwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Experienced Team:',
                text: `Our development team consists of specialists who understand the features of various engines, including Unity and Unreal Engine. `,
                imgSrc: Images.portthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Maintenance and Support:',
                text: `We provide full support during the engagement and maintenance after release. ​`,
                imgSrc: Images.portfour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            },
           
        ],
        bannerContent: {
            title: "How Can Kartoffel Technologies Enhance Your Game Porting Experience?",
            description: `With Kartoffel Technologies, you can trust our extensive experience and expertise in providing comprehensive game porting services. We ensure your game reaches multiple platforms seamlessly and efficiently with our range of services. Our unique services and experienced team make us the ideal choice for your game porting needs.`,
            buttonText: "Request Call Back",
            imageSrc: Images.portingban,
            imageAlt: "Consultation"
        },
        meta_description:" Embrace the future with confidence as we guide you through seamless migration and implementation of cutting-edge technologies",
        meta_title:"Game Porting",
    }
}
export const CoDevelopmentURL = {
    "co-development": {
        cards: [
            {
                title: 'Collaborative Development:',
                text: ` We work alongside your development team, handling specific aspects of the project while you manage others. This partnership ensures a balanced workload and efficient progress. `,
                imgSrc: Images.coone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Shared Expertise:',
                text: ' Benefit from our experienced developers and designers who bring their specialized skills to your project. We help fill any skill gaps and enhance the overall quality of your game.​',
                imgSrc: Images.cotwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Cost-Effective Solutions:',
                text: ` You can manage costs more effectively by sharing development responsibilities. We help optimize your budget without compromising on the quality of the final product.`,
                imgSrc: Images.cothree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Seamless Integration: ',
                text: `Our team ensures seamless integration with your existing processes and tools, providing support and expertise where needed. This collaborative approach helps streamline development and reduces potential bottlenecks.​`,
                imgSrc: Images.cofour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            },
            {
                title: 'Flexible Engagement Models:',
                text: `We offer flexible engagement models tailored to your specific needs, whether you require assistance with initial development stages, mid-project support, or final testing and polishing.`,
                imgSrc: Images.cofive,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Continuous Support:',
                text: ` We provide constant support and communication throughout the co-development process to ensure alignment and promptly address any challenges.​`,
                imgSrc: Images.cosix,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            },
           
        ],
        bannerContent: {
            title: "How Can Kartoffel Technologies Enhance Your Game Development Through Co-Development?",
            description: `​Partner with Kartoffel Technologies for co-development to achieve a more efficient and collaborative game development process and ensure your project’s success.`,
            buttonText: "Request Call Back",
            imageSrc: Images.coban,
            imageAlt: "Consultation"
        },
        meta_description:" Streamline your operations and enhance scalability by migrating and integrating your infrastructure with our advanced solutions, optimizing performance, and minimizing downtime.",
        meta_title:"Co-Development",
    }
}
export const UnityGameURL = {
    "unity-game-development": {
        cards: [
            {
                title: 'Full-Cycle Unity Game Development:',
                text: `We provide comprehensive game development services, utilizing Unity 2D and 3D across all genres and concepts. Our development process features straightforward architecture to ensure smooth and efficient game creation.`,
                imgSrc: Images.gameone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Multiplayer System: ',
                text: 'We design fast and scalable multiplayer systems using matchmakers and relay servers, enabling players to connect to games quickly without long waits.​',
                imgSrc: Images.gametwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Integration Services: ',
                text: `Our integration services facilitate seamless connectivity with other software. We offer integration with APIs, Webhooks, and Unity Analytics, simplifying the creation and management of your games.`,
                imgSrc: Images.gamethree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: '2D/3D Unity Games Development: ',
                text: `Our talented developers craft immersive 2D and 3D games for various devices and platforms. Unity's versatility allows us to develop captivating games that engage users.​`,
                imgSrc: Images.gamefour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            },
            {
                title: 'Cross-Platform Game App Development: ',
                text: `Unity supports multiple platforms, including desktops, mobile devices, game consoles, and the web. We ensure that Unity's adaptable codebase works seamlessly across all these platforms.`,
                imgSrc: Images.gamefive,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'AR/VR Game Development: ',
                text: `Our skilled developers also are experts in developing VR/AR mobile or PC games, turning your ideas beyond imagination.​`,
                imgSrc: Images.gamesix,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            },
           
        ],
        bannerContent: {
            title: "Why Choose Kartoffel Technologies for Unity 3D Game Development?",
            description: `Kartoffel Technologies is a leading Unity 2D, 3D game development company known for crafting innovative games that offer enjoyable and interactive experiences. Our team of expert Unity developers creates high-quality 2D, 3D games for various platforms, including Android, iOS, and PC. We specialize in developing diverse game types, including simulations, web apps, and AR/VR experiences.`,
            buttonText: "Request Call Back",
            imageSrc: Images.unityban,
            imageAlt: "Consultation"
        },
        meta_description:"Transform your outdated interfaces into visually stunning and intuitive experiences with our UI/UX modernization services, captivating your users and enhancing their engagement.",
        meta_title:"Unity Game Development",
    }
}
export const  UnrealGameDevelopmentURL = {
    "unreal-game-development": {
        cards: [
            {
                title: 'End-to-End Game Development:',
                text: `We offer full-cycle game development services, managing everything from the concept stage to release. Our team provides seamless and efficient production.`,
                imgSrc: Images.unrealone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Post-Release Support​: ',
                text: 'Our LiveOps and post-release maintenance experts are dedicated to enhancing your game long after its initial release. We maximize revenue and increase your player base through strategic content updates, bug fixes, and performance improvements.​',
                imgSrc: Images.unrealtwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Game Prototyping:',
                text: `Using the Unreal engine, we quickly create functional prototypes. Our team develops your idea into a playable prototype with basic mechanics, allowing you to test and verify its feasibility without committing to complete development.​`,
                imgSrc: Images.unrealthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'VR & MR Solutions:',
                text: `Our team of Unreal game developers creates immersive XR experiences, including games and applications for platforms such as Oculus and HTC Vive. We specialize in developing projects that provide engaging virtual and mixed-reality experiences.​`,
                imgSrc: Images.unrealfour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            },
           
        ],
        bannerContent: {
            title: "Why Choose Kartoffel Technologies for Unreal Engine Game Development?",
            description: `Kartoffel Technologies is an Unreal Engine game development studio which creates high-quality games across multiple platforms. Our experienced team handles projects across various genres, delivering comprehensive solutions with industry-leading features from concept to launch.`,
            buttonText: "Request Call Back",
            imageSrc: Images.unrealban,
            imageAlt: "Consultation"
        },
        meta_description:" Unlock the potential of your software product with our expert consulting and auditing services, ensuring optimal performance and strategic growth.",
        meta_title:"Unreal Game Development",
    }
}
export const  GameTestingURL = {
    "game-testing": {
        cards: [
            {
                title: 'Game Functionality Testing:',
                text: `We ensure your game works seamlessly across various hardware configurations, resolutions, drivers, codecs, mods, and operating systems.`,
                imgSrc: Images.testingone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Game Compatibility Testing​:',
                text: 'Our team tests your game on multiple devices to ensure it is compatible with different hardware setups and software environments.​',
                imgSrc: Images.testingtwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Game Performance Testing: ',
                text: `We identify and address performance issues, ensuring your game runs smoothly on all devices.`,
                imgSrc: Images.testingthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Game Compliance Testing: ',
                text: `We ensure your game meets industry standards with no bottlenecks or problematic areas.`,
                imgSrc: Images.testingfour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: "two"

            },
            {
                title: 'Mobile Game Testing: ',
                text: `To ensure optimal performance, we test mobile games for battery usage, RAM limitations, memory formats, and OS compatibility.`,
                imgSrc: Images.testingfive,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Console Game Testing: ',
                text: `Our expertise in console game development ensures your game meets all requirements, from gamepad specifics to TRC certification and proper functioning of activities and achievements.`,
                imgSrc: Images.testingsix,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            },
           
        ],
        bannerContent: {
            title: "Why Choose Kartoffel Technologies as your Game Testing Partner?",
            description: `Ensure your game is of the highest quality before release or at any stage of development. Let our expertise help you deliver a high-quality gaming experience to your users. Our experienced testers use various methodologies and tools to thoroughly test your game, ensuring it is free of bugs and glitches and meets industry standards. Gain the expertise and scalability needed for a successful game release. `,
            buttonText: "Request Call Back",
            imageSrc: Images.testingban,
            imageAlt: "Consultation"
        },
        meta_description:"Unlock the potential of your software product with our expert consulting and auditing services, ensuring optimal performance and strategic growth.",
        meta_title:"Game Testing",
    }
}
export const   InteractiveDesignURL = {
    "interactive-design": {
        cards: [
            {
                title: 'Engaging User Interfaces: ',
                text: `We create interfaces that are not only beautiful but also intuitive.`,
                imgSrc: Images.interactiveone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'Advanced Interaction Techniques:',
                text: ' Our focus on smooth navigation, responsive feedback and captivating visuals ensures a spellbinding experience, making it both functional and enjoyable. ',
                imgSrc: Images.interactivetwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'Incentive Structures: ',
                text: `We incorporate rewards, points, and badges to motivate users and increase their engagement with your application.`,
                imgSrc: Images.interactivethree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"

            },
            {
                title: 'Progress Tracking:',
                text: ` We implement leaderboards and progress bars to give users a sense of achievement and encourage continued use of your app.`,
                imgSrc: Images.interactivefour,
                imgAlt: 'Left Icon',
                imgPosition: 'right',
                number: ""

            },
           
        ],
        bannerContent: {
            title: "How Can Kartoffel Technologies Enhance Your Interactive Engineering and Gamification?",
            description: `Kartoffel Technologies offers advanced interactive engineering and gamification solutions to create engaging, user-friendly applications that captivate your audience. We ensure your users enjoy an intuitive and rewarding experience by leveraging the latest technologies and design principles.`,
            buttonText: "Request Call Back",
            imageSrc: Images.interactiveban,
            imageAlt: "Consultation"
        },
        meta_description:"Create immersive, engaging, and addictive games that captivate your audience, driving player engagement and boosting revenue.",
        meta_title:"Interactive Design",
    }
}
export const   ARVRMREngineeringURL = {
    "arvrmr-engineering": {
        cards: [
            {
                title: 'AR Engineering:',
                text: `Develop augmented reality applications that overlay digital content onto the real world, providing unique and interactive experiences.`,
                imgSrc: Images.arone,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "one"
            },
            {
                title: 'VR Engineering: ',
                text: 'Create virtual reality experiences that fully immerse users in a digitally constructed environment.',
                imgSrc: Images.artwo,
                imgAlt: 'Right Icon',
                imgPosition: 'right',
                number: "two"
            },
            {
                title: 'MR Engineering: ',
                text: `Combine the best AR and VR to create mixed reality experiences seamlessly blending the physical and digital worlds.`,
                imgSrc: Images.arthree,
                imgAlt: 'Left Icon',
                imgPosition: 'left',
                number: "",
                id: "connect-bottom"

            }
           
        ],
        bannerContent: {
            title: "How Can Kartoffel Technologies Lead in AR/VR/MR Engineering?",
            description: `Kartoffel Technologies has a proven track record of creating immersive experiences that turn ideas into virtual realities. We ensure to deliver high-quality, interactive applications for various platforms, including Oculus, HTC Vive, and more. Partnering with Kartoffel Technologies can significantly enhance your interactive engineering, gamification, and AR/VR/MR capabilities.`,
            buttonText: "Request Call Back",
            imageSrc: Images.arvrban,
            imageAlt: "Consultation"
        },
        meta_description:"Transform your vision into reality with cutting-edge AR/VR solutions that revolutionize user experiences and unlock limitless possibilities.",
        meta_title:"AR / VR / MR Engineering",
    }
}