import React from 'react'
import "../../assests/css/Common.css"
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import Footer from '../../commonComponents/footer/footer'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import Seo from "../../commonComponents/MetaTag/MetaTag";
import BlogSection from '../../component/Blogs/Blog'
import BlogBanner from '../../component/Blogs/BlogsBan'


const BlogPage = () => {
  const meta_description=" Discover valuable insights, trends, and expertise in technology and innovation on the Kartoffel Technologies Blog. Stay informed and inspired.";
  const meta_title="  Explore Insights and Expertise - Kartoffel Technologies Blog";
  return (
    <div className='Blogswholes'>
      <Seo meta_description={meta_description} meta_title={meta_title}/>
      <NavbarHeader />
      <BlogBanner/>
      <BlogSection/>
      <GetInTouch/>
      <Footer />
    </div>
  )
}

export default BlogPage