import React from 'react'
import "../../assests/css/CaseStudySub.css"
import Images from '../../Images/Images'
const CaseThap = () => {
    return (
        <div className='casthapsj'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="lefthaph">
                            <img className='phpnip' src={Images.iphthap} alt="" />
                        </div>

                    </div>
                    <div className="col-lg-6">
                        <div className="rightsthaps">
                        <h3>THAP</h3>
                        <div className="needap">
                          <p className=''>B2C,mobile application</p>  
                        </div>
                        <div className="subthapsk">
                            <p>THAP is an app for consultation with a wellness coach via teleconsultation. It has inbuilt assessment tests, personalized plans with exercises and diet recommendations.</p>
                        </div>
                        <div className="tolsim">
                            <img src={Images.tolsshk} alt="" />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CaseThap