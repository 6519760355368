import React from 'react';
import Images from '../../Images/Images';
import "../../assests/css/serBan.css";

const AboutBan = () => {
    const bann = [
        {
            img: Images.abtBan,
            text: ""
        }
    ];
    return (
        <div className='aboutBannersection'>
            {bann.map((item, index) => (
                <div key={index} className='aboutBanMain'>
                    <img className='aboutImgBan' src={item.img} alt="banner-img" />
                    <div className='bannerContent'>
                        <h1>About</h1>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AboutBan;
