import React from 'react'
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import CuspSection from '../../component/KnowledgeCenter/CuspSection/CuspSection'
import KnowledgeBlogsCenter from '../../component/KnowledgeCenter/KnowledgeBlogsCenter/KnowledgeBlogsCenter'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import LandFooter from '../../commonComponents/footer/LandFooter'
import Footer from '../../commonComponents/footer/footer'
import ServiceBan from '../../component/Service/ServiceBanneer'
import CaseStudies from '../../component/CaseStudy/CaseCards'
import Images from "../../Images/Images"
// import KnowledgeCenterBanner from '../../component/KnowledgeCenter/KnowledgeCenterBanner/KnowledgeCenterBanner'

const KnowledgeCenter = () => {

  const bann = [
    {
      img: Images.knowban,
      head: "Knowledge Center",
    }
  ];
  return (
    <div className='know-ledge'>
      <NavbarHeader />
      <ServiceBan bann={bann} />
      <CuspSection />
      <CaseStudies/>
      <KnowledgeBlogsCenter />
      <GetInTouch />
      <Footer />
    </div>
  )
}

export default KnowledgeCenter