import React, { useRef, useEffect } from 'react';
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient'
import Images from "../../Images/Images"
// import Seo from "../../commonComponents/MetaTag/MetaTag";
import LandingBan from '../../component/Career/CareerBanner';
import NavbarLanding from '../../commonComponents/header/NavbarLanding';
import WebsiteInfo from '../../component/WebLanding/Website';
import Features from '../../component/WebLanding/WebService';
import WebDomain from '../../component/WebLanding/WebDomain';
import OurProcess from '../../component/WebLanding/WebOurProcess';
import Achievements from '../../component/WebLanding/WebAchievment';
import LandGetInTouch from '../../commonComponents/GetInTouch/LandGetInTouch';
import LandFooter from '../../commonComponents/footer/LandFooter';

const WebLandPage = () => {

  const ourServiceRef = useRef(null);
  const containerRef = useRef(null);

  const handleKnowMoreClick = () => {
    if (ourServiceRef.current) {
      ourServiceRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

    const bann = [
        {
            img: Images.weblanban,
        }
    ];
    const client= {
      classname: "clients"
    };

    useEffect(() => {
      if (containerRef.current) {
        const divSections = Array.from(containerRef.current.children);
        divSections.forEach((section, index) => {
          const height = section.clientHeight;
          console.log(`Section ${index + 1} height:`, height);
        });
      }
    }, 100);
  return (
    <div className='Weblanding'>
       <NavbarLanding/>
        <LandingBan  bann={bann} onKnowMoreClick={handleKnowMoreClick} />
        <OurTrustedPartners client={client}/>
        <WebsiteInfo/>
        <Features/>
        <WebDomain />
        <OurProcess/>
        <Achievements/>
        <div ref={ourServiceRef} >
        <LandGetInTouch/>
        </div>
        <LandFooter/>
    </div>
  )
}

export default WebLandPage