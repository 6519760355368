import React, { useRef, useEffect, useState } from 'react'
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import SpecificBanner from '../../commonComponents/ServiceInnerBan/ServiceBan'
import Footer from '../../commonComponents/footer/footer'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import AuditBanner from '../../commonComponents/ServiceInn/AuditKartoffel'
import ConnectedFeatures from '../../commonComponents/ServiceInnConnection/ServiceInnConnection'
import ProductAnimations from "../../component/ProductAnimations/ProductAnimations"
import { DataURL, WebappURL, ModernizationURL, MaintenanceURL, UIDesignURL, UIDevelopmentURL, NewProductURL, MvpdevelopmentURL, ProductRoadmapDevelopmentURL, GamePortingURL, CoDevelopmentURL, UnityGameURL, UnrealGameDevelopmentURL, GameTestingURL, InteractiveDesignURL, ARVRMREngineeringURL } from '../../commonComponents/ServiceInnerData/InnerData';
import DevelopmentTools from '../../component/Service/DevelopmentTools'
import throttle from 'lodash/throttle';
import Seo from "../../commonComponents/MetaTag/MetaTag";


const ServiceInnerPage = () => {

  // useEffect(() => {
  //     window.scrollTo(0, 0)
  //   }, [])
  const [staticContent, setsStaticContent] = useState({});
  const [renderStatus, setRenderStatus] = useState(true);

  useEffect(() => {
    const currentPath = window.location.pathname.split('/');
    const pathParam = currentPath[currentPath.length - 1];

    if (pathParam && renderStatus) {
      const dataContent = DataURL[pathParam];
      const webappContent = WebappURL[pathParam];
      const modernizationContent = ModernizationURL[pathParam];
      const maintenanceContent = MaintenanceURL[pathParam];
      const uidesignContent = UIDesignURL[pathParam];
      const uidevelopmentContent = UIDevelopmentURL[pathParam];
      const newproductContent = NewProductURL[pathParam];
      const mvpdevelopmentContent = MvpdevelopmentURL[pathParam];
      const ProductRoadmapContent = ProductRoadmapDevelopmentURL[pathParam];
      const gameportingContent = GamePortingURL[pathParam];
      const codevelopmentContent = CoDevelopmentURL[pathParam];
      const unitygameContent = UnityGameURL[pathParam];
      const unrealgameContent = UnrealGameDevelopmentURL[pathParam];
      const gametestingContent = GameTestingURL[pathParam];
      const interactivedesignContent = InteractiveDesignURL[pathParam];
      const arvrmrengineeringContent = ARVRMREngineeringURL[pathParam];
      // const deditechteamsContent = deditechteamsURL[pathParam];
      // const apimicrointergrationContent = apimicrointergrationURL[pathParam];
      // const crmintergrationContent = crmintergrationURL[pathParam];
      // const consultmarketstudyContent = consultmarketstudyURL[pathParam];
      // const ideationSolutionContent = ideationSolutionURL[pathParam];
      // const ideamarketContent = ideamarketURL[pathParam];

      setsStaticContent({ ...dataContent, ...webappContent, ...modernizationContent, ...maintenanceContent, ...uidesignContent, ...uidevelopmentContent, ...newproductContent, ...mvpdevelopmentContent, ...ProductRoadmapContent, ...gameportingContent, ...codevelopmentContent, ...unitygameContent, ...unrealgameContent, ...gametestingContent, ...interactivedesignContent, ...arvrmrengineeringContent });
      setRenderStatus(false);
    }
  }, [renderStatus]);
  const containerRef = useRef(null);
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  useEffect(() => {
    if (containerRef.current) {
      const divSections = Array.from(containerRef.current.children);
      divSections.forEach((section, index) => {
        const height = section.clientHeight;
        console.log(`Section ${index + 1} height:`, height);
      });
    }

    const handleScroll = throttle(() => {
      if (window.innerWidth > 768) {
        let lastScrollTop = 0;
        const scrollPosition = window.scrollY;
        const someDiv = document.getElementById('aaaaaaaaaaaaaaaaaa');
        if (someDiv) {
          const distanceToTop = someDiv.getBoundingClientRect().top;
          if ((distanceToTop > 550 && distanceToTop < 580) && scrollPosition > lastScrollTop) {
            window.scrollTo({ top: 1500, behavior: 'smooth' });
          }

          if (distanceToTop > -560 && distanceToTop < -550 && scrollPosition < lastScrollTop) {
            window.scrollTo({ top: 1300, behavior: 'smooth' });
          }
        }
        lastScrollTop = scrollPosition;
      }
    }, 100);

    if (window.innerWidth > 768) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='ServiceInner'>
      <Seo meta_description={staticContent.meta_description} meta_title={staticContent.meta_title} />
      <NavbarHeader />
      <SpecificBanner bannerContent={staticContent.bannerContent} />
      <ConnectedFeatures cards={staticContent.cards} />
      {/* <div className='aaaaaaaaaaaaaaaaaa' id='aaaaaaaaaaaaaaaaaa'>
                <ProductAnimations />
            </div> */}
      <AuditBanner />
      <DevelopmentTools />
      <GetInTouch />
      <Footer />
    </div>
  )
}

export default ServiceInnerPage

