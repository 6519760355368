import React from 'react';
import { Link } from 'react-router-dom';
import "../../assests/css/Footer.css";
import Images from "../../Images/Images";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="site-footer__middle-inner bottom-foot">
                    <div className='row row-15'>
                        <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
                            <div className="site-footer__top-logo">
                                <Link to="" className="logoImageiconfooter">
                                    <img src={Images.KartoffelLogo} alt='logo' />
                                </Link>
                            </div>
                            <div className='row row-15 mt-4'>
                                {/* <div className='col-lg-5 col-md-12 col-sm-12 info-kart'>
                                    <p className='foot-head'>Contact</p>
                                    <p>contact@kartoffel.in</p>
                                    <p>+91 86374 04992</p>
                                </div> */}
                                <div className='col-lg-12 col-md-12 col-sm-12 info-kart'>
                                    <p className='foot-head'>Location</p>
                                    <p className='add-kart'>Office No. 28, Workflo by OYO,<br />GREETA TOWERS, 99, Rajiv Gandhi Salai,<br />Phase-1,
                                        Industrial Estate,<br />Perungudi, Chennai-600096</p>
                                </div>
                            </div>
                            <div className='site-footer__top-right-social col-lg-5 col-md-12 col-sm-12 mb-3  mt-2'>
                                <a href="https://www.facebook.com/kartoffeltech" target="_blank" rel="noopener noreferrer">
                                    <img src={Images.fb} alt='fb logo'></img>
                                </a>
                                <a href="https://www.instagram.com/kartoffeltechnologies/" target="_blank" rel="noopener noreferrer">
                                    <img src={Images.insta} alt='insta logo'></img>
                                </a>
                                {/* <a href="">
                                    <img src={Images.x} alt='x logo'></img>
                                </a> */}
                                <a href="https://www.linkedin.com/company/kartoffeltech/mycompany/" target="_blank" rel="noopener noreferrer">
                                    <img src={Images.linked} alt='linkedin logo'></img>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 mt-2">
                            <div className='row row-15 mt-4'>
                                <div className="col-lg-4 col-md-6 col-sm-12 d-flex site-map">
                                    <div className='kart-foot'>
                                        <span className='foot-head'>Site Map</span>
                                        <Link className="kart-link" to='/about'><p className='foot-menu mt-3'>About</p></Link>
                                        <Link className="kart-link" to='/careers'><p className='foot-menu'>Careers</p></Link>
                                        <Link className="kart-link" to='/blogs'><p className='foot-menu'>Blogs</p></Link>
                                        <Link className="kart-link" to='/contact-us'><p className='foot-menu'>Contact</p></Link>
                                        <Link className="kart-link" to='/knowledge-center'><p className='foot-menu '>Knowledge Center</p></Link>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 service-foot'>
                                    <div className='kart-foot'>
                                        <span className='foot-head'>Services</span>
                                        <Link className="kart-link" to='/product-engineering'><p className='foot-menu mt-3'>Product Engineering</p></Link>
                                        <Link className="kart-link" to='/digital-transformation'><p className='foot-menu'>Digital Transformation</p></Link>
                                        <Link className="kart-link" to='/product-development'><p className='foot-menu'>Product Development</p></Link>
                                        <Link className="kart-link" to='/game-engineering'><p className='foot-menu'>Game Engineering</p></Link>
                                        <Link className="kart-link" to='/interactive-engineering'><p className='foot-menu'>Interactive Engineering</p></Link>
                                    </div>
                                </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12 d-flex site-map">
                                        <div className='kart-foot'>
                                            <span className='foot-head'>company</span>
                                            <Link className="kart-link" to='/'><p className='foot-menu mt-3'>Home</p></Link>
                                            <Link className="kart-link" to='/privacy-policy'><p className='foot-menu'>Privacy Policy</p></Link>
                                            <Link className="kart-link" to='/terms-and-condition'><p className='foot-menu'>Terms And Conditions</p></Link>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className='footer-info'>
                        <div className='row foot-dis'>
                            <div className="col-lg-3 col-md-3 col-xs-6 ">
                                <p className='foot-top'>Contact</p>
                                <a href="tel:+918637404992" className='footer-lin'>+91 86374 04992</a>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xs-6">
                                <p className='foot-top'>Project Inquiry</p>
                                <a href="mailto:contactus@kartoffel.in" className='footer-lin'>contactus@kartoffel.in</a>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xs-6">
                                <p className='foot-top'>Careers</p>
                                <a href="mailto:info@kartoffel.in" className='footer-lin'>info@kartoffel.in</a>
                            </div>
                            <div className="col-lg-3 col-md-3 col-xs-6">
                                <p className='foot-top'>WhatsApp</p>
                                <a href="https://wa.me/918637404992" className='footer-lin'>Chat with us</a>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom">
                        &copy; Copyrights, 2024 Kartoffel Technologies
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
