import React from 'react'
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import ServiceBan from '../../component/Service/ServiceBanneer'
import Images from "../../Images/Images"
import Footer from '../../commonComponents/footer/footer'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import Seo from "../../commonComponents/MetaTag/MetaTag";
import TestimonialCarousel from '../../component/Home/Tesimony'
import CaseStudies from '../../component/CaseStudy/CaseCards'
import ProcessSteps from '../../component/CaseStudy/ProcessSteps'

const CaseStudyPage = () => {
    const bann = [
        {
            img: Images.caseban,
            head: "Case Studies",
        }
    ];
    
  return (
    <div className='caseStudy'>
        {/* <Seo meta_description={meta_description} meta_title={meta_title}/> */}
        <NavbarHeader/>
        <ServiceBan  bann={bann}/>
        <div>
            <p className='case-client'>What Client Says</p>
        <TestimonialCarousel/>
        </div>
        <CaseStudies/>
        <ProcessSteps/>
        <GetInTouch/>
        <Footer/>
    </div>
  )
}

export default CaseStudyPage