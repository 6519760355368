import React from 'react'
import NavbarHeader from '../../commonComponents/header/NavbarHeader'
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient'
import Contact from '../../component/Contact/Contact'
import ServiceBan from '../../component/Service/ServiceBanneer'
import Images from "../../Images/Images"
import Footer from '../../commonComponents/footer/footer'
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import Address from '../../component/Contact/Address'
import Seo from "../../commonComponents/MetaTag/MetaTag";

const ContactUsPage = () => {
    const bann = [
        {
            img: Images.contactban,
            head: "CONTACT US",
        }
    ];
    const meta_description="Reach out to Kartoffel Technologies for inquiries, collaborations, or support. We're here to assist you with our expertise in technology solutions.";
    const meta_title=" Get in Touch - Contact Kartoffel Technologies Today";
  return (
    <div className='contactus'>
        <Seo meta_description={meta_description} meta_title={meta_title}/>
        <NavbarHeader/>
        <ServiceBan  bann={bann}/>
        {/* <OurTrustedPartners/> */}
        <Contact/>
        <Address/>
        {/* <GetInTouch/> */}
        <Footer/>
    </div>
  )
}

export default ContactUsPage