import React, { useState } from 'react';
import Image from '../../Images/Images';
import "../../assests/css/OutSourced.css";
import "../../assests/css/Contact.css";

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [idea, setIdea] = useState('');
    const [termsChecked, setTermsChecked] = useState(false);
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(null);



    const listItems = [
        {
            alt: "Specialized Expertise",
            text: `Our team is dedicated to providing a prompt response to your inquiries.`,
        },
        {
            alt: "Specialized Expertise",
            text: `Discuss your specific needs with our experts to get customised solutions.`,
        },
        {
            alt: "Specialized Expertise",
            text: `From initial consultation to ongoing maintenance, we are here to assist you every step of the way.`,
        },
        {
            alt: "Specialized Expertise",
            text: `Connect with our highly skilled professionals with extensive experience in software development.`
        },
        {
            alt: "Specialized Expertise",
            text: `We ensure your data and communications are secure and confidential.`,
        },
        {
            alt: "Specialized Expertise",
            text: `Learn about our various engagement models tailored to fit your project requirements.`,
        },
        {
            alt: "Specialized Expertise",
            text: `No matter where you are located, we can support your business needs.`,
        },
        {
            alt: "Specialized Expertise",
            text: `Request a free consultation to explore how we can help your business grow.`,
        },
    ];

    const validateForm = () => {
        const newErrors = {};
        
        if (!name) newErrors.name = 'Name is required';
        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email is not valid';
        }
        if (phone && !/^\d{10}$/.test(phone)) {
            newErrors.phone = 'Phone number must be 10 digits';
        }
        if (!idea) newErrors.idea = 'Your current big idea is required';
        if (!termsChecked) newErrors.termsChecked = 'You must agree to the Terms and Conditions';
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsSubmitting(true);

            const payload = {
                to: "contactus@kartoffel.in",
                subject: `New Contact from ${name}`,
                body: `
                    <p><strong>Name:</strong> ${name}</p>
                    <p><strong>Email:</strong> ${email}</p>
                    <p><strong>Phone:</strong> ${phone}</p>
                    <p><strong>Loking For:</strong> ${idea}</p>
                `,
                isHtml: true // Optional flag if your email service requires specifying HTML content
            };
            
            fetch('https://kartoffel.in/api/send-mail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
            .then(response => response.json())
            .then(data => {
                setIsSubmitting(false);
                setSubmissionSuccess(true);
                console.log('Success:', data);
            
                setName('');
                setEmail('');
                setPhone('');
                setIdea('');
                setTermsChecked(false);
                setErrors({});
            })
            .catch((error) => {
                setIsSubmitting(false);
                setSubmissionSuccess(false);
                console.error('Error:', error);
            });
        }
    };

    return (
        <div>
            <div className="contact">
                <div className="row oopd-cont">
                    <div className="col-lg-6 col-md-12 col-sm-12 slo">
                        <div className="contact-form">
                            <h2 className='heads-cont'>CONTACT US</h2>
                            <form onSubmit={handleSubmit}>
                                <input 
                                    type="text" 
                                    placeholder="Name" 
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required 
                                />
                                {errors.name && <p className="error">{errors.name}</p>}
                                
                                <input 
                                    type="email" 
                                    placeholder="Email" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required 
                                />
                                {errors.email && <p className="error">{errors.email}</p>}
                                
                                <input 
                                    type="tel" 
                                    placeholder="Phone Number (Optional)" 
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value.replace(/\D/, ''))}
                                />
                                {errors.phone && <p className="error">{errors.phone}</p>}
                                
                                <input 
                                    type="text" 
                                    placeholder="Your current big idea!" 
                                    value={idea}
                                    onChange={(e) => setIdea(e.target.value)}
                                    required 
                                />
                                {errors.idea && <p className="error">{errors.idea}</p>}
                                
                                <div className="checkbox-group">
                                    <label className='con-back'>
                                        <input 
                                            type="checkbox" 
                                            checked={termsChecked}
                                            onChange={(e) => setTermsChecked(e.target.checked)}
                                            required 
                                        /> Terms and Conditions
                                    </label>
                                    {errors.termsChecked && <p className="error">{errors.termsChecked}</p>}
                                </div>
                                <button className='bn-contactusr' type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? 'Submitting...' : 'Submit'}
                                </button>
                            </form>
                            {submissionSuccess === true && <p className="success-message">Your message was sent successfully!</p>}
                            {submissionSuccess === false && <p className="error-message">There was an error sending your message. Please try again later.</p>}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 happen">
                        <div className='next mt-3'>
                            <p className='subHeading-next mb-4'>What Happens Next? </p>
                            <ul className='myList cot-lis'>
                                {listItems.map((item, index) => (
                                    <li key={index} className='listedItems mb-2'>
                                        <img src={Image.list} alt={item.alt} className="icon-img" />
                                        <span className="text-content">{item.text}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
