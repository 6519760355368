import React, { useState, useEffect } from 'react';
import '../../assests/css/Cusp.css'; // Assuming you have a CSS file for styles
import CuspTabsContent from './CuspTabsContent';

const CuspFrame = () => {
  const [activeTab, setActiveTab] = useState('Consumerization');
  const [isSticky, setIsSticky] = useState(false);

  const scrollToSection = (id) => {
    const container = document.querySelector('.scrolleble');
    const element = document.getElementById(id);
    
    if (element && container) {
      const elementPosition = element.offsetTop - container.offsetTop;
      container.scrollTo({
        top: elementPosition,
        behavior: 'smooth'
      });
      setActiveTab(id);
    }
  };

  useEffect(() => {
    const sections = ['Consumerization', 'Uberization', 'Servitization', 'Productization'];
    const observerOptions = {
      root: document.querySelector('.scrolleble'), // Set root to the scrolling container
      rootMargin: '0px',
      threshold: 0.5,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveTab(entry.target.id);
        }
      });
    }, observerOptions);

    sections.forEach((sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sections.forEach((sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.new-header');
      const tabSection = document.querySelector('.tab-container');

      if (header && tabSection) {
        const headerBottom = header.getBoundingClientRect().bottom;
        setIsSticky(window.scrollY > headerBottom);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="container frmklo">
      <div className="wholjnjsln">
        <p className="text-center head def" style={{ fontSize: '40px' }}>CUSP</p>
        <p className='frmk'>The CUSP framework is built on four key pillars</p>
        <header className={`tab-container  tabghk`}>
          <nav className="tab-nav">
            <ul className="tab-list">
              {['Consumerization', 'Uberization', 'Servitization', 'Productization'].map((tab) => (
                <li key={tab} className="tab-item">
                  <a
                    href={`#${tab}`}
                    className={`tab-link ${activeTab === tab ? 'active' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection(tab);
                    }}
                  >
                    {tab}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </header>

        <div className="scrolleble">
          <CuspTabsContent activeTab={activeTab} />
        </div>
      </div>
    </div>
  );
};

export default CuspFrame;
