import React from 'react';
import "../../assests/css/ProductAnimations.css";

const Product = ({ title, describe,keyfact,focus,tname }) => {
  return (
    <div>
      <div className='container phone-kartoffel-one'>
        <div>
        <h3 className='hpyTname'>{tname}</h3>
          <h3 className='hapPrjctvb'>{title}</h3>
          <p className='deProject'>{describe}</p>
          <p className='keyFoc'> <span>{keyfact}</span> {focus}</p>
        </div>
      </div>
    </div>
  );
};

const ProductDesc = ({item,index}) => {
  // const ca = [
  //   {
  //     title: "ghj",
  //     describe: "ghjk",
  //   },
    // {
    //   title: "N 1",
    //   describe: "New  1",
    // },
    // {
    //   title: "New  2",
    //   describe: "New  2",
    // },
  // ];

  return (
    <div className="ccc">
      <div className="container phn-kartoffel">
        {
        // ca.map((product, index) => (
          <Product
            key={index}
            tname={item.tname}
            title={item.title}
            describe={item.describe}
            focus={item.focus}
            keyfact={item.keyfact}
          />
        // ))
        }
      </div>
    </div>
  );
};

export default ProductDesc;
