import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      console.log('Scrolling to top after timeout');
      window.scrollTo(0, 0);
    }, 100); 

    return () => clearTimeout(timeoutId); // Cleanup on unmount
  }, [pathname]);
  return null;
};

export default ScrollToTop;
