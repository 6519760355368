import React from 'react';
import '../../assests/css/BlogInnerBan.css';

const BlogInnerBanner = ({ bannerImg }) => {
    if (!bannerImg) {
        return null;
      }
    return (
        <div className="Blog-inner-banner">
            <div className="bloginner-banner-image">
                <img src={bannerImg.imageSrc} alt={bannerImg.imageAlt} />
            </div>
        </div>
    );
};

export default BlogInnerBanner;
