import React, { useState } from 'react';
import Images from '../../Images/Images'
import { Container, Button } from 'react-bootstrap';
import "../../assests/css/GetInTouch.css"
import { useNavigate } from "react-router-dom";
import LandContactModal from "../../commonComponents/ServiceInnerBan/LandContactModal"


const LandGetInTouch = () => {
    const [showModal, setShowModal] = useState(false);
    return (
        <div className='git land-git'>
            <Container className='land-git-con'>
                <div className='d-flex flex-column flex-md-row git-content'>
                    <div className='work mt-2'>
                        <p className='together'>LET’S WORK TOGETHER. <br />GET IN TOUCH.</p>
                    </div>
                    {/* <div className='tals mt-md-0'> */}
                        {/* <Button className="bn-talk bn-coomon" onClick={() => handleApplyClick("/contact-us")} >Lets Talk <img className="bt-learn" src={Images.learnmore} /></Button> */}
                        <div className='hover-image-container tals get-lan-bn'>
                            <img className='hover-image  img-unique' src={Images.button} alt="Know More" />
                            <img className='button-image img-unique' src={Images.buttonclick} alt="Know More Hover" />
                            <div className='hover-text' onClick={() => setShowModal(true)} >
                                <p className='home-know'>Lets Talk</p>
                                <span className='arrow-icon'>
                                    <img src={Images.rightarrow} alt="Right Arrow" />
                                </span>
                            </div>
                        </div>
                    {/* </div> */}
                
                  </div>
                  <img src={Images.getrocket} alt="Rocket" className="rocket-image" />
                 <img src={Images.getback} alt="Rocket" className="getback-image" />
                 <img src={Images.getright} alt="Rocket" className="getback-right" />
            </Container>
            <LandContactModal show={showModal} setShow={setShowModal} />
        </div>
    )
}

export default LandGetInTouch