import React from 'react'
import NavbarHeader from "../../commonComponents/header/NavbarHeader"
import Footer from "../../commonComponents/footer/footer"
import ServiceCards from '../../component/Service/ServiceCard'
import ProductionIdea from "../../component/Service/ProductionIdea"
import DevelopmentTools from "../../component/Service/DevelopmentTools"
import OutsourcedProducts from "../../component/Service/OutSourced"
import GetInTouch from '../../commonComponents/GetInTouch/GetInTouch'
import ServiceBan from '../../component/Service/ServiceBanneer'
import FAQ from '../../component/Service/Faq'
import OurTrustedPartners from '../../commonComponents/OurClient/OurClient'
import Seo from "../../commonComponents/MetaTag/MetaTag";
import { ProductService, bann, service,faqs,listItems } from "../../commonComponents/ServicesData/DigitalExperience"
import Statistics from '../../commonComponents/ProjectCount/ProjectCount'

const DigitalExperience = () => {
  const meta_description="Improve user experiences with our Digital Experience Design services. We enhance engagement and usability for your platforms.";
  const meta_title="Enhance User Engagement - Digital Experience Design | Kartoffel Technologies";
  const meta_keyword = "UI/UX Design Services,ui/ux design services company,ui/ux consulting services,ui/ux development service,ui/ux consulting services"

  const dynamicTitle = {
    one: "Staying ahead means adopting new",
    two: "technologies – let us not just keep up",
    three: "with the future but lead it together. "
  };
  const out_title="What does Digital Transformation mean for your business?"

  return (
    <div className='Kart-service'>
       <Seo meta_description={meta_description} meta_title={meta_title}  meta_keyword={meta_keyword}/>
      <NavbarHeader />
      <ServiceBan bann={bann} />
      <ServiceCards service={service}  title={dynamicTitle}/>
      <ProductionIdea sliderData={ProductService} />
      {/* <OutsourcedProducts listItems={listItems}  out_title={out_title} /> */}
      <Statistics/>
      {/* <OurTrustedPartners /> */}
      <DevelopmentTools />
      <FAQ faqs={faqs}/>
      <GetInTouch />
      <Footer />
    </div>
  )
}

export default DigitalExperience